import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";

import InputMask from 'react-input-mask';
import Select from 'react-select'
import "react-table/react-table.css";
import "../components/css/teste.css"
import "../components/css/relatorio.css"
import 'moment-timezone';
import format from "date-fns/format";
import { getToken } from "../auth";
import Button from 'react-bootstrap/Button';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BR from 'date-fns/locale/pt-BR';
import ReactTooltip from 'react-tooltip'
import axios from 'axios';
import swal from 'sweetalert';
import Spinner from 'react-loader-spinner';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Autosuggest from 'react-autosuggest';
import Topo_Autenticado from './Topo_autenticado.jsx'

import api from '../services/api'

registerLocale('pt-BR', BR)


export default class Relatorio extends Component {

    constructor(props) {
        super(props); //
        this.state = {
            data: [],
            startDate: new Date(),
            showPagination: true,
            pageSize: 2,
            menssagemRetorno: " ",
            total_ativos: 0,
            total_estimado: 0,
            total_executado: 0,
            valor_efetivo: 0,
            total_movimentado: 0,
            processos_excluir: [],
            show_loading_importados: false,
            data_pesquisa: 0,
            value: "", //aqui ficará salva a string a ser utilizada para busca no autoComplete de buscar partes
            sugestoes_partes: [],
            partes: [],

            proc_impro_loading: true,// novo
            numeracao_unica: '', 
            first_date: '',
            second_date: '',
            comarcas:[],
            comarca_id: 0,
            unidades_judiciarias:[],
            unidade_judiciaria_id: 0,
            situacoes:[],
            situacoes_id: 0,
            situation: '',
            
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleUnidadeJudiciaria = this.handleUnidadeJudiciaria.bind(this)
        this.handleComarca = this.handleComarca.bind(this)
        this.handleNumeracaoUnica = this.handleNumeracaoUnica.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onChangeSuggest = this.onChangeSuggest.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.preencherResumo = this.preencherResumo.bind(this)
        //this.handleCheckDeletar = this.handleCheckDeletar.bind(this)
    }

    async preencherResumo()
    {
        await fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getInfosComplementares"
        ,{
            headers:
            {
                'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
        .then(res => res.json())
        .then(
            (result) =>
            {
                if(result !== false)
                {
                    this.setState({
                        total_ativos: result.total_ativos,
                        total_estimado: result.total_estimado,
                        total_executado: result.total_executado,
                        valor_efetivo: result.valor_efetivo,
                        total_movimentado: result.total_movimentado
                    });
                }
                else
                {    
                    this.setState({
                        total_ativos: "erro",
                        total_estimado: "erro",
                        total_executado: "erro",
                        total_efetivo: "erro",
                        total_movimentado: "erro"
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    handleChange = async date => {
        
        this.setState({
          startDate: date    
        });
        
        const dataPesquisa = format(date, "yyyy-MM-dd");
        await this.setState({data_pesquisa: dataPesquisa}) //!

        //console.log("data selecionada enviada para as rotas:")
        //console.log(dataPesquisa)

        this.ReactTableImportados.fireFetchData()
        this.ReactTableRetornados.fireFetchData()
        this.ReactTableCobrancaAdministrativa.fireFetchData()
        this.ReactTableCobrancaDispensada.fireFetchData()
        this.ReactTableEmbargosDeclarados.fireFetchData()
        this.ReactTableHonorariosNTransitados.fireFetchData()
        this.ReactTableHonorariosTransitados.fireFetchData()
        this.ReactTableExecutados.fireFetchData()
        this.ReactTableRiscoPrescricao.fireFetchData()
        this.ReactTableExecucaoConcluida.fireFetchData()
        this.ReactTableImprocedentes.fireFetchData()
        this.ReactTablePendentes.fireFetchData()
    }


    componentDidMount = async() =>
    {
        this.setState({show_loading_importados: true})

        await this.preencherResumo()

        this.setState({ 
            showPagination: true, 
            pageSize: 2,
            showPaginationBottom: true, 
            menssagemRetorno: 'Sem Processos...',
            show_loading_importados: false
        });        
        this.getComarcas();
        this.getSituacoes();
    }

    // sugestion
    onSuggestionsFetchRequested = async ({ value }) =>
    {
        //essa função faz a requisição p/ o back-end retornar os dados das partes de acordo com o que foi escrito
        console.log('legal2')
        console.log(value)
        let valor_pesquisar = {'string': value} //prepara o obj com a string a ser pesquisada no back-end
        let sugestoes_partes = [] //var que vai ter todas as sugestões

        //await axios.post("http://10.53.23.189:8000/parte/autocomplete", valor_pesquisar
        await axios.post(process.env.REACT_APP_DOMAIN + "/parte/autocomplete", valor_pesquisar
        ,{ //await -> segura a execução do resto do código enquanto o comando não termina, necessita que a função seja assíncrona
            headers:
            {
                //'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
        .then((resposta) => 
        {
            //prepara os objetos de cada parte com seus respectivos dados
            for (let i = 0; i < resposta['data'].length; i++) //TODO: trocar por map?
            {
                sugestoes_partes.push({}) //insere um obj vazio no array, p/ poder acessar pelo índice mais abaixo
                
                const element = resposta['data'][i]; //element é o valor da vez
                //sem o .push({}) acima, [i] é inacessível
                sugestoes_partes[i]['id'] = element.id; //o id da parte no back-end! Importante
                sugestoes_partes[i]['nome'] = element.nome;
                sugestoes_partes[i]['documento'] = element.documento;
                sugestoes_partes[i]['sem_documento'] = element.sem_documento;

                if(element.sem_documento)
                {
                    sugestoes_partes[i]['tipo_cpf'] = true;
                    sugestoes_partes[i]['tipo_rg'] = false;
                    sugestoes_partes[i]['tipo_cnpj'] = false;

                    sugestoes_partes[i]['cpf'] = null;
                    sugestoes_partes[i]['rg'] = null;
                    sugestoes_partes[i]['cnpj'] = null;
                }
                else
                {
                    if (element.tipo_cpf) //element.tipo_cpf === true
                    {
                        sugestoes_partes[i]['tipo_cpf'] = true;
                        sugestoes_partes[i]['tipo_rg'] = false;
                        sugestoes_partes[i]['tipo_cnpj'] = false;

                        sugestoes_partes[i]['cpf'] = element.cpf;
                        sugestoes_partes[i]['rg'] = null;
                        sugestoes_partes[i]['cnpj'] = null;
                    }
                    else if (element.tipo_rg) //element.tipo_rg === true
                    {
                        sugestoes_partes[i]['tipo_cpf'] = false;
                        sugestoes_partes[i]['tipo_rg'] = true;
                        sugestoes_partes[i]['tipo_cnpj'] = false;

                        sugestoes_partes[i]['cpf'] = null;
                        sugestoes_partes[i]['rg'] = element.rg;
                        sugestoes_partes[i]['cnpj'] = null;
                    }
                    else
                    {
                        sugestoes_partes[i]['tipo_cpf'] = false;
                        sugestoes_partes[i]['tipo_rg'] = false;
                        sugestoes_partes[i]['tipo_cnpj'] = true;

                        sugestoes_partes[i]['cpf'] = null
                        sugestoes_partes[i]['rg'] = null;
                        sugestoes_partes[i]['cnpj'] = element.cnpj
                    }
                }
                sugestoes_partes[i]['descricao'] = element.descricao
                sugestoes_partes[i]['endereco'] = element.endereco
                sugestoes_partes[i]['estado_id'] = element.estado_id
                sugestoes_partes[i]['cidade_id'] = element.cidade_id
                sugestoes_partes[i]['telefones'] = element.telefones
            }
        }) //fim do axios e do 'await', o que vier em seguida só será executado depois que o comando com 'await' terminar
        this.setState({ 
            sugestoes_partes: sugestoes_partes
        });
        console.log("buscou:")
        console.log(sugestoes_partes)
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            sugestoes_partes: []
        });
    };

    getSuggestionValue(suggestion)
    {
        return suggestion.nome;
    };

    renderSuggestion(suggestion)
    {
        return (
            <span>{suggestion.nome}<br/>{suggestion.documento}</span>
        );
    };

    onChangeSuggest = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionSelected(event, { suggestion })
    { //essa função adiciona a parte selecionada ao processo
        /**
         * exemplo de como vem os dados da parte que o usuario clicar (parte == sugestao)
         * 
            cidade_id: 210​
            cnpj: null
            cpf: "000.123.789-94"
            descricao: "Empresa de Agua e Saneamento"
            documento: "CPF: 000.123.789-94"
            endereco: "Rua A, Bairro X - 451"
            estado_id: 5
            id: 10
            nome: "ADSON SANTOS"
            tipo_cnpj: false
            tipo_cpf: true
            
            // a propriedade 'documento' vem no formato 'documento_parte: numeros'
            // pq na seção da parte no processo ela so precisa ser exibida, não é necessário realizar operações nela
         */

         let parte = {}

         if (suggestion.tipo_cpf) //se tiver cpf
         {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: null,
                rg: null,
                cpf: suggestion.cpf,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_cnpj: false,
                tipo_rg: false,
                tipo_cpf: true
            }
         }
         else if (suggestion.tipo_rg) //se tiver rg
         {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: null,
                rg: suggestion.rg,
                cpf: null,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_cnpj: false,
                tipo_rg: true,
                tipo_cpf: false
            }
         }
         else //se tiver cnpj
         {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: suggestion.cnpj,
                rg: null,
                cpf: null,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_rg: false,
                tipo_cnpj: true,
                tipo_cpf: false
            }
         }

         //? inicializando valores
         parte['qtd_parcelas'] = 1;
         parte['valor_executado'] = 0;
         parte['valor_estimado'] = 0;
         parte['valor_efetivo'] = 0;

         parte['data_execucao'] = null;
         parte['data_ingresso_conta'] = null;
         parte['data_transito_julgado'] = null;
         parte['data_cobranca'] = null;

         /**
            data_execucao: "",
            data_ingresso_conta: "",
            data_transito_julgado: "",
            data_cobranca: "",
            data_agendado_retorno: "",
            campos_extras: '',
            valor_executado: 0,
            valor_executado_tratado: 0, //sem o prefixo "R$ ", utilizado para o cálculo da parcela
            valor_estimado: 0,
            valor_efetivo: 0,
            receita_recebida: 0,
            prorrogacao_id: '',
            motivo_id: '',
          */

        let partes = [...this.state.partes]; //cópia do state atual

        if (partes.length === 0) //se não houver nenhuma parte no processo, a que acabou de ser selecionada é a principal
            parte['sw_parte_principal'] = true;

        partes.push(parte) //adiciona no fim

        this.setState({partes}) //salva

        console.log("inseriu:")
        console.log(parte)
         /*
        this.setState({ //resetando os states
            flag_botao_add_parte: true, //exibir novamente o botão de adicionar parte
            value: "", //zerar o valor da pesquisa de parte
        })
        */
    }

    //
    formataNumeroProcesso(valor)
    {

        /**Criei essa função para formatar a numeração única do processo, o problema é que se
         * a numeração não tiver 20 caracteres a função retorna o numero sem a formatação e 
         * se tiver mais de 20 caracteres a função não exibe os numeros a mais, por este motivo fiz uma validação 
         * para que se for superior ou inferior a 20 exibe sem formatação.
         * Formato: 9999999-99.9999.9.99.9999
         * Para remover a mascara é só utilizar: formatado.replace(/[^\d]+/g,'')
         */

        //if(valor.lenght > 20 || valor.lenght < 20) ////  lengtH, ;^) 
        if (valor.length != 20)
            return valor;
        else
            var formatado = valor.replace(/^(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4}).*/, '$1-$2.$3.$4.$5.$6');
        return formatado;
    }

    formatardata(valor)
    {
        let formatado = '-'
        if(valor && valor !== null) {
            const date = new Date(valor);
            const dia = date.getDate().toString().padStart(2, '0');
            const mes = (date.getMonth() + 1).toString().padStart(2, '0');
            const ano = date.getFullYear().toString();
             formatado = `${dia}-${mes}-${ano}`;
        }else{
             formatado = `-`; 
        }


        return formatado;
    }

    filterCaseInsensitive(filter, row) //? essa função permite pesquisar nas tabelas ignorando se as letras são maiúsculas ou minúsculas
    {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    handleCheckDeletar = async(e, processo_numeracao) =>
    {
        //console.log(e.target.checked)
        //console.log(processo_numeracao)
        if (e.target.checked)
        {
            //? adiciona o processo no final do Array de processos para excluir
            await this.setState({processos_excluir: [...this.state.processos_excluir, processo_numeracao]})
        }
        else
        {
            let processos = await this.state.processos_excluir
            try
            {
                let indice = processos.indexOf(processo_numeracao)
                processos.splice(indice, 1)
                await this.setState({processos_excluir: processos})
            }
            catch (error)
            {    
            }
        }
        //console.log("array de excluir:")
        //console.log(this.state.processos_excluir)
    }

    deletarProcessos = async() =>
    {
        let obj = {
            processos: this.state.processos_excluir
        }
        //console.log("obj a enviar:")
        //console.log(obj)
        try
        {
            const result = await axios.post(process.env.REACT_APP_DOMAIN + "/removeImportados", obj,
            {
                headers:
                {
                    'authorization': getToken()
                },
            })
            //console.log(result)
            swal({
                title: "Sucesso!",
                text: "Processo(s) excluído(s)",
                icon: "success",
                timer: 3000,
                button: false
            })
            .then(() => {
                //console.log("aqui")
                //this.getProcessosImportados()
                this.ReactTableImportados.fireFetchData()
            }) //? busca novamente os processos importados após a exclusão
        }
        catch (error)
        {
            //console.log(error)
            //console.log(error.response)
            swal({
                title: "Erro",
                text: "Não foi possível deletar o processo, por favor atualize a tela e tente novamente.",
                icon: "error",
                button: "Fechar"
            })
            .then((e)=>{})
        }
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilterMethod = (filter, row) =>
    {
        let valorPesquisa = filter.value
        /* //!
            abaixo, remove '-' e '.' e aplica manualmente a máscara,
            assim fica indiferente pro user digitar a pesquisa {com/sem/parcialmente} com máscara
        */
        valorPesquisa = valorPesquisa.replace(/-|\./g, "")
        if (valorPesquisa.length >= 8)
        {
            valorPesquisa = valorPesquisa.substring(0, 7) + '-' + valorPesquisa.substring(7)
        }
        if (valorPesquisa.length >= 10)
        {
            valorPesquisa = valorPesquisa.substring(0, 10) + '.' + valorPesquisa.substring(10)
        }
        if (valorPesquisa.length >= 15)
        {
            valorPesquisa = valorPesquisa.substring(0, 15) + '.' + valorPesquisa.substring(15)
        }
        if (valorPesquisa.length >= 17)
        {
            valorPesquisa = valorPesquisa.substring(0, 17) + '.' + valorPesquisa.substring(17)
        }
        if (valorPesquisa.length >= 20)
        {
            valorPesquisa = valorPesquisa.substring(0, 20) + '.' + valorPesquisa.substring(20)
        }
        
        if (row.numeracao_unica.indexOf(valorPesquisa) !== -1)
            return row
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilter = (filter, onChange) =>
    {
        return (
            <div>
                <input
                    type='text'
                    value = {filter? filter.value:""}
                    maxLength= {25}
                    onChange={(event) => onChange(event.target.value)}
                />
            </div>
        )
    }


    getProcessos = async(pagina = 0, filtros = {}, tipoProcessos = 1) =>
    {
        try
        {
            //console.log("filtros na rota:")
            //console.log(filtros)
            let res = await api.post(
                `/cadastrar-processo/getProcessos/${tipoProcessos}/${this.state.data_pesquisa}/${pagina}`,
                filtros
            )
            if (res.data == 0)
            {
                //console.log("essa tabela ficara vazia:")
                //console.log(res)
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: []
                    }
                }
            }
            else
                return res
        }
        catch (error)
        {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    customOnFetchData = async(tableState, instance, tipoProcessos) =>
    {
        let filtros = {}
        tableState.filtered.map((filtro) => {
            if (filtro.id === 'numeracao_unica' && filtro.value.length >= 6)
                filtros.numeracao_unica = filtro.value

            else if (filtro.id === 'comarca' && filtro.value.length >= 3)
                filtros.comarca = filtro.value.toUpperCase()

            else if (filtro.id === 'motivo_pendencia' && filtro.value.length >= 3)
                filtros.motivo_pendencia = filtro.value.toUpperCase()

            else if (filtro.id === 'data_ingresso' && filtro.value.length === 10)
                filtros.data_ingresso = filtro.value

            else if (filtro.id === 'data_transito_julgado' && filtro.value.length === 10)
                filtros.data_transito_julgado = filtro.value

            else if (filtro.id === 'data_retorno' && filtro.value.length === 10)
                filtros.data_retorno = filtro.value
        })

        return await this.getProcessos(tableState.page+1, filtros, tipoProcessos)
    }
    //novo2
        //seta valor do campo para o objeto de comarcas
    handleComarca(e) {
        let change = {}
        change['comarca_id'] = e.value
        this.setState(change)
        this.getUJ(e.value);
    }
    handleNumeracaoUnica(valor)
    {
        this.setState({numeracao_unica: valor.target.value})
    }
    handleUnidadeJudiciaria(e) {
        let change = {}
        change['unidade_judiciaria_id'] = e.value
        this.setState(change)
    }

    handleSituacoes(e) {
        let change = {}
        change['situacoes_id'] = e.value
        this.setState(change)
    }

    handleChange2 = event => {
        this.setState({
          [event.target.name]: event.target.value
        });
      };
    


       //carrega a lista de comarcas
    getComarcas() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getComarcas"
        ,{
            headers:
            {
                'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let comarcas = result.map(comarca => ({
                        value: comarca.id,
                        label: comarca.nome,
                    }));
                    this.setState({
                        comarcas: comarcas
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

      //carrega o combo de unidade judiciária de acordo com a comarca selecionada
      getUJ(comarca_id, unidade_judiciaria_id = -1)
      { //? se nao enviar o 2º parâmetro, 'unidade_judiciaria_id' fica com o valor -1,
        //? assim da pra saber se é somente p/ carregar as UJ's ->OU<- carregá-las e já setar uma UJ
  
          fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getUJ/" + comarca_id
          ,{
              headers:
              {
                  'content-type': 'application/x-www-form-urlencoded',
                  'authorization': getToken()
              },
          })
              .then(res => res.json())
              .then(
                  (result) => {
                      let unidades = result.map(unidade => ({
                          value: unidade.id,
                          label: unidade.nome,
                      }));
                      if (unidade_judiciaria_id === -1) //se for -1, o usuário vai escolher uma UJ
                      {
                          this.setState({
                              unidades_judiciarias: unidades
                          });
                      }
                      else //senao ja carrega o combo de acordo com a comarca e seta um valor
                      {
                          this.setState({
                              unidades_judiciarias: unidades,
                              unidade_judiciaria_id: unidade_judiciaria_id
                          });
                      }
                  },
                  (error) => {
                      this.setState({
                          isLoaded: true,
                          error
                      });
                  }
              );
      }
          //carrega o combo de situações
    getSituacoes() {
        fetch(process.env.REACT_APP_DOMAIN +"/cadastrar-processo/getSituacoes"
        ,{
            headers:
            {
                'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    let situacoes = result.map(situacao => ({
                        value: situacao.id,
                        label: situacao.nome,
                    }));
                    this.setState({
                        situacoes: situacoes
                    });
                  
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    // metodo que executa o submit 


    handleSubmit =  async (e) =>  {
        e.preventDefault();

        this.setState({
            proc_impro_loading: true
        })
            const dados = await this.getRelatorioBuscar(1)
            const processosSigad = dados.data.processos.map(processosSigad => ({
            numeracao_unica: processosSigad.numeracao_unica ? this.formataNumeroProcesso(processosSigad.numeracao_unica) : "",
            data_movimentacao: processosSigad.observacao_updated_at ? this.formatardata(processosSigad.observacao_updated_at): "",
            comarca_nome: processosSigad.comarca_nome,
            unidade_judiciaria: processosSigad.unidade_judiciaria_nome,
            situacao_nome: processosSigad.situacao_nome
            
           
        }))
        
        
        this.setState({
            processosSigad: processosSigad,
            proc_sigad_pages: dados.data.pages,
            proc_impro_loading: false
        })
    }


    getRelatorioBuscar = async (pagina = 0) => {

        let dados = {
            numeracao_unica: this.state.numeracao_unica,
            unidade_judiciaria_id: this.state.unidade_judiciaria_id,
            comarca_id: this.state.comarca_id,
            first_date: this.state.first_date,
            second_date: this.state.second_date,
            situacoes_id: this.state.situacoes_id,
            parte_id: this.state.partes
        }

        

        try {
            let res = await api.post("/relatorio-processo/buscar/" + pagina,dados)
            
            if (res.data == 0) {
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: [],
                        to: 5
                    }
                }
            } else {
                
                return res;
            }
        } catch (error) {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    generatePDF = () => {
        const unit = 'pt';
        const size = 'A4';
        const orientation = 'landscape';
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
        const title = 'My Awesome Table';
        const headers = [['Numeração Única', 'Data da última movimentação', 'Comarca', 'Unidade Judiciária','Situação']];
        const data = this.state.processosSigad.map((elt) => [
            elt.numeracao_unica  , elt.data_movimentacao, elt.comarca_nome, elt.unidade_judiciaria, elt.situacao_nome
        
        ]);
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('table.pdf');
    };
    render() {

        //São as variaveis que trazem os dados para popular as tabelas
        const { 
 
            startDate,
            processosSigad,
            pageSize,
            showPagination,
            showPaginationBottom,
            menssagemRetorno,
            value,

    
        } = this.state;

        const inputProps =
        {
            placeholder: "Pesquisar Parte",
            value,
            onChange: this.onChangeSuggest
        };


        //renderiza a tela
        return (
            <>
            <Topo_Autenticado/>
            <div className="row">
                <div className="col">
                    <DatePicker
                        selected={startDate}
                        onChange={this.handleChange}
                        dateFormat="DD/MM/YYYY"
                        locale="pt-BR"
                        inline
                    /> <br/>

                    <div className="row">
                        <div className='col'>
                            <h6>Cadastro Manual</h6>
                        </div>
                        <div className='col'>
                            <a href="/#/cadastrar-processo">
                                <img src="/static/media/icons8-adicionar.f109a600.svg" alt=""/>  
                            </a>
                        </div>                             
                    </div>

                    <br/>
                    <div style={{border: "dotted #1c2b73 1px", background:"#ECF4DC" }}>
                        <div className="center">
                            <h6><b>Resumo</b></h6>
                        </div>  
                        <br/>
                        <ul style={{margin: "5px" }}>             
                            <li><b>Processos Ativos: {this.state.total_ativos}</b></li><br/>
                            <li><b>Total Estimado: {this.state.total_estimado}</b></li><br/>
                            <li><b>Total Executado: {this.state.total_executado}</b></li><br/>
                            <li><b>Total Efetivo: {this.state.valor_efetivo}</b></li><br/>
                            <li><b>{this.state.total_movimentado} Processos movimentados</b></li>
                        </ul>
                        <br></br>
                        <div className="center">
                            <h6><b>Legenda</b></h6>
                        </div>  
                        <br/>
                        <ul style={{margin: "5px" }}>             
                            <li><b><img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />  Processo Prioritário</b></li><br/>
                            <li><b><img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />  Parte Principal do Processo</b></li><br/>
                        </ul>
                    </div>

                </div>
                <div className="center">
                    <h5 className="text-center"><b>RELATÓRIO DE HONORÁRIOS</b></h5>
                </div>
                <div className="row">
                    <div className="box2">
                        <div className="well">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-controll">

                                <div className="form-controll-box">

                                    <div className="form-controll-unique">
                                        <label htmlFor="numeracao_unica">Número do Processo</label>
                                        <InputMask
                                            placeholder="Número do Processo"
                                            id="numeracao_unica"
                                            name="numeracao_unica"
                                            mask="9999999-99.9999.9.99.9999"
                                            value={this.state.numeracao_unica}
                                            onChange = {valor => this.handleNumeracaoUnica(valor)}
                                            required
                                        />
                                
                                    </div>

                                    <div className="form-controll-unique">
                                        <label htmlFor="parte_name">Nome da parte:</label>
                                        <Autosuggest 
                                            suggestions={this.state.sugestoes_partes}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                            onSuggestionSelected = {this.onSuggestionSelected}
                                        />
                                    </div>


                                </div>
                                <div className="form-controll-box">

                                    <div className="form-controll-unique">
                                        <label htmlFor="first_date">Data Inicial:</label>
                                        <input
                                        type="date"
                                        id="first_date"
                                        name="first_date"
                                        onChange={this.handleChange2}
                                        value={this.state.first_date}
                                        />
                                    </div>

                                    <div className="form-controll-unique">
                                        <label htmlFor="second_date">Data Final:</label>
                                        <input
                                        type="date"
                                        id="second_date"
                                        name="second_date"
                                        onChange={this.handleChange2}
                                        value={this.state.second_date}
                                        />
                                    </div>
                                </div>
                                <div className="form-controll-box">

                                    <div className="form-controll-unique">
                                        <label htmlFor="comarca">Comarca:</label>

                                        <Select
                                        onChange={this.handleComarca.bind(this)}
                                        options={this.state.comarcas}
                                        placeholder="Selecionar Comarca"
                                        noOptionsMessage={() => 'Carregando...'}
                                        inputId="comarca_id"
                                        value={this.state.comarcas.filter(({value}) => { return value === this.state.comarca_id})}
                                    />
                                    </div>

                                    <div className="form-controll-unique">
                                        <label htmlFor="unidade_judiciaria">Unidade Judiciaria:</label>
                                        <Select
                                        onChange={this.handleUnidadeJudiciaria.bind(this)}
                                        options={this.state.unidades_judiciarias}
                                        noOptionsMessage={() => 'Selecione uma Comarca'}
                                        placeholder="Selecionar Unidade Judiciária"
                                        inputId="unidade_judiciaria_id"
                                        value={this.state.unidades_judiciarias.filter(({value}) => { return value === this.state.unidade_judiciaria_id})}    
                                        />

                                    </div>

                                    <div className="form-controll-unique">
                                        <label htmlFor="situation">Situação:</label>

                                        <Select
                                        onChange = {this.handleSituacoes.bind(this)}
                                        options={this.state.situacoes}
                                        placeholder="Selecionar Situação"
                                        inputId="situacao_id"
                                        inputName="situacao_id"
                                        value={this.state.situacoes.filter(({value}) => { return value === this.state.situacoes_id})}    
                                        />

                                    </div>
                                </div>
                                <div className='toRight'>
                                    <button className='btn' onClick = {this.handleSubmit} type="submit">Buscar</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                        <div className='box-table'>
                            <ReactTable
                                data={processosSigad}
                                pages={this.state.proc_sigad_pages}
                                manual
                                ref={ref => this.ReactTableProcessosSigad = ref}
                                loading={this.state.proc_impro_loading}
                                onFetchData={async (state, instance) => {
                                    this.setState({ proc_sigad_loading: true })

                                    const dados = await this.getRelatorioBuscar(state.page + 1)
                                    const processosSigad = dados.data.processos.map(processosSigad => ({
                                        numeracao_unica: processosSigad.numeracao_unica ? this.formataNumeroProcesso(processosSigad.numeracao_unica) : "",
                                        data_movimentacao: processosSigad.observacao_updated_at ? this.formatardata(processosSigad.observacao_updated_at): "",
                                        comarca_nome: processosSigad.comarca_nome,
                                        unidade_judiciaria: processosSigad.unidade_judiciaria_nome,
                                        situacao_nome: processosSigad.situacao_nome
                                        
                                    }))
                                    
                                   
                                    this.setState({
                                        processosSigad: processosSigad,
                                        proc_sigad_pages: dados.data.pages,
                                        proc_impro_loading: false,
                                        pageSize: dados.data.to
                                    })
                                    
                                }}
                                columns={[{
                                    Header: "Resultados",
                                    columns: [
                                        {
                                            Header: "Numeração Única",
                                            id: "numeracao_unica",
                                            width: 280,
                                            accessor: "numeracao_unica",
                                            Cell: e => <div style={{ textAlign: "center" }}>
                                                <a href={'/#/editar-processo/' + e.value}> {e.value} </a>
                                            </div>
                                        },
                                        {
                                            Header: "Data da última movimentação",
                                            accessor: "data_movimentacao",
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Comarca",
                                            accessor: "comarca_nome",
                                            //width: 80,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Unidade Judiciária",
                                            accessor: "unidade_judiciaria",
                                            //width: 80,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Situação",
                                            accessor: "situacao_nome",
                                            //width: 80,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },

                                    ]
                                }]}
                                
                                className="-striped -highlight"
                                defaultFilterMethod={this.filterCaseInsensitive}

                                //Paginação
                                showPagination={showPagination}
                                showPaginationBottom={showPaginationBottom}
                                showPageSizeOptions={false}
                                defaultPageSize={pageSize}

                                noData
                                noDataText={menssagemRetorno}

                                // Texto
                                previousText='Anterior'
                                nextText='Próximo'
                                loadingText='Carregando...'
                                pageText='Páginas'
                                ofText='de'
                                rowsText='Linhas'
                            >

                            </ReactTable>
                            <div className='toRight'>
                                <button className='btn' onClick={this.generatePDF}>Export PDF</button>
                            </div>
                        </div>
                    
                </div>

                
   
            </div>
            </>
        )
    }
    
}