import React from 'react';
import PropTypes from 'prop-types';
import './css/nestedTable.css';

function NestedTable({ data,onButtonClick,isLidoProp  }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Data de inserção</th>
          <th>Ato praticado</th>
          <th>Observação</th>
          <th>Leitura</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          const { numero_processo, ato_praticado, observacao, data_ato, lido,ato_processo_id } = item;
          return (
            <React.Fragment key={index}>
              {ato_praticado.map((ato, atoIndex) => {
                return (
                  <tr key={`${index}-${atoIndex}`}>
                    {atoIndex === 0 && (
                      <td rowSpan={ato_praticado.length}>{data_ato}</td>
                    )}
                    <td>{ato}</td>
                    {atoIndex === 0 && <td className='css-fix' rowSpan={ato_praticado.length}>{observacao}</td>}
                    {atoIndex === 0 && ( <td rowSpan={ato_praticado.length}>
                        {isLidoProp ? (
                          <button className='btn-lor-table' onClick={() => onButtonClick(item)}>
                            Marcar como não lida
                          </button>
                        ) : (
                          <button className='btn-lor-table' onClick={() => onButtonClick(item)}>
                            Marcar como Lido
                          </button>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

NestedTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      numero_processo: PropTypes.string.isRequired,
      ato_praticado: PropTypes.arrayOf(PropTypes.string).isRequired,
      observacao: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default NestedTable;