import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';
import { setToken }  from "../auth"
import axios from 'axios';
import Topo from './Topo';
import Modal from 'react-responsive-modal';
import Button2 from '@material-ui/core/Button';
import swal from 'sweetalert';
import Spinner from 'react-loader-spinner';
import "./css/Login.scss"

export default class Login extends Component {

 constructor(props)
 {
    super(props);

    this.state = {
      erro: false,
      estaAutenticado: false,
      estalogando: false,
      usuario: null,
      isOpen: false,
      confirmandoLogin: false,
      nomeCompleto: null,
      cpf: null,
      digitos_cpf: -1,
      email_institucional: null,
      spinnerSubmit: false,
      spinnerReset: false
    };

    this.handleChangeNome = this.handleChangeNome.bind(this);
    this.handleChangeCPF = this.handleChangeCPF.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  handleChangeNome(e)
  {
    this.setState({nomeCompleto: e.target.value})
  }

  handleChangeCPF(e)
  {
    this.setState({cpf: e.target.value});
    this.setState({digitos_cpf: this.state.digitos_cpf + 1})
  }

  handleChangeEmail(e)
  {
    this.setState({email_institucional: e.target.value})
  }

  keyDown(e)
  {
    if (e.key == 'Enter') //se a tecla clicada for 'Enter', chama a função de login
      this.handleSubmit(e);
  }

  handleSubmit(event)
  {
    event.preventDefault();
    this.setState({estalogando: true, spinnerSubmit: true});
    const dados_usuario = {
      'username': this.usuario.value,
      'password': this.password.value,
      'grupo': "ESDPA_HONORARIOS"
    };

    axios.post(process.env.REACT_APP_DOMAIN + `/login`, dados_usuario)
      .then(res => {
      setToken(res.data.token);
      this.setState({erro: false, estaAutenticado:true, estalogando: false, spinnerSubmit: false});
    }).catch( (e) =>
      {
        if (e.response.status == 401)
        {
          swal({
            title: "Credenciais Inválidas",
            text: "Seu usuário e/ou senha estão incorretos.",
            icon: "error",
            button: "Fechar",
          });
          this.setState({erro: true, estalogando: false, estaAutenticado:false, spinnerSubmit: false})
        }
        if (e.response.status == 420)
        {
          swal({
            title: "Usuário Não Autorizado",
            text: "Por favor entre em contato com a CMO através do servicedesk.",
            icon: "error",
            button: "Fechar",
          });
          this.setState({erro: true, estalogando: false, estaAutenticado:false, spinnerSubmit: false})
        }
      }

    );
  }

  handleReset ()
  {
    this.setState({spinnerReset: true});
    const dados_reset = {
      'nomeCompleto': this.state.nomeCompleto,
      'cpf': this.state.cpf,
      'email': this.state.email_institucional,
    }
    axios.post("https://demo6752021.mockable.io/enviar_email", dados_reset) //FIXME: alterar para o endereço que Adson criar
    .then(res =>
      swal({
        title: "Enviada",
        text: "Sua solicitação foi enviada para o suporte técnico.",
        icon: "info",
        button: "Fechar",
      })
        .then((value) => //após clicar o botão "Fechar" ou fora do alert no alert de envio do email
        {
          this.setState({
            isOpen: false, //fecha o modal de resetar a senha atrás do alert
            nomeCompleto: null, //reseta os valores
            cpf: null,
            digitos_cpf: -1,
            email_institucional: null,
            spinnerReset: false
          })
        })
    )
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render()
  {
    if(this.state.confirmandoLogin)
    {
      return( <div className="container">
                <p>Redirecionando... </p>
              </div>
        )
    }

    if(this.state.estaAutenticado)
    {
      return  <Redirect to='/home' />
    }

    //estilo da pág vem do arq Login.scss
    return (
      <>
      <Topo/>
      <div className="base-container">
        <div className="header"></div>
        <div className="content">
          <div className="form" onSubmit={this.handleSubmit}>
            <div className="form-group-username">
              <img src={require("../images/logo_DPE_com_nome.png")}
              style = {{marginLeft: '67px', marginBottom: '20px'}}/>
              <label htmlFor="username">Usuário</label>
              <input
                type="text"
                name="usuario"
                ref={(ref) => this.usuario = ref}
                className='form-control'
                placeholder="Usuário"
                onKeyDown={this.keyDown} //ao clicar 'Enter', faz login
              />
              <label>Suas Credenciais do Windows</label>

            </div>
            <div className="form-group-password">
              <label htmlFor="password">Senha</label>
              <input
                type="password"
                name="password"
                ref={(ref) => this.password = ref}
                className='form-control'
                placeholder="Senha"
                onKeyDown={this.keyDown} //ao clicar 'Enter', faz login
              />
            </div>
          </div>
        </div>
        <div //acerta a posição do spinner
          style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
          }}
        >
          <Spinner //configs do spinner
            type="ThreeDots"
            color="#1c2b73"
            height={50}
            width={50}
            visible={this.state.spinnerSubmit}
          />
        </div>
        <button
          disabled={this.state.estalogando}
          type="submit"
          className="btn"
          onClick={this.handleSubmit}
        >
          Entrar
        </button>

        <Modal open={this.state.isOpen} onClose={this.toggleModal} center>
          <div className="form" onSubmit={this.handleReset}>
            <label>Redefinição de Senha</label>
            <br />
            <p>Por favor envie um email para o servicedesk solicitando a redefinição da sua senha.
              <br/>
              Endereço: <a href="#" target="_blank">chamado</a>
            </p>
            {
              /**
              <p>Um e-mail será enviado para o suporte técnico redefinir a sua senha.</p>
            <label>Nome Completo</label>
            <input
              type='text'
              name='nomeCompleto'
              className='form-control'
              placeholder='Nome Completo'
              onChange = {this.handleChangeNome.bind(this)}
              size="12"
              required
            />
            <br />
            <label>CPF</label>
            <InputMask
              placeholder="CPF"
              id="cpf"
              name="cpf"
              mask="999.999.999-99"
              onChange = {this.handleChangeCPF.bind(this)}
              required
            />
            <br />
            <label>E-mail Institucional</label>
            <input
              type='text'
              name='email'
              className='form-control'
              placeholder='e-mail'
              onChange={this.handleChangeEmail.bind(this)}
              required
            />
            <br />
            <div //acerta a posição do spinner
              style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Spinner //configs do spinner
                type="ThreeDots"
                color="#2BAD60"
                height="50"
                width="50"
                visible={this.state.spinnerReset}
              />
            </div>
            <br />
            <button className="btn" type="submit" onClick={this.handleReset}
              disabled =
              {
                !(
                  Boolean(this.state.nomeCompleto) &&
                  Boolean(this.state.digitos_cpf >= 11) &&
                  Boolean(this.state.email_institucional)
                 )
              }
            >Enviar</button> */
            }
          </div>
        </Modal>

        <div className="footer">

        <Button2 variant="outlined" color="secondary" onClick={this.toggleModal}>Esqueci minha senha</Button2>
        </div>
      </div>
      </>
    )
  }
}
