import React, { Component } from 'react';
import Topo_Autenticado from './Topo_autenticado';
import { getToken } from "../auth";
import Spinner from 'react-loader-spinner';
import "../components/css/pje.css"




export default class Pje extends Component {
    constructor(props){
        super(props);
        this.state = {
            processo: [],
            movimentacao: [],
            enderecoAT: [],
            enderecoPAS: [],
            documentos: [],
            errorMessage:"",
            error: false,
            loading: false,
            loadingAuto: false,
            tab: true,
        }
    }

    OpenDocument(params) {
        var iframe = document.getElementById("iframetoHtmlOrPDF")
        if(params.ns2documentoVinculado){
            var folder = document.getElementById(params["@attributes"].idDocumento)
            if(folder.classList.contains("folder-hide")){
                folder.classList.remove("folder-hide")
            }else{
                folder.classList.add("folder-hide")
            }
        }else{
            iframe.classList.add("folder-hide")
            this.setState({loadingAuto: true})
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
            var url = "https://ws-dpe.defensoria.ba.def.br/integracao-pje-v2/api/v1/tipo-consultar-processo/documento/"+this.state.processo.ns2dadosBasicos['@attributes'].numero+"/"+params["@attributes"].idDocumento
            fetch(url, requestOptions).then(res => res.text())
            .then((result) => { 
                this.setState({loadingAuto: false})
                iframe.classList.remove("folder-hide")
                iframe.src = result
            })
        }


    }

    componentDidMount(){
        const { match: { params } } = this.props
        if(params.numeroProcesso){
            fetch(process.env.REACT_APP_DOMAIN+"/pje/"+params.numeroProcesso
            ,{
                headers:
                {
                    //'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if(result == 200){
                        this.setState({errorMessage: "Processo não encontrado.", error: true})
                    }else if(result == 500){
                        this.setState({errorMessage: "PJE EM MANUTENÇÃO.", error: true})
                    }
                    else{
                        this.setState({processo: result, enderecoAT: result.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa.ns2endereco, enderecoPAS: result.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa.ns2endereco, movimentacao: result.ns2movimento, documentos: result.ns2documento})
                        this.setState({loading: true})
                    }
                },
                (error) => {
                })
        }else{
            this.setState({errorMessage: "Processo não encontrado.", error: true})
        }
    }
    render(){
        return(
            <>
                <Topo_Autenticado/>
                <div className="center">
                    <h5 className="text-center"><b>PJE</b></h5>
                </div>
                <div className="box2" style={{justifyContent: 'center'}}>    
                    {
                        this.state.loading ? (
                            this.state.tab ? (
                            <div  style={{width: '95%'}}>
                                <div>
                                    <a className="nav-link nav-item" onClick={() => this.setState({tab: true})} style={{backgroundColor: this.state.tab ? "#3a863c" : "#1c2b73"}}>Dados Gerais</a>
                                    <a className="nav-link nav-item" onClick={() => this.setState({tab: false})} style={{backgroundColor: this.state.tab ? "#1c2b73" : "#3a863c" }}>Autos do Processo</a>
                                </div>
                                <div className="well-pje" >
                                    <h6 className="title-pje">Informações Básicas</h6>
                                    <table className="">
                                        <thead className='thead-pje'>
                                            <tr className="tr-pje">
                                                <th scope="col">Processo</th>
                                                <th scope="col">Prioridade</th>
                                                <th scope="col">Órgão julgador</th>
                                                <th scope="col">Autuado em</th>
                                                <th scope="col">Classe judicial</th>
                                                <th scope="col">Polo ativo</th>
                                                <th scope="col">Polo passivo</th>
                                                <th scope="col">Valor Causa</th>
                                                <th scope="col">Última moviment.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.state.processo.ns2dadosBasicos['@attributes'].numero}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2prioridade ? this.state.processo.ns2dadosBasicos.ns2prioridade : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2orgaoJulgador['@attributes'].nomeOrgao}</td>
                                                <td>{this.state.processo.ns2dadosBasicos['@attributes'].dataAjuizamento.substr(6,2)}/{this.state.processo.ns2dadosBasicos['@attributes'].dataAjuizamento.substr(4,2)}/{this.state.processo.ns2dadosBasicos['@attributes'].dataAjuizamento.substr(0,4)}</td>
                                                <td>{this.state.processo.ns2dadosBasicos['@attributes'].classeProcessual}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa['@attributes'].nome}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa['@attributes'].nome}</td>
                                                <td>R$ {this.state.processo.ns2dadosBasicos.ns2valorCausa}</td>
                                                <td>{this.state.processo.ns2movimento[0].ns2movimentoNacional.ns2complemento }</td>
                                            </tr>                     
                                        </tbody>
                                    </table>
                                </div>
                                <div className="well-pje" >
                                    <h6 className="title-pje">Historico de Movimentações</h6>
                                    <table className="table table-sm text-center table-striped">
                                        <thead className='thead-pje'>
                                            <tr className="tr-pje">
                                                <th scope="col" style={{textAlign: 'center'}}>Data/hora</th>
                                                <th scope="col" style={{textAlign: 'center'}}>Movimentação</th>
                                            </tr>
                                        </thead>
                                        <tbody>   
                                            {
                                                this.state.movimentacao.map((movimento) => (
                                                    <tr>
                                                        <td style={{textAlign: 'center'}}>{movimento['@attributes'].dataHora.substr(6,2)}/{movimento['@attributes'].dataHora.substr(4,2)}/{movimento['@attributes'].dataHora.substr(0,4)}</td>
                                                        <td style={{textAlign: 'center'}}>{movimento.ns2movimentoNacional ? movimento.ns2movimentoNacional.ns2complemento : movimento.ns2movimentoLocal["@attributes"].descricao}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="well-pje" >
                                    <h6 className="title-pje">Detalhamento dos Polos</h6>
                                    <p className="title-pje">- POLO ATIVO</p>
                                    <table className="table">
                                        <thead className='thead-pje'>
                                            <tr className="tr-pje">
                                                <th scope="col">Nome</th>
                                                <th scope="col">Genitora</th>
                                                <th scope="col">Dt. Nascimento</th>
                                                <th scope="col">Pessoa</th>
                                                <th scope="col">CPF/CNPJ</th>
                                                <th scope="col">Endereço</th>
                                            </tr>
                                        </thead>
                                        <tbody >   
                                            <tr>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].nome}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].nomeGenitora ? this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].nomeGenitora : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(6, 2)}/{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(4, 2)}/{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(0, 4)}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].tipoPessoa ? this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].tipoPessoa : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].codigoDocumento ? this.state.processo.ns2dadosBasicos.ns2polo[0].ns2parte.ns2pessoa["@attributes"].codigoDocumento : "N.I"}</td>
                                                <td>{this.state.enderecoAT.ns2logradouro ? this.state.enderecoAT.ns2logradouro : "N.I"}, N° {this.state.enderecoAT.ns2numero ? this.state.enderecoAT.ns2numero : "N.I"},<br/> CEP: {this.state.enderecoAT['@attributes'].cep ? this.state.enderecoAT['@attributes'].cep : "N.I"},<br/> BAIRRO: {this.state.enderecoAT.ns2bairro ? this.state.enderecoAT.ns2bairro : "N.I"},<br/> CIDADE: {this.state.enderecoAT.ns2cidade ? this.state.enderecoAT.ns2cidade : "N.I"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p className="title-pje">- POLO PASSIVO</p>
                                    <table className="table">
                                        <thead className='thead-pje'>
                                            <tr className="tr-pje">
                                                <th scope="col">Nome</th>
                                                <th scope="col">Genitora</th>
                                                <th scope="col">Dt. Nascimento</th>
                                                <th scope="col">Pessoa</th>
                                                <th scope="col">CPF/CNPJ</th>
                                                <th scope="col">Endereço</th>
                                            </tr>
                                        </thead>
                                        <tbody >   
                                            <tr>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].nome}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].nomeGenitora ? this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].nomeGenitora : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].dataNascimento ? this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(6, 2)+"/"+this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(4, 2)+"/"+this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].dataNascimento.substr(0, 4) : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].tipoPessoa ? this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].tipoPessoa : "N.I"}</td>
                                                <td>{this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].codigoDocumento ? this.state.processo.ns2dadosBasicos.ns2polo[1].ns2parte.ns2pessoa["@attributes"].codigoDocumento : "N.I"}</td>
                                                <td>{this.state.enderecoPAS.ns2logradouro ? this.state.enderecoPAS.ns2logradouro : "N.I"}, N° {this.state.enderecoPAS.ns2numero ? this.state.enderecoPAS.ns2numero : "N.I"},<br/> CEP: {this.state.enderecoPAS['@attributes'].cep ? this.state.enderecoPAS['@attributes'].cep : "N.I"},<br/> BAIRRO: {this.state.enderecoPAS.ns2bairro ? this.state.enderecoPAS.ns2bairro : "N.I"},<br/> CIDADE: {this.state.enderecoPAS.ns2cidade ? this.state.enderecoPAS.ns2cidade : "N.I"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <div style={{width: '95%'}}>
                                <div>
                                    <a className="nav-link nav-item" onClick={() => this.setState({tab: true})} style={{backgroundColor: this.state.tab ? "#3a863c" : "#1c2b73"}}>Dados Gerais</a>
                                    <a className="nav-link nav-item" onClick={() => this.setState({tab: false})} style={{backgroundColor: this.state.tab ? "#1c2b73" : "#3a863c" }}>Autos do Processo</a>
                                </div>
                                <div className="well-pje" style={{flexDirection: 'row', display: 'flex'}} >
                                    <div className="autos-processo" style={{ width: "30%"}}>
                                    {
                                        this.state.documentos.map((documento) => (
                                            <div >
                                                <a className="autos-item" onClick={() => this.OpenDocument(documento)}>
                                                    {
                                                        documento.ns2documentoVinculado ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" color='#3a863c' class="bi bi-folder-fill" viewBox="1 0 15 16">
                                                                <path d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"/>
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" color="#1c2b73" class="bi bi-file-earmark-fill" viewBox="1 0 15 16">
                                                                <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                                            </svg>
                                                        )
                                                    }

                                                    { documento["@attributes"].idDocumento} - {documento["@attributes"].descricao}
                                                </a>
                                                    {
                                                        documento.ns2documentoVinculado ? (
                                                            <div id={documento["@attributes"].idDocumento} className="folder-hide">
                                                                {
                                                                    Array.isArray(documento.ns2documentoVinculado) ?
                                                                    documento.ns2documentoVinculado.map((vinculado) =>(
                                                                        <div>
                                                                            <a className="autos-sub-item"  id={vinculado["@attributes"].idDocumento} onClick={() => this.OpenDocument(vinculado)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" color='#1c2b73' class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                                                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                                                                </svg> 
                                                                                {vinculado["@attributes"].idDocumento} - {vinculado["@attributes"].descricao}
                                                                            </a> 
                                                                        </div>      
                                                                    )) : (
                                                                        <div>
                                                                            <a className="autos-sub-item"  id={documento.ns2documentoVinculado["@attributes"].idDocumento} onClick={() => this.OpenDocument(documento.ns2documentoVinculado)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" color='#1c2b73' class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                                                                    <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                                                                </svg> 
                                                                                {documento.ns2documentoVinculado["@attributes"].idDocumento} - {documento.ns2documentoVinculado["@attributes"].descricao}
                                                                            </a>
                                                                        </div>
                                                                    )                                                          
                                                                }
                                                            </div> 
                                                        ) : <></>
                                                    }                                      
                                            </div>
                                        ))
                                    }
                                    </div>
                                    <div style={{textAlign: 'center', width: "70%"}}>
                                        <div style={{display: this.state.loadingAuto ? "" : "none"}}>
                                            <Spinner
                                                type="TailSpin"
                                                color="#2BAD60"
                                                height={70}
                                                width={70}
                                                style={{marginTop: 25}}
                                                visible = {true}
                                            />   
                                            Buscando Auto..                                    
                                        </div>

                                        <iframe src="" id='iframetoHtmlOrPDF' className="folder-hide" style={{marginLeft: 50, marginTop: 25, height: 1000, width: 750}}/>

                                    </div>
                                </div>
                            </div>
                        )
                        ) : (
                            <div style={{display: "flex", flex: 1, flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
                                {
                                    !this.state.error ? (
                                        <>
                                            <Spinner
                                                type="TailSpin"
                                                color="#2BAD60"
                                                height={70}
                                                width={70}
                                                visible = {true}
                                            />
                                            Buscando processo, aguarde.                                        
                                        </>
                                    ) : (
                                        <p className='title-pje'>{this.state.errorMessage}</p>
                                    )
                                }

                            </div>
                        )
                    }
                </div>
            </>
        )
    }
}