import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../components/css/teste.css"
import 'moment-timezone';
import format from "date-fns/format";
import { getToken } from "../auth";
import Button from 'react-bootstrap/Button';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BR from 'date-fns/locale/pt-BR';
import ReactTooltip from 'react-tooltip'
import Axios from 'axios';
import swal from 'sweetalert';
import Spinner from 'react-loader-spinner';
import Topo_Autenticado from './Topo_autenticado.jsx'
import api from '../services/api'
registerLocale('pt-BR', BR)


export default class Home extends Component {

    constructor(props) {
        super(props); //
        this.state = {
            data: [],
            startDate: new Date(),
            showPagination: true,
            menssagemRetorno: " ",
            total_ativos: 0,
            total_estimado: 0,
            total_executado: 0,
            valor_efetivo: 0,
            total_movimentado: 0,
            processos_excluir: [],
            show_loading_importados: false,
            data_pesquisa: 0,

            proc_novos_pages: -1, //? Processos Novos
            proc_novos_page: 1,
            proc_novos_loading: true,
            proc_impro_pages: -1, //? Processos Improcedentes
            proc_impro_page: 1,
            proc_impro_loading: true,
            cob_disp_pages: -1,  //? Processos Cobrança Dispensada
            cob_disp_page: 1,
            cob_disp_loading: true,
            emb_decla_pages: -1,  //? Processos Embargos Declarados
            emb_decla_page: 1,
            emb_decla_loading: true,
            hono_ntransi_pages: -1,  //? Processos Honorários Não Transitados
            hono_ntransi_page: 1,
            hono_ntransi_loading: true,
            hono_transi_pages: -1,  //? Processos Honorários Transitados
            hono_transi_page: 1,
            hono_transi_loading: true,
            proc_exec_pages: -1,  //? Processos Executados
            proc_exec_page: 1,
            proc_exec_loading: true,
            proc_risco_pages: -1,  //? Processos Risco Prescrição
            proc_risco_page: 1,
            proc_risco_loading: true,
            proc_exec_concl_pages: -1,  //? Processos Execução Concluída
            proc_exec_concl_page: 1,
            proc_exec_concl_loading: true,
            cob_admin_pages: -1,  //? Processos Cobrança Administrativa
            cob_admin_page: 0,
            cob_admin_loading: true,
            proc_pendentes_pages: -1,  //? Processos Pendentes
            proc_pendentes_page: 1,
            proc_pendentes_loading: true,
        };

        this.handleChange = this.handleChange.bind(this)
        this.preencherResumo = this.preencherResumo.bind(this)
        //this.handleCheckDeletar = this.handleCheckDeletar.bind(this)
    }

    async preencherResumo()
    {
        await fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getInfosComplementares"
        ,{
            headers:
            {
                'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
        .then(res => res.json())
        .then(
            (result) =>
            {
                if(result !== false)
                {
                    this.setState({
                        total_ativos: result.total_ativos,
                        total_estimado: result.total_estimado,
                        total_executado: result.total_executado,
                        valor_efetivo: result.valor_efetivo,
                        total_movimentado: result.total_movimentado
                    });
                }
                else
                {
                    this.setState({
                        total_ativos: "erro",
                        total_estimado: "erro",
                        total_executado: "erro",
                        total_efetivo: "erro",
                        total_movimentado: "erro"
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    handleChange = async date => {

        this.setState({
          startDate: date
        });

        const dataPesquisa = format(date, "yyyy-MM-dd");
        await this.setState({data_pesquisa: dataPesquisa}) //!

        //console.log("data selecionada enviada para as rotas:")
        //console.log(dataPesquisa)

        this.ReactTableImportados.fireFetchData()
        this.ReactTableRetornados.fireFetchData()
        this.ReactTableCobrancaAdministrativa.fireFetchData()
        this.ReactTableCobrancaDispensada.fireFetchData()
        this.ReactTableEmbargosDeclarados.fireFetchData()
        this.ReactTableHonorariosNTransitados.fireFetchData()
        this.ReactTableHonorariosTransitados.fireFetchData()
        this.ReactTableExecutados.fireFetchData()
        this.ReactTableRiscoPrescricao.fireFetchData()
        this.ReactTableExecucaoConcluida.fireFetchData()
        this.ReactTableImprocedentes.fireFetchData()
        this.ReactTablePendentes.fireFetchData()
    }


    componentDidMount = async() =>
    {
        this.setState({show_loading_importados: true})

        await this.preencherResumo()

        this.setState({
            showPagination: true,
            showPaginationBottom: true,
            menssagemRetorno: 'Sem Processos...',
            show_loading_importados: false
        });
    }


    formataNumeroProcesso(valor){

        /**Criei essa função para formatar a numeração única do processo, o problema é que se
         * a numeração não tiver 20 caracteres a função retorna o numero sem a formatação e
         * se tiver mais de 20 caracteres a função não exibe os numeros a mais, por este motivo fiz uma validação
         * para que se for superior ou inferior a 20 exibe sem formatação.
         * Formato: 9999999-99.9999.9.99.9999
         * Para remover a mascara é só utilizar: formatado.replace(/[^\d]+/g,'')
         */

        //if(valor.lenght > 20 || valor.lenght < 20) ////  lengtH, ;^)
        if (valor.length != 20)
            return valor;
        else
            var formatado = valor.replace(/^(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4}).*/, '$1-$2.$3.$4.$5.$6');
        return formatado;
    }

    filterCaseInsensitive(filter, row) //? essa função permite pesquisar nas tabelas ignorando se as letras são maiúsculas ou minúsculas
    {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    handleCheckDeletar = async(e, processo_numeracao) =>
    {
        //console.log(e.target.checked)
        //console.log(processo_numeracao)
        if (e.target.checked)
        {
            //? adiciona o processo no final do Array de processos para excluir
            await this.setState({processos_excluir: [...this.state.processos_excluir, processo_numeracao]})
        }
        else
        {
            let processos = await this.state.processos_excluir
            try
            {
                let indice = processos.indexOf(processo_numeracao)
                processos.splice(indice, 1)
                await this.setState({processos_excluir: processos})
            }
            catch (error)
            {
            }
        }
        //console.log("array de excluir:")
        //console.log(this.state.processos_excluir)
    }

    deletarProcessos = async() =>
    {
        let obj = {
            processos: this.state.processos_excluir
        }
        //console.log("obj a enviar:")
        //console.log(obj)
        try
        {
            const result = await Axios.post(process.env.REACT_APP_DOMAIN + "/removeImportados", obj,
            {
                headers:
                {
                    'authorization': getToken()
                },
            })
            //console.log(result)
            swal({
                title: "Sucesso!",
                text: "Processo(s) excluído(s)",
                icon: "success",
                timer: 3000,
                button: false
            })
            .then(() => {
                //console.log("aqui")
                //this.getProcessosImportados()
                this.ReactTableImportados.fireFetchData()
            }) //? busca novamente os processos importados após a exclusão
        }
        catch (error)
        {
            //console.log(error)
            //console.log(error.response)
            swal({
                title: "Erro",
                text: "Não foi possível deletar o processo, por favor atualize a tela e tente novamente.",
                icon: "error",
                button: "Fechar"
            })
            .then((e)=>{})
        }
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilterMethod = (filter, row) =>
    {
        let valorPesquisa = filter.value
        /* //!
            abaixo, remove '-' e '.' e aplica manualmente a máscara,
            assim fica indiferente pro user digitar a pesquisa {com/sem/parcialmente} com máscara
        */
        valorPesquisa = valorPesquisa.replace(/-|\./g, "")
        if (valorPesquisa.length >= 8)
        {
            valorPesquisa = valorPesquisa.substring(0, 7) + '-' + valorPesquisa.substring(7)
        }
        if (valorPesquisa.length >= 10)
        {
            valorPesquisa = valorPesquisa.substring(0, 10) + '.' + valorPesquisa.substring(10)
        }
        if (valorPesquisa.length >= 15)
        {
            valorPesquisa = valorPesquisa.substring(0, 15) + '.' + valorPesquisa.substring(15)
        }
        if (valorPesquisa.length >= 17)
        {
            valorPesquisa = valorPesquisa.substring(0, 17) + '.' + valorPesquisa.substring(17)
        }
        if (valorPesquisa.length >= 20)
        {
            valorPesquisa = valorPesquisa.substring(0, 20) + '.' + valorPesquisa.substring(20)
        }

        if (row.numeracao_unica.indexOf(valorPesquisa) !== -1)
            return row
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilter = (filter, onChange) =>
    {
        return (
            <div>
                <input
                    type='text'
                    value = {filter? filter.value:""}
                    maxLength= {25}
                    onChange={(event) => onChange(event.target.value)}
                />
            </div>
        )
    }


    getProcessos = async(pagina = 0, filtros = {}, tipoProcessos = 1) =>
    {
        try
        {
            //console.log("filtros na rota:")
            //console.log(filtros)
            let res = await api.post(
                `/cadastrar-processo/getProcessos/${tipoProcessos}/${this.state.data_pesquisa}/${pagina}`,
                filtros
            )
            if (res.data == 0)
            {
                //console.log("essa tabela ficara vazia:")
                //console.log(res)
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: []
                    }
                }
            }
            else
                return res
        }
        catch (error)
        {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    customOnFetchData = async(tableState, instance, tipoProcessos) =>
    {
        let filtros = {}
        tableState.filtered.map((filtro) => {
            if (filtro.id === 'numeracao_unica' && filtro.value.length >= 6)
                filtros.numeracao_unica = filtro.value

            else if (filtro.id === 'comarca' && filtro.value.length >= 3)
                filtros.comarca = filtro.value.toUpperCase()

            else if (filtro.id === 'motivo_pendencia' && filtro.value.length >= 3)
                filtros.motivo_pendencia = filtro.value.toUpperCase()

            else if (filtro.id === 'data_ingresso' && filtro.value.length === 10)
                filtros.data_ingresso = filtro.value

            else if (filtro.id === 'data_transito_julgado' && filtro.value.length === 10)
                filtros.data_transito_julgado = filtro.value

            else if (filtro.id === 'data_retorno' && filtro.value.length === 10)
                filtros.data_retorno = filtro.value
        })
        return await this.getProcessos(tableState.page+1, filtros, tipoProcessos)
    }


    render() {

        //São as variaveis que trazem os dados para popular as tabelas
        const {
            processosImprocedentes,
            cobrancaDispensada,
            embargosDeclarados,
            honorariosNaoTransitados,
            honorariosTransitados,
            executados,
            riscoPrescricao,
            execucaoConcluida,
            cobrancaAdministrativa,
            processosPendentes,
            startDate, showPagination, showPaginationBottom, menssagemRetorno,

        } = this.state;

        //renderiza a tela
        return (
            <>
            <Topo_Autenticado/>
                <div className="row">
                    <div className="col">
                        <DatePicker
                            selected={startDate}
                            onChange={this.handleChange}
                            dateFormat="DD/MM/YYYY"
                            locale="pt-BR"
                            inline
                        /> <br/>

                        <div className="row">
                            <div className='col'>
                                <h6>Cadastro Manual</h6>
                            </div>
                            <div className='col'>
                                <a href="/#/cadastrar-processo">
                                    <img src="/static/media/icons8-adicionar.f109a600.svg" alt=""/>
                                </a>
                            </div>
                        </div>

                        <br/>
                        <div style={{border: "dotted #1c2b73 1px", background:"#ECF4DC" }}>
                            <div className="center">
                                <h6><b>Resumo</b></h6>
                            </div>
                            <br/>
                            <ul style={{margin: "5px" }}>
                                <li><b>Processos Ativos: {this.state.total_ativos}</b></li><br/>
                                <li><b>Total Estimado: {this.state.total_estimado}</b></li><br/>
                                <li><b>Total Executado: {this.state.total_executado}</b></li><br/>
                                <li><b>Total Efetivo: {this.state.valor_efetivo}</b></li><br/>
                                <li><b>{this.state.total_movimentado} Processos movimentados</b></li>
                            </ul>
                            <br></br>
                            <div className="center">
                                <h6><b>Legenda</b></h6>
                            </div>
                            <br/>
                            <ul style={{margin: "5px" }}>
                                <li><b><img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />  Processo Prioritário</b></li><br/>
                                <li><b><img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />  Parte Principal do Processo</b></li><br/>
                            </ul>
                        </div>

                    </div>
                <div className="center">
                    <h5 className="text-center"><b>PROCESSOS POR SITUAÇÃO</b></h5>
                </div>

                <div className="row">
                    <div className="col" style={{width: "550px" }}>
                        <ReactTable
                            data = {cobrancaAdministrativa}
                            pages = {this.state.cob_admin_pages}
                            manual
                            ref = {ref => this.ReactTableCobrancaAdministrativa = ref}
                            loading = {this.state.cob_admin_loading}
                            onFetchData = { async(state, instance) => {
                               this.setState({cob_admin_loading: true})

                               const dados = await this.customOnFetchData(state, instance, 11)

                               const cobrancaAdministrativa = dados.data.processos.map(cobrancaAdministrativa => ({
                                    numeracao_unica: cobrancaAdministrativa.numeracao_unica ? this.formataNumeroProcesso(cobrancaAdministrativa.numeracao_unica): "",
                                    data_ingresso: cobrancaAdministrativa.data_ingresso,
                                    prioritario: cobrancaAdministrativa.prioritario ? true : false,
                                    principal: cobrancaAdministrativa.principal ? true : false,
                                    atos_nao_lidos: cobrancaAdministrativa.atos_nao_lidos ? true : false,
                                    avisos_lidos: cobrancaAdministrativa.aviso_lidos,
                                    comarca: cobrancaAdministrativa.comarca
                                }))

                                this.setState({
                                    cobrancaAdministrativa: cobrancaAdministrativa,
                                    cob_admin_pages: dados.data.pages,
                                    cob_admin_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Cobrança Pré Processual",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>
                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data Ingresso",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {15}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >
                        </ReactTable>
                    </div>

                    {/* <div className="col" style={{width: "550px" }}> */}
                        {/* <ReactTable */}
                            {/* data={cobrancaDispensada} */}
                            {/* pages={this.state.cob_disp_pages} */}
                            {/* manual */}
                            {/* ref = {ref => this.ReactTableCobrancaDispensada = ref} */}
                            {/* loading = {this.state.cob_disp_loading} */}
                            {/* onFetchData = { async(state, instance) => { */}
                               {/* this.setState({cob_disp_loading: true}) */}

                               {/* const dados = await this.customOnFetchData(state, instance, 2) */}

                                {/* const cobrancaDispensada = dados.data.processos.map(cobrancaDispensada => ({ */}
                                    {/* numeracao_unica: cobrancaDispensada.numeracao_unica ? this.formataNumeroProcesso(cobrancaDispensada.numeracao_unica): "", */}
                                    {/* data_ingresso: cobrancaDispensada.data_ingresso, */}
                                    {/* prioritario: cobrancaDispensada.prioritario ? true : false, */}
                                    {/* principal: cobrancaDispensada.principal ? true : false, */}
                                    {/* motivo: cobrancaDispensada.resolucao, */}
                                    {/* comarca: cobrancaDispensada.comarca */}
                                {/* }));  */}

                                {/* this.setState({ */}
                                    {/* cobrancaDispensada, */}
                                    {/* cob_disp_pages: dados.data.pages, */}
                                    {/* cob_disp_loading: false */}
                                {/* }) */}
                            {/* }} */}
                            {/* columns={[{ */}
                                {/* Header: "Cobrança Dispensada", */}
                                {/* columns: [ */}
                                    {/* { */}
                                        {/* Header: "Numeração Única", */}
                                        {/* id: "numeracao_unica", */}
                                        {/* width: 280, */}
                                        {/* accessor: "numeracao_unica", */}
                                        {/* filterMethod: (filter, row) => { */}
                                            {/* return this.columnCustomFilterMethod(filter, row) */}
                                        {/* }, */}
                                        {/* Filter: ({ filter, onChange }) => { */}
                                            {/* return this.columnCustomFilter(filter, onChange) */}
                                        {/* }, */}
                                        {/* Cell: e =><div style={{ textAlign: "center" }}> */}
                                                        {/* <a href={'/#/editar-processo/'+e.value}> {e.value} </a> */}

                                                    {/* {e.original.prioritario ? */}
                                                        {/* <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} /> */}
                                                    {/* : */}
                                                        {/* null */}
                                                    {/* } */}
                                                    {/* <ReactTooltip place="top" type="dark" effect="float"/> */}

                                                    {/* {e.original.principal ? */}
                                                        {/* <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} /> */}
                                                    {/* : */}
                                                        {/* null */}
                                                    {/* } */}
                                                    {/* <ReactTooltip place="top" type="dark" effect="float"/> */}
                                                    {/* </div> */}
                                    {/* }, */}
                                    {/* { */}
                                        {/* Header: "Data do Ingresso", */}
                                        {/* accessor: "data_ingresso", */}
                                        {/* Cell: row => ( */}
                                            {/* <div style={{ textAlign: "center" }}>{row.value}</div> */}
                                        {/* ) */}
                                    {/* }, */}
                                    {/* { */}
                                        {/* Header: "Comarca", */}
                                        {/* accessor: "comarca", */}
                                        {/* //width: 80, */}
                                        {/* Cell: row => ( */}
                                            {/* <div style={{ textAlign: "center" }}>{row.value}</div> */}
                                        {/* ) */}
                                    {/* }, */}
                                    {/* { */}
                                        {/* Header: "Motivo", */}
                                        {/* accessor: "motivo", */}
                                        {/* Cell: row => ( */}
                                            {/* <div style={{ textAlign: "left" }}>{row.original.motivo}</div> */}
                                        {/* ) */}
                                    {/* } */}
                                {/* ] */}
                            {/* }]}                         */}
                            {/* filterable */}
                            {/* className="-striped -highlight"  */}
                            {/* defaultFilterMethod={this.filterCaseInsensitive} */}

                            {/* //Paginação */}
                            {/* showPagination= {showPagination}                    */}
                            {/* showPaginationBottom= {showPaginationBottom} */}
                            {/* showPageSizeOptions= {false} */}
                            {/* defaultPageSize= {15} */}

                            {/* noData   */}
                            {/* noDataText= {menssagemRetorno} */}

                            {/* // Texto */}
                            {/* previousText= 'Anterior' */}
                            {/* nextText= 'Próximo' */}
                            {/* loadingText= 'Carregando...' */}
                            {/* pageText= 'Páginas' */}
                            {/* ofText= 'de' */}
                            {/* rowsText= 'Linhas' */}
                        {/* > */}

                        {/* </ReactTable> */}
                    {/* </div> */}

                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={embargosDeclarados}
                            pages={this.state.emb_decla_pages}
                            manual
                            ref = {ref => this.ReactTableEmbargosDeclarados = ref}
                            loading = {this.state.emb_decla_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({emb_decla_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 9)

                                const embargosDeclarados = dados.data.processos.map(embargosDeclarados => ({
                                    numeracao_unica: embargosDeclarados.numeracao_unica ? this.formataNumeroProcesso(embargosDeclarados.numeracao_unica): "",
                                    data_ingresso: embargosDeclarados.data_ingresso,
                                    prioritario: embargosDeclarados.prioritario ? true : false,
                                    principal: embargosDeclarados.principal ? true : false,
                                    atos_nao_lidos: embargosDeclarados.atos_nao_lidos ? true : false,
                                    avisos_lidos: embargosDeclarados.aviso_lidos,
                                    comarca: embargosDeclarados.comarca
                                }));

                                this.setState({
                                    embargosDeclarados,
                                    emb_decla_pages: dados.data.pages,
                                    emb_decla_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Ação Rescisória",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Ingresso",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {15}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>
                </div>

                <div className="row">
                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={honorariosNaoTransitados}
                            pages={this.state.hono_ntransi_pages}
                            manual
                            ref = {ref => this.ReactTableHonorariosNTransitados = ref}
                            loading = {this.state.hono_ntransi_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({hono_ntransi_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 5)

                                const honorariosNaoTransitados = dados.data.processos.map(honorariosNaoTransitados => ({
                                    numeracao_unica: honorariosNaoTransitados.numeracao_unica ? this.formataNumeroProcesso(honorariosNaoTransitados.numeracao_unica): "",
                                    data_ingresso: honorariosNaoTransitados.data_ingresso,
                                    prioritario: honorariosNaoTransitados.prioritario ? true : false,
                                    principal: honorariosNaoTransitados.principal ? true : false,
                                    atos_nao_lidos: honorariosNaoTransitados.atos_nao_lidos ? true : false,
                                    avisos_lidos: honorariosNaoTransitados.aviso_lidos,
                                    comarca:honorariosNaoTransitados.comarca
                                }))

                                this.setState({
                                    honorariosNaoTransitados,
                                    hono_ntransi_pages: dados.data.pages,
                                    hono_ntransi_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Honorários Não Transitados",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Último Retorno",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {5}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>


                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={honorariosTransitados}
                            pages={this.state.hono_transi_pages}
                            manual
                            ref = {ref => this.ReactTableHonorariosTransitados = ref}
                            loading = {this.state.hono_transi_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({hono_transi_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 6)

                                const honorariosTransitados = dados.data.processos.map(honorariosTransitados => ({
                                    numeracao_unica: honorariosTransitados.numeracao_unica ? this.formataNumeroProcesso(honorariosTransitados.numeracao_unica): "",
                                    data_ingresso: honorariosTransitados.data_ingresso,
                                    prioritario: honorariosTransitados.prioritario ? true : false,
                                    principal: honorariosTransitados.principal ? true : false,
                                    atos_nao_lidos: honorariosTransitados.atos_nao_lidos ? true : false,
                                    avisos_lidos: honorariosTransitados.aviso_lidos,
                                    comarca: honorariosTransitados.comarca
                                }))

                                this.setState({
                                    honorariosTransitados,
                                    hono_transi_pages: dados.data.pages,
                                    hono_transi_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Honorários Transitados",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Ingresso",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {5}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>
                </div>

                <div className="row">

                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={executados}
                            pages={this.state.proc_exec_pages}
                            manual
                            ref = {ref => this.ReactTableExecutados = ref}
                            loading = {this.state.proc_exec_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({proc_exec_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 3)

                                const executados = dados.data.processos.map(executados => ({
                                    numeracao_unica: executados.numeracao_unica ? this.formataNumeroProcesso(executados.numeracao_unica): "",
                                    data_ingresso: executados.data_ingresso,
                                    prioritario: executados.prioritario ? true : false,
                                    principal: executados.principal ? true : false,
                                    atos_nao_lidos: executados.atos_nao_lidos ? true : false,
                                    avisos_lidos: executados.aviso_lidos,
                                    comarca: executados.comarca
                                }))

                                this.setState({
                                    executados,
                                    proc_exec_pages: dados.data.pages,
                                    proc_exec_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Executados",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>
                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Último Retorno",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {5}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>


                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={riscoPrescricao}
                            pages={this.state.proc_risco_pages}
                            manual
                            ref = {ref => this.ReactTableRiscoPrescricao = ref}
                            loading = {this.state.proc_risco_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({proc_risco_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 10)

                                const riscoPrescricao = dados.data.processos.map(riscoPrescricao => ({
                                    numeracao_unica: riscoPrescricao.numeracao_unica ? this.formataNumeroProcesso(riscoPrescricao.numeracao_unica): "",
                                    data_transito_julgado: riscoPrescricao.data_transito_julgado,
                                    prioritario: riscoPrescricao.prioritario ? true : false,
                                    principal: riscoPrescricao.principal ? true : false,
                                    atos_nao_lidos: riscoPrescricao.atos_nao_lidos ? true : false,
                                    avisos_lidos: riscoPrescricao.aviso_lidos,
                                    comarca: riscoPrescricao.comarca
                                }))

                                this.setState({
                                    riscoPrescricao,
                                    proc_risco_pages: dados.data.pages,
                                    proc_risco_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Risco de Prescrição",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>
                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Trânsito em Julgado",
                                        accessor: "data_transito_julgado",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {5}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>
                </div>

                <div className="row">

                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={execucaoConcluida}
                            pages={this.state.proc_exec_concl_pages}
                            manual
                            ref = {ref => this.ReactTableExecucaoConcluida = ref}
                            loading = {this.state.proc_exec_concl_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({proc_exec_concl_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 4)

                                const execucaoConcluida = dados.data.processos.map(execucaoConcluida => ({
                                    numeracao_unica: execucaoConcluida.numeracao_unica ? this.formataNumeroProcesso(execucaoConcluida.numeracao_unica): "",
                                    data_ingresso: execucaoConcluida.data_ingresso,
                                    prioritario: execucaoConcluida.prioritario ? true : false,
                                    principal: execucaoConcluida.principal ? true : false,
                                    atos_nao_lidos: execucaoConcluida.atos_nao_lidos ? true : false,
                                    avisos_lidos: execucaoConcluida.aviso_lidos,
                                    comarca: execucaoConcluida.comarca
                                }));

                                this.setState({
                                    execucaoConcluida,
                                    proc_exec_concl_pages: dados.data.pages,
                                    proc_exec_concl_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Execução Concluida",
                                columns: [
                                    {
                                        Header: "Numeração Única",
                                        id: "numeracao_unica",
                                        width: 280,
                                        accessor: "numeracao_unica",
                                        /* filterMethod: (filter, row) => {
                                            return this.columnCustomFilterMethod(filter, row)
                                        },
                                        Filter: ({ filter, onChange }) => {
                                            return this.columnCustomFilter(filter, onChange)
                                        }, */
                                        Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                    },
                                    {
                                        Header: "Comarca",
                                        accessor: "comarca",
                                        //width: 80,
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    },
                                    {
                                        Header: "Data do Último Retorno",
                                        accessor: "data_ingresso",
                                        Cell: row => (
                                            <div style={{ textAlign: "center" }}>{row.value}</div>
                                        )
                                    }
                                ]
                            }]}
                            filterable
                            className="-striped -highlight"
                            defaultFilterMethod={this.filterCaseInsensitive}

                            //Paginação
                            showPagination= {showPagination}
                            showPaginationBottom= {showPaginationBottom}
                            showPageSizeOptions= {false}
                            defaultPageSize= {5}

                            noData
                            noDataText= {menssagemRetorno}

                            // Texto
                            previousText= 'Anterior'
                            nextText= 'Próximo'
                            loadingText= 'Carregando...'
                            pageText= 'Páginas'
                            ofText= 'de'
                            rowsText= 'Linhas'
                        >

                        </ReactTable>
                    </div>

                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={processosImprocedentes}
                            pages={this.state.proc_impro_pages}
                            manual
                            ref = {ref => this.ReactTableImprocedentes = ref}
                            loading = {this.state.proc_impro_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({proc_impro_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 7)

                                const processosImprocedentes = dados.data.processos.map(processosImprocedentes => ({
                                    numeracao_unica: processosImprocedentes.numeracao_unica ? this.formataNumeroProcesso(processosImprocedentes.numeracao_unica): "",
                                    data_ingresso: processosImprocedentes.data_ingresso,
                                    prioritario: processosImprocedentes.prioritario ? true : false,
                                    principal: processosImprocedentes.principal ? true : false,
                                    atos_nao_lidos: processosImprocedentes.atos_nao_lidos ? true : false,
                                    avisos_lidos: processosImprocedentes.aviso_lidos,
                                    comarca: processosImprocedentes.comarca
                                }))

                                this.setState({
                                    processosImprocedentes,
                                    proc_impro_pages: dados.data.pages,
                                    proc_impro_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Improcedente",
                                columns: [
                                {
                                    Header: "Numeração Única",
                                    id: "numeracao_unica",
                                    width: 280,
                                    accessor: "numeracao_unica",
                                    /* filterMethod: (filter, row) => {
                                        return this.columnCustomFilterMethod(filter, row)
                                    },
                                    Filter: ({ filter, onChange }) => {
                                        return this.columnCustomFilter(filter, onChange)
                                    }, */
                                    Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                    {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                },
                                {
                                    Header: "Comarca",
                                    accessor: "comarca",
                                    //width: 80,
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>{row.value}</div>
                                    )
                                },
                                {
                                    Header: "Data do Ingresso",
                                    accessor: "data_ingresso",
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>{row.value}</div>
                                    )
                                }
                            ]
                        }]}
                        filterable
                        className="-striped -highlight"
                        defaultFilterMethod={this.filterCaseInsensitive}

                        //Paginação
                        showPagination= {showPagination}
                        showPaginationBottom= {showPaginationBottom}
                        showPageSizeOptions= {false}
                        defaultPageSize= {5}

                        noData
                        noDataText= {menssagemRetorno}

                        // Texto
                        previousText= 'Anterior'
                        nextText= 'Próximo'
                        loadingText= 'Carregando...'
                        pageText= 'Páginas'
                        ofText= 'de'
                        rowsText= 'Linhas'
                        >
                        </ReactTable>
                    </div>
                </div>

                <div className="row">

                    <div className="col" style={{width: "690px" }}>
                        <ReactTable
                            data={processosPendentes}
                            pages={this.state.proc_pendentes_pages}
                            manual
                            ref = {ref => this.ReactTablePendentes = ref}
                            loading = {this.state.proc_pendentes_loading}
                            onFetchData = { async(state, instance) => {
                                this.setState({proc_pendentes_loading: true})

                                const dados = await this.customOnFetchData(state, instance, 12)

                                const processosPendentes = dados.data.processos.map(pendente => ({
                                    numeracao_unica: pendente.numeracao_unica ? this.formataNumeroProcesso(pendente.numeracao_unica): "",
                                    //data_ingresso: pendente.data_ingresso,
                                    prioritario: pendente.prioritario ? true : false,
                                    principal: pendente.principal ? true : false,
                                    motivo_pendencia: pendente.motivo_pendencia,
                                    atos_nao_lidos: pendente.atos_nao_lidos ? true : false,
                                    avisos_lidos: pendente.aviso_lidos,
                                    comarca: pendente.comarca
                                }))

                                this.setState({
                                    processosPendentes,
                                    proc_pendentes_pages: dados.data.pages,
                                    proc_pendentes_loading: false
                                })
                            }}
                            columns={[{
                                Header: "Processos Pendentes",
                                columns: [
                                {
                                    Header: "Numeração Única",
                                    id: "numeracao_unica",
                                    width: 280,
                                    accessor: "numeracao_unica",
                                    /* filterMethod: (filter, row) => {
                                        return this.columnCustomFilterMethod(filter, row)
                                    },
                                    Filter: ({ filter, onChange }) => {
                                        return this.columnCustomFilter(filter, onChange)
                                    }, */
                                    Cell: e =><div style={{ textAlign: "center" }}>
                                                    <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                                    {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                    {e.original.atos_nao_lidos ?
                                                        <img alt="" data-tip="Novo Ato Processual"  src={require("../images/list3.png")} />
                                                    :
                                                        null
                                                    }
                                                     {e.original.avisos_lidos ?
                                                        <img alt="" data-tip="Novo Aviso Pendente"  src={require("../images/aviso_image3.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                                </div>
                                },
                                {
                                    Header: "Comarca",
                                    accessor: "comarca",
                                    //width: 80,
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>{row.value}</div>
                                    )
                                },
                                {
                                    Header: "Motivo",
                                    accessor: "motivo_pendencia",
                                    Cell: row => (
                                        <div style={{ textAlign: "left" }}>{row.original.motivo_pendencia}</div>
                                    )
                                }
                            ]
                        }]}
                        filterable
                        className="-striped -highlight"
                        defaultFilterMethod={this.filterCaseInsensitive}

                        //Paginação
                        showPagination= {showPagination}
                        showPaginationBottom= {showPaginationBottom}
                        showPageSizeOptions= {false}
                        defaultPageSize= {5}

                        noData
                        noDataText= {menssagemRetorno}

                        // Texto
                        previousText= 'Anterior'
                        nextText= 'Próximo'
                        loadingText= 'Carregando...'
                        pageText= 'Páginas'
                        ofText= 'de'
                        rowsText= 'Linhas'
                        >
                        </ReactTable>
                    </div>
                </div>
            </div>
            </>
        )
    }

}