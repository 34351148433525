import React, { Component } from 'react';
import 'materialize-css'; // It installs the JS asset only
import 'materialize-css/dist/css/materialize.min.css';

export default class Rodape extends Component {
    render() {
        return (
          <body>
            {/*<header></header>
            <main></main>   usar essas tags empurra o footer para o fim da tela */}
            <footer className ="page-footer blue">
              <div className ="footer-copyright blue">
                <div className ="footer-container">
                  Sistema de Honorários - Defensoria Pública do Estado do Pará
                </div>
              </div>
            </footer>
          </body>
        );
    }
}