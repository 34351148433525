import Axios from 'axios'
import { getToken } from '../auth';

const api = Axios.create({
    baseURL: process.env.REACT_APP_DOMAIN
})

api.interceptors.request.use(function (config) {
    config.headers = {
        'content-type': 'application/json',
        'authorization': getToken()
    }
    return config
});


export default api
