import React, {Component} from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../components/css/teste.css"
import 'moment-timezone';
import format from "date-fns/format";
import { getToken } from "../auth";
import Button from 'react-bootstrap/Button';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BR from 'date-fns/locale/pt-BR';
import ReactTooltip from 'react-tooltip'
import Axios from 'axios';
import swal from 'sweetalert';
import Spinner from 'react-loader-spinner';
import Topo_Autenticado from './Topo_autenticado.jsx'
import api from '../services/api'
registerLocale('pt-BR', BR)


export default class Home extends Component {

    constructor(props) {
        super(props); //
        this.state = {
            data: [],
            startDate: new Date(),
            showPagination: true,
            menssagemRetorno: " ",
            total_ativos: 0,
            total_estimado: 0,
            total_executado: 0,
            valor_efetivo: 0,
            total_movimentado: 0,
            processos_excluir: [],
            show_loading_importados: false,
            data_pesquisa: 0,
            
            proc_retornados_pages: -1, //? Processos Retornados
            proc_retornados_page: 1,
            proc_retornados_loading: true,
            proc_impor_pages: -1,  //? Processos Importados
            proc_impor_page: 1,
            proc_impor_loading: true,
        };

        this.handleChange = this.handleChange.bind(this)
        this.preencherResumo = this.preencherResumo.bind(this)
        //this.handleCheckDeletar = this.handleCheckDeletar.bind(this)
    }

    async preencherResumo()
    {
        await fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getInfosComplementares"
        ,{
            headers:
            {
                'content-type': 'application/x-www-form-urlencoded',
                'authorization': getToken()
            },
        })
        .then(res => res.json())
        .then(
            (result) =>
            {
                if(result !== false)
                {
                    this.setState({
                        total_ativos: result.total_ativos,
                        total_estimado: result.total_estimado,
                        total_executado: result.total_executado,
                        valor_efetivo: result.valor_efetivo,
                        total_movimentado: result.total_movimentado
                    });
                }
                else
                {    
                    this.setState({
                        total_ativos: "erro",
                        total_estimado: "erro",
                        total_executado: "erro",
                        total_efetivo: "erro",
                        total_movimentado: "erro"
                    });
                }
            },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        );
    }

    handleChange = async date => {
        
        this.setState({
          startDate: date    
        });
        
        const dataPesquisa = format(date, "yyyy-MM-dd");
        await this.setState({data_pesquisa: dataPesquisa}) //!

        //console.log("data selecionada enviada para as rotas:")
        //console.log(dataPesquisa)

        this.ReactTableImportados.fireFetchData()
        this.ReactTableRetornados.fireFetchData()
        this.ReactTableCobrancaAdministrativa.fireFetchData()
        this.ReactTableCobrancaDispensada.fireFetchData()
        this.ReactTableEmbargosDeclarados.fireFetchData()
        this.ReactTableHonorariosNTransitados.fireFetchData()
        this.ReactTableHonorariosTransitados.fireFetchData()
        this.ReactTableExecutados.fireFetchData()
        this.ReactTableRiscoPrescricao.fireFetchData()
        this.ReactTableExecucaoConcluida.fireFetchData()
        this.ReactTableImprocedentes.fireFetchData()
        this.ReactTablePendentes.fireFetchData()
    }


    componentDidMount = async() =>
    {
        this.setState({show_loading_importados: true})

        await this.preencherResumo()

        this.setState({ 
            showPagination: true, 
            showPaginationBottom: true, 
            menssagemRetorno: 'Sem Processos...',
            show_loading_importados: false
        });        
    }


    formataNumeroProcesso(valor){

        /**Criei essa função para formatar a numeração única do processo, o problema é que se
         * a numeração não tiver 20 caracteres a função retorna o numero sem a formatação e 
         * se tiver mais de 20 caracteres a função não exibe os numeros a mais, por este motivo fiz uma validação 
         * para que se for superior ou inferior a 20 exibe sem formatação.
         * Formato: 9999999-99.9999.9.99.9999
         * Para remover a mascara é só utilizar: formatado.replace(/[^\d]+/g,'')
         */

        //if(valor.lenght > 20 || valor.lenght < 20) ////  lengtH, ;^) 
        if (valor.length != 20)
            return valor;
        else
            var formatado = valor.replace(/^(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4}).*/, '$1-$2.$3.$4.$5.$6');
        return formatado;
    }

    filterCaseInsensitive(filter, row) //? essa função permite pesquisar nas tabelas ignorando se as letras são maiúsculas ou minúsculas
    {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
                true
        );
    }

    handleCheckDeletar = async(e, processo_numeracao) =>
    {
        //console.log(e.target.checked)
        //console.log(processo_numeracao)
        if (e.target.checked)
        {
            //? adiciona o processo no final do Array de processos para excluir
            await this.setState({processos_excluir: [...this.state.processos_excluir, processo_numeracao]})
        }
        else
        {
            let processos = await this.state.processos_excluir
            try
            {
                let indice = processos.indexOf(processo_numeracao)
                processos.splice(indice, 1)
                await this.setState({processos_excluir: processos})
            }
            catch (error)
            {    
            }
        }
        //console.log("array de excluir:")
        //console.log(this.state.processos_excluir)
    }

    deletarProcessos = async() =>
    {
        let obj = {
            processos: this.state.processos_excluir
        }
        //console.log("obj a enviar:")
        //console.log(obj)
        try
        {
            const result = await Axios.post(process.env.REACT_APP_DOMAIN + "/removeImportados", obj,
            {
                headers:
                {
                    'authorization': getToken()
                },
            })
            //console.log(result)
            swal({
                title: "Sucesso!",
                text: "Processo(s) excluído(s)",
                icon: "success",
                timer: 3000,
                button: false
            })
            .then(() => {
                //console.log("aqui")
                //this.getProcessosImportados()
                this.ReactTableImportados.fireFetchData()
            }) //? busca novamente os processos importados após a exclusão
        }
        catch (error)
        {
            //console.log(error)
            //console.log(error.response)
            swal({
                title: "Erro",
                text: "Não foi possível deletar o processo, por favor atualize a tela e tente novamente.",
                icon: "error",
                button: "Fechar"
            })
            .then((e)=>{})
        }
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilterMethod = (filter, row) =>
    {
        let valorPesquisa = filter.value
        /* //!
            abaixo, remove '-' e '.' e aplica manualmente a máscara,
            assim fica indiferente pro user digitar a pesquisa {com/sem/parcialmente} com máscara
        */
        valorPesquisa = valorPesquisa.replace(/-|\./g, "")
        if (valorPesquisa.length >= 8)
        {
            valorPesquisa = valorPesquisa.substring(0, 7) + '-' + valorPesquisa.substring(7)
        }
        if (valorPesquisa.length >= 10)
        {
            valorPesquisa = valorPesquisa.substring(0, 10) + '.' + valorPesquisa.substring(10)
        }
        if (valorPesquisa.length >= 15)
        {
            valorPesquisa = valorPesquisa.substring(0, 15) + '.' + valorPesquisa.substring(15)
        }
        if (valorPesquisa.length >= 17)
        {
            valorPesquisa = valorPesquisa.substring(0, 17) + '.' + valorPesquisa.substring(17)
        }
        if (valorPesquisa.length >= 20)
        {
            valorPesquisa = valorPesquisa.substring(0, 20) + '.' + valorPesquisa.substring(20)
        }
        
        if (row.numeracao_unica.indexOf(valorPesquisa) !== -1)
            return row
    }

    //! a função abaixo não é mais utilizada pq o filtro de pesquisa é enviado pro back-end devido à prop 'manual' nas tabelas
    columnCustomFilter = (filter, onChange) =>
    {
        return (
            <div>
                <input
                    type='text'
                    value = {filter? filter.value:""}
                    maxLength= {25}
                    onChange={(event) => onChange(event.target.value)}
                />
            </div>
        )
    }

    getProcessosImportados = async(pagina = 0, filtros = {}) =>
    {
        //console.log("filtros na rota:")
        //console.log(filtros)
        try
        {
            let res = await api.post(
                `/cadastrar-processo/getImportados/${this.state.data_pesquisa}/${pagina}`,
                filtros,
            )
            if (res.data == 0)
            {
                //console.log("tbl importados vazia!:")
                //console.log(res)
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: []
                    }
                }
            }
            else
                return res
        }
        catch (error)
        {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    getProcessos = async(pagina = 0, filtros = {}, tipoProcessos = 1) =>
    {
        try
        {
            //console.log("filtros na rota:")
            //console.log(filtros)
            let res = await api.post(
                `/cadastrar-processo/getProcessos/${tipoProcessos}/${this.state.data_pesquisa}/${pagina}`,
                filtros
            )
            if (res.data == 0)
            {
               //console.log("essa tabela ficara vazia:")
                //console.log(res)
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: []
                    }
                }
            }
            else
                return res
        }
        catch (error)
        {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    customOnFetchData = async(tableState, instance, tipoProcessos) =>
    {
        let filtros = {}
        tableState.filtered.map((filtro) => {
            if (filtro.id === 'numeracao_unica' && filtro.value.length >= 6)
                filtros.numeracao_unica = filtro.value

            else if (filtro.id === 'comarca' && filtro.value.length >= 3)
                filtros.comarca = filtro.value.toUpperCase()

            else if (filtro.id === 'motivo_pendencia' && filtro.value.length >= 3)
                filtros.motivo_pendencia = filtro.value.toUpperCase()

            else if (filtro.id === 'data_ingresso' && filtro.value.length === 10)
                filtros.data_ingresso = filtro.value

            else if (filtro.id === 'data_transito_julgado' && filtro.value.length === 10)
                filtros.data_transito_julgado = filtro.value

            else if (filtro.id === 'data_retorno' && filtro.value.length === 10)
                filtros.data_retorno = filtro.value
        })

        return await this.getProcessos(tableState.page+1, filtros, tipoProcessos)
    }

    
    render() {

        //São as variaveis que trazem os dados para popular as tabelas
        const { 
            processosNovos, 
            processosRetornados, 
            processosImprocedentes,
            cobrancaDispensada,
            embargosDeclarados,
            honorariosNaoTransitados,
            honorariosTransitados,
            executados,
            riscoPrescricao,
            execucaoConcluida,
            cobrancaAdministrativa,
            importados,
            processosPendentes,
            startDate, showPagination, showPaginationBottom, menssagemRetorno,
    
        } = this.state;

        //renderiza a tela
        return (
            <>
            <Topo_Autenticado/>
            <div style={{ padding: '20px' }}>
                <div className="row">
                    <div className="col">
                        <DatePicker
                            selected={startDate}
                            onChange={this.handleChange}
                            dateFormat="DD/MM/YYYY"
                            locale="pt-BR"
                            inline
                        /> <br/>

                        <div className="row">
                            <div className='col'>
                                <h6>Cadastro Manual</h6>
                            </div>
                            <div className='col'>
                                <a href="/#/cadastrar-processo">
                                    <img src="/static/media/icons8-adicionar.f109a600.svg" alt=""/>  
                                </a>
                            </div>                             
                        </div>

                        <br/>
                        <div style={{border: "dotted #1c2b73 1px", background:"#ECF4DC" }}>
                            <div className="center">
                                <h6><b>Resumo</b></h6>
                            </div>  
                            <br/>
                            <ul style={{margin: "5px" }}>             
                                <li><b>Processos Ativos: {this.state.total_ativos}</b></li><br/>
                                <li><b>Total Estimado: {this.state.total_estimado}</b></li><br/>
                                <li><b>Total Executado: {this.state.total_executado}</b></li><br/>
                                <li><b>Total Efetivo: {this.state.valor_efetivo}</b></li><br/>
                                <li><b>{this.state.total_movimentado} Processos movimentados</b></li>
                            </ul>
                            <br></br>
                            <div className="center">
                                <h6><b>Legenda</b></h6>
                            </div>  
                            <br/>
                            <ul style={{margin: "5px" }}>             
                                <li><b><img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />  Processo Prioritário</b></li><br/>
                                <li><b><img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />  Parte Principal do Processo</b></li><br/>
                            </ul>
                        </div>

                    </div>

                    <div className="col" style={{width: "600px" }}>
                        {this.state.show_loading_importados?
                            <div style={{display: "flex", flex: 1, flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
                                <Spinner
                                    type="TailSpin"
                                    color="#2BAD60"
                                    height={100}
                                    width={100}
                                    visible = {true}
                                />
                                Buscando processos importados, aguarde.
                            </div>
                            :
                            <>
                                <ReactTable
                                    data={importados}
                                    pages={this.state.proc_impor_pages}
                                    manual
                                    ref = {ref => this.ReactTableImportados = ref}
                                    loading = {this.state.proc_impor_loading}
                                    onFetchData = { async(state, instance) => {
                                        this.setState({proc_impor_loading: true})

                                        //? a var 'state' referenciada nesse método é interna ao componente da tabela...
                                        //? ...apesar do nome, não tem nada a ver com o 'this.state'

                                        let filtros = {}
                                        state.filtered.map((filtro) => {
                                            if (filtro.id === 'numeracao_unica' && filtro.value.length >= 6)
                                                filtros.numeracao_unica = filtro.value

                                            else if (filtro.id === 'comarca' && filtro.value.length >= 3)
                                                filtros.comarca = filtro.value.toUpperCase()

                                            else if (filtro.id === 'instancia' && filtro.value.length === 1)
                                                filtros.instancia = filtro.value

                                            else if (filtro.id === 'sistema' && filtro.value.length >= 2)
                                                filtros.sistema = filtro.value.toUpperCase()

                                            else if (filtro.id === 'data_importacao' && filtro.value.length === 10)
                                                filtros.data_importacao = filtro.value
                                        })

                                        const dados = await this.getProcessosImportados(state.page+1, filtros)

                                        const importados = dados.data.processos.map(importados => ({
                                            numeracao_unica: importados.numeracao_unica ? this.formataNumeroProcesso(importados.numeracao_unica): "",
                                            data_importacao: importados.data_importacao,
                                            data_movimentacao: importados.data_movimentacao,
                                            movimentacao: importados.movimentacao,
                                            nome_parte: importados.nome_parte,
                                            instancia: importados.instancia,
                                            ativo: importados.ativo,
                                            sistema: importados.sistema,
                                            comarca: importados.comarca
                                        }))
                    
                                        this.setState({
                                            importados,
                                            proc_impor_pages: dados.data.pages,
                                            proc_impor_loading: false
                                        })
                                    }}
                                    columns={[{
                                        Header: "Processos Importados",
                                        columns: [
                                            {
                                                Header: "Excluir",
                                                id: "exclusao",
                                                width: 80,
                                                accessor: "numeracao_unica",
                                                Cell: e =>
                                                    <div style={{ display: 'flex', alignSelf: "center", alignItems: 'center', justifyContent: 'center'}}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={
                                                                    this.state.processos_excluir.indexOf(e.value) !== -1? //? a func. retorna o índice >= 0 se existir e -1 se nao existir
                                                                        true:false
                                                                }
                                                                onChange={(a) => {this.handleCheckDeletar(a, e.value)}} //? a -> click, e.value -> num. do processo
                                                                style = {{alignSelf: "center"}}
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                            },
                                            {
                                                Header: "Numeração Única",
                                                id: "numeracao_unica",
                                                width: 250,
                                                accessor: "numeracao_unica",
                                                /* filterMethod: (filter, row) => {
                                                    return this.columnCustomFilterMethod(filter, row)
                                                },
                                                Filter: ({ filter, onChange }) => {
                                                    return this.columnCustomFilter(filter, onChange)
                                                }, */
                                                Cell: e =><div style={{ textAlign: "center"}}>
                                                            <a href={'/#/editar-processo/'+e.value}> {e.value} </a>
                                                        </div>
                                            },
                                            {
                                                Header: "Comarca",
                                                accessor: "comarca",
                                                width: 90,
                                                Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                            },
                                            /*{
                                                Header: "Data da Movimentação",
                                                accessor: "data_movimentacao",
                                            },
                                            {
                                                Header: "Movimentação",
                                                accessor: "movimentacao",
                                            },
                                            {
                                                Header: "Nome da Parte",
                                                accessor: "nome_parte",
                                            },*/
                                            {
                                                Header: "Instância",
                                                accessor: "instancia",
                                                width: 65,
                                                Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                            },
                                            /*{
                                                Header: "Ativo",
                                                accessor: "ativo",
                                                Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                            },*/
                                            {
                                                Header: "Sistema",
                                                accessor: "sistema",
                                                width: 75,
                                                Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                            },
                                            {
                                                Header: "Data da Importação",
                                                accessor: "data_importacao",
                                                width: 150,
                                                Cell: row => (
                                                    <div style={{ textAlign: "center" }}>{row.value}</div>
                                                )
                                            }
                                        ]
                                    }]}                        
                                    filterable
                                    className="-striped -highlight" 
                                    defaultFilterMethod={this.filterCaseInsensitive}
                                    //defaultFilterMethod={this.processoFilterCaseInsensitive}

                                    defaultPageSize= {15}
                                    

                                    noData  
                                    noDataText= {menssagemRetorno}

                                    // Texto
                                    previousText= 'Anterior'
                                    nextText= 'Próximo'
                                    loadingText= 'Carregando...'
                                    pageText= 'Páginas'
                                    ofText= 'de'
                                    rowsText= 'Linhas'
                                >

                                </ReactTable>
                                <Button
                                    style={{marginTop: 10}}
                                    onClick = {() => {this.deletarProcessos()}}
                                >
                                    Deletar Processos Selecionados
                                </Button>
                            </>
                        }
                    </div>
               
                    <div className="col" style={{width: "464px" }}>
                        <ReactTable
                        data={processosRetornados}
                        pages={this.state.proc_retornados_pages}
                        manual
                        ref = {ref => this.ReactTableRetornados = ref}
                        loading = {this.state.proc_retornados_loading}
                        onFetchData = { async(state, instance) => {
                            this.setState({proc_retornados_loading: true})

                            const dados = await this.customOnFetchData(state, instance, 8)
                            const processosRetornados = dados.data.processos.map(processosRetornados => ({
                                numeracao_unica: processosRetornados.numeracao_unica ? this.formataNumeroProcesso(processosRetornados.numeracao_unica): "",
                                data_retorno: processosRetornados.data_retorno,
                                prioritario: processosRetornados.prioritario ? true : false,
                                principal: processosRetornados.principal ? true : false
                            })); 
        
                            this.setState({
                                processosRetornados,
                                proc_retornados_pages: dados.data.pages,
                                proc_retornados_loading: false
                            })
                        }}
                        columns={[{
                            Header: "Processos Retornados",
                            columns: [
                                {
                                    Header: "Numeração Única",
                                    id: "numeracao_unica",
                                    width: 250,
                                    accessor: "numeracao_unica",
                                    /* filterMethod: (filter, row) => {
                                        return this.columnCustomFilterMethod(filter, row)
                                    },
                                    Filter: ({ filter, onChange }) => {
                                        return this.columnCustomFilter(filter, onChange)
                                    }, */
                                    Cell: e =><div style={{ textAlign: "center" }}>
                                            <a href={'/#/editar-processo/'+e.value}> {e.value} </a>

                                            {e.original.prioritario ?
                                                        <img alt="" data-tip="Prioritário" src={require("../images/icon_warning20.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>

                                                    {e.original.principal ?
                                                        <img alt="" data-tip="Parte Principal" src={require("../images/icon_star20_verde.png")} />
                                                    :
                                                        null
                                                    }
                                                    <ReactTooltip place="top" type="dark" effect="float"/>
                                        </div>
                                },
                                {
                                    Header: "Data do Último Retorno",
                                    accessor: "data_retorno",
                                    width: 200,
                                    Cell: row => (
                                        <div style={{ textAlign: "center" }}>{row.value}</div>
                                    )
                                }
                            ]
                        }]}                        
                        filterable
                        className="-striped -highlight" 
                        defaultFilterMethod={this.filterCaseInsensitive}

                        //Paginação
                        showPagination= {showPagination}                   
                        showPaginationBottom= {showPaginationBottom}
                        showPageSizeOptions= {false}
                        defaultPageSize= {15}

                        noData  
                        noDataText= {menssagemRetorno}

                        // Texto
                        previousText= 'Anterior'
                        nextText= 'Próximo'
                        loadingText= 'Carregando...'
                        pageText= 'Páginas'
                        ofText= 'de'
                        rowsText= 'Linhas'
                    >

                    </ReactTable>
                    </div>
                </div>               
   
            </div>
            </>
        )
    }
    
}