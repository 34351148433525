import React, { Component } from 'react';
import 'materialize-css'; // It installs the JS asset only
import 'materialize-css/dist/css/materialize.min.css';
import { logout } from "../auth";
import Autosuggest from 'react-autosuggest';
import api from '../services/api';
import css_autocomplete_processos from './css/autocomplete_processos.css'

export default class Topo extends Component {
    constructor(props)
    {
        super(props)
        this.state = {
            sugestoes_processos: [],
            value: "",
        }
    }


    funcaoSair()
    {
        logout() //funcao do arquivo auth.js
    }

    onClickSugestao(event, { suggestion })
    {}

    getValorSugestao(suggestion)
    {
        return suggestion.numeracao_unica
    }

    renderSuggestion(suggestion)
    {
        return (
            <a
                style={{color: 'black', backgroundColor: '#f4f7f8', maxWidth: '70ch',
                overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
                href={'/#/editar-processo/'+ suggestion.numeracao_unica}
                title={suggestion.observacao}
            >
                Processo: {suggestion.busca}
            </a>
        )
    }

    onChangeSuggest = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        })
    }

    getSugestoes = async ({ value }) =>
    {
        value = value.replace(/[-.]/gi, "");
        if (value.length >= 7)
        {
            try
            {
                const result = await api.post("/cadastrar-processo/autocomplete", {string: value})
                if (result.data.length)
                    this.setState({sugestoes_processos: result.data})
                else
                    this.setState({
                        sugestoes_processos: [{busca: "NENHUM PROCESSO ENCONTRADO"}]
                    })
            }
            catch (error)
            {
                this.setState({
                    sugestoes_processos: [{busca: "ERRO"}]
                })
            }
        }
    }

    limparSugestoes = () => {
        this.setState({
            sugestoes_processos: []
        });
    }

    onChangeSuggest = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    }

    render() {
        const {value} = this.state
        const inputProps =
        {
            placeholder: "Pesquisar Processos",
            value,
            onChange: this.onChangeSuggest
        }

        return (
            <header>
                <div className ="navbar-fixed">
                    <nav>
                        <div className ="nav-wrapper blue">
                            <a href="/#/home" className ="brand-logo ml-3">Verba de Sucumbência</a>
                            <ul className ="right hide-on-med-and-down">
                                <li>
                                    <div>
                                        <Autosuggest
                                            suggestions={this.state.sugestoes_processos}
                                            onSuggestionsFetchRequested={this.getSugestoes}
                                            onSuggestionsClearRequested={this.limparSugestoes}
                                            getSuggestionValue={this.getValorSugestao}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                            onSuggestionSelected = {this.onClickSugestao}
                                            // theme = {css_autocomplete_processos}
                                        />
                                    </div>
                                </li>
                                <li><a href="/#/home">Início</a></li>
                                <li><a href="/#/cadastrar-processo">Cadastro Manual</a></li>
                                <li><a href="/#/relatorio">Relatório</a></li>
                                {/* <li><a href="/#/processos">Processos Importados</a></li> */}
                                <li><a href="/#/processos-solar">Processos SOLAR</a></li>
                                <li className ="activei"><a href="/" onClick={this.funcaoSair}>Sair</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}