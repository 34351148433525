import React from 'react';
import PropTypes from 'prop-types';
import './css/nestedTable.css';

function AvisosTable({ data,onButtonClick,isLidoProp  }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Nome da parte</th>
          <th>Nº do processo</th>
          <th>Id do Expediente</th>
          <th>Atuação</th>
          <th>Comarca</th>
          <th>Descrição do ato</th>
          <th>Data de expedição</th>
          <th>Prazo</th>
          <th>Data limite para ciência</th>
          <th>Data limite para resposta</th>
          <th>Leitura</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => {
          return (
            <React.Fragment key={index}>
                  <tr key={`${index}`}>
                    <td>{item.info.destinatario_pje}</td>
                    <td><a target="_blank" href={"/#/pje/"+item.info.processo_numeracao_unica}>{item.info.processo_numeracao_unica}</a></td>
                    <td>{item.avisos.id_aviso}</td>
                    <td>{item.info.nome_orgao}</td>
                    <td>{item.info.nome}</td>
                    <td>{item.descricao_ato}</td>
                    <td>{item.info.data_expedicao.substr(8,2)}/{item.info.data_expedicao.substr(5,2)}/{item.info.data_expedicao.substr(0,4)}</td>
                    <td>{item.info.prazo}</td>
                    <td>{item.info.data_limite_ciencia.substr(8,2)}/{item.info.data_limite_ciencia.substr(5,2)}/{item.info.data_limite_ciencia.substr(0,4)}</td>
                    <td>{item.info.data_limite_resposta ? item.info.data_limite_resposta.substr(8,2)+"/"+ item.info.data_limite_resposta.substr(5,2) +"/"+ item.info.data_limite_resposta.substr(0,4) : ""}</td>
                    <td>
                        {isLidoProp ? (
                          <button className='btn-lor-table' onClick={() => onButtonClick(item)}>
                            Marcar como não lida
                          </button>
                        ) : (
                          <button className='btn-lor-table' onClick={() => onButtonClick(item)}>
                            Marcar como Lido
                          </button>
                        )}
                    </td>
                  </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

AvisosTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      numero_processo: PropTypes.string.isRequired,
      ato_praticado: PropTypes.arrayOf(PropTypes.string).isRequired,
      observacao: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AvisosTable;