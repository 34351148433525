import BR from 'date-fns/locale/pt-BR';
import 'moment-timezone';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../components/css/teste.css";
import api from '../services/api';
import Topo_Autenticado from './Topo_autenticado.jsx';
registerLocale('pt-BR', BR)

export default class ProcessosDoSigad extends Component {
    constructor(props) {
        super(props); //
        this.state = {
            data: [],
            startDate: new Date(),
            showPagination: true,
            menssagemRetorno: " ",
            total_ativos: 0,
            total_estimado: 0,
            total_executado: 0,
            valor_efetivo: 0,
            total_movimentado: 0,
            processos_excluir: [],
            show_loading_importados: false,
            data_pesquisa: 0,

            proc_novos_pages: -1, //? Processos Novos
            proc_novos_page: 1,
            proc_novos_loading: true,
            proc_retornados_pages: -1, //? Processos Retornados
            proc_retornados_page: 1,
            proc_retornados_loading: true,
            proc_impro_pages: -1, //? Processos Improcedentes
            proc_impro_page: 1,
            proc_impro_loading: true,
            cob_disp_pages: -1,  //? Processos Cobrança Dispensada
            cob_disp_page: 1,
            cob_disp_loading: true,
            emb_decla_pages: -1,  //? Processos Embargos Declarados
            emb_decla_page: 1,
            emb_decla_loading: true,
            hono_ntransi_pages: -1,  //? Processos Honorários Não Transitados
            hono_ntransi_page: 1,
            hono_ntransi_loading: true,
            hono_transi_pages: -1,  //? Processos Honorários Transitados
            hono_transi_page: 1,
            hono_transi_loading: true,
            proc_exec_pages: -1,  //? Processos Executados
            proc_exec_page: 1,
            proc_exec_loading: true,
            proc_risco_pages: -1,  //? Processos Risco Prescrição
            proc_risco_page: 1,
            proc_risco_loading: true,
            proc_exec_concl_pages: -1,  //? Processos Execução Concluída
            proc_exec_concl_page: 1,
            proc_exec_concl_loading: true,
            proc_impor_pages: -1,  //? Processos Importados
            proc_impor_page: 1,
            proc_impor_loading: true,
            cob_admin_pages: -1,  //? Processos Cobrança Administrativa
            cob_admin_page: 0,
            cob_admin_loading: true,
            proc_pendentes_pages: -1,  //? Processos Pendentes
            proc_pendentes_page: 1,
            proc_pendentes_loading: true,

            proc_sigad_pages: -1,
            proc_sigad_page: 1,
            proc_sigad_loading: true,
            toggle: false
        };
    }

    componentDidMount = async () => {
        this.setState({ show_loading_importados: true })


        this.setState({
            showPagination: true,
            showPaginationBottom: true,
            menssagemRetorno: 'Sem Processos...',
            show_loading_importados: false
        });
    }
    formataNumeroProcesso(valor) {

        /**Criei essa função para formatar a numeração única do processo, o problema é que se
         * a numeração não tiver 20 caracteres a função retorna o numero sem a formatação e
         * se tiver mais de 20 caracteres a função não exibe os numeros a mais, por este motivo fiz uma validação
         * para que se for superior ou inferior a 20 exibe sem formatação.
         * Formato: 9999999-99.9999.9.99.9999
         * Para remover a mascara é só utilizar: formatado.replace(/[^\d]+/g,'')
         */

        //if(valor.lenght > 20 || valor.lenght < 20) ////  lengtH, ;^)
        if (valor.length != 20)
            return valor;
        else
            var formatado = valor.replace(/^(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4}).*/, '$1-$2.$3.$4.$5.$6');
        return formatado;
    }

    filterCaseInsensitive(filter, row) //? essa função permite pesquisar nas tabelas ignorando se as letras são maiúsculas ou minúsculas
    {
        const id = filter.pivotId || filter.id;
        return (
            row[id] !== undefined ?
                String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                :
                true
        );
    }

    getProcessosSigad = async (pagina = 0, filtros = {}) => {
        try {
            let res = await api.post("/processos-sigad/all/" + pagina,
                filtros)
            if (res.data == 0) {
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: []
                    }
                }
            } else {
                console.log(res.data)
                return {
                    data: {
                        ...res.data,
                        page: res.data.current_page,
                        pages: res.data.last_page,
                        processos: res.data.data
                    }
                }
            }
        } catch (error) {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    consultaPossivelHonorario(onChange) {
        this.toggle = !this.toggle;
        onChange(this.toggle ? "1" : '')
    }

    render() {
        const {
            processosSigad,
            startDate, showPagination, showPaginationBottom, menssagemRetorno,

        } = this.state;

        //renderiza a tela
        return (
            <>
                <Topo_Autenticado />
                <div className="titulo-cadastrar-editar-processo" style={{ padding: "10px" }}>
                    <h5>Processos do Solar</h5>
                </div>
                <div className='row'>
                    <div className='col'>
                        <DatePicker
                            selected={startDate}
                            onChange={this.handleChange}
                            dateFormat="DD/MM/YYYY"
                            locale="pt-BR"
                            inline
                        />
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="col" style={{ width: "100%", margin: '10px' }}>
                            <ReactTable
                                data={processosSigad}
                                pages={this.state.proc_sigad_pages}
                                manual
                                ref={ref => this.ReactTableProcessosSigad = ref}
                                loading={this.state.proc_sigad_loading}
                                onFetchData={async (state, instance) => {
                                    this.setState({ proc_sigad__loading: true })

                                    let filtros = {}
                                    state.filtered.map((filtro) => {
                                        if (filtro.id === 'numeracao_unica' && filtro.value.length >= 6)
                                            filtros.numeracao_unica = filtro.value
                                        else if (filtro.id === 'valor_causa' && filtro.value.length >= 1)
                                            filtros.valor_causa = filtro.value
                                        else if (filtro.id === 'cidade' && filtro.value.length >= 3)
                                            filtros.cidade = filtro.value
                                        else if (filtro.id === 'unidade_judiciaria' && filtro.value.length >= 1)
                                            filtros.unidade_judiciaria = filtro.value
                                        else if (filtro.id === 'possivel_honorario' && filtro.value.length >= 1)
                                            console.log(filtro)
                                        filtros.possivel_honorario = this.toggle ? "1" : ''

                                    })

                                    const dados = await this.getProcessosSigad(state.page + 1, filtros)
                                    const processosSigad = dados.data.data.map(processosSigad => ({
                                        numeracao_unica: processosSigad.numeracao_unica ? this.formataNumeroProcesso(processosSigad.numeracao_unica) : "",
                                        prioritario: processosSigad.assistido_id,
                                        cidade: processosSigad.cidade ? processosSigad.cidade : "",
                                        unidade_judiciaria: processosSigad.unidade_judiciaria,
                                        valor_causa: processosSigad.valor_causa,
                                        possivel_honorario: processosSigad.possivel_honorario ? "Sim" : "Não"
                                    }))

                                    this.setState({
                                        processosSigad: processosSigad,
                                        proc_sigad_pages: dados.data.pages,
                                        proc_sigad_loading: false
                                    })
                                }}
                                columns={[{
                                    Header: "Processos SOLAR",
                                    columns: [
                                        {
                                            Header: "Numeração Única",
                                            id: "numeracao_unica",
                                            width: 280,
                                            accessor: "numeracao_unica",
                                            Cell: e => <div style={{ textAlign: "center" }}>
                                                <a href={'/#/editar-processo-solar/' + e.value}> {e.value} </a>
                                            </div>
                                        },
                                        {
                                            Header: "Valor da Causa",
                                            id: "valor_causa",
                                            accessor: "valor_causa",
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>R$ {row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Comarca",
                                            accessor: "cidade",
                                            //width: 80,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },
                                        {
                                            Header: "Unidade Judiciaria",
                                            accessor: "unidade_judiciaria",
                                            //width: 80,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            )
                                        },
                                        {
                                            id: "possivel_honorario",
                                            Header: "Possivel Honorario",
                                            accessor: "possivel_honorario",
                                            //width: 80,
                                            filterable: true,
                                            Cell: row => (
                                                <div style={{ textAlign: "center" }}>{row.value}</div>
                                            ),
                                            Filter: ({ filter, onChange }) => (
                                                <div style={{ textAlign: "center", justifyContent: 'center', justifyItems: 'center' }}>

                                                    <div class="form-check" >

                                                        <input

                                                            class="form-check-input h-4 w-4"
                                                            style={{
                                                                backgroundColor: '#ff0000', /* Vermelho como exemplo */
                                                                Checkbox: '2px 2px 4px rgba(0, 0, 0, 0.4)',
                                                                opacity: 1,
                                                                top: '40%', position: 'absolute',

                                                            }}
                                                            onClick={() => this.consultaPossivelHonorario(onChange)}
                                                            defaultChecked={this.toggle}
                                                            type="checkbox" value="" id="flexCheckDefault" />
                                                        <label class="form-check-label" style={{ color: 'transparent', top: '38%', position: 'absolute', left: '52%' }} for="flexCheckDefault">
                                                            D
                                                        </label>
                                                    </div>
                                                </div>

                                            )

                                        }
                                    ]
                                }]}
                                filterable
                                className="-striped -highlight"
                                defaultFilterMethod={this.filterCaseInsensitive}

                                //Paginação
                                showPagination={showPagination}
                                showPaginationBottom={showPaginationBottom}
                                showPageSizeOptions={false}
                                defaultPageSize={5}


                                noData
                                noDataText={menssagemRetorno}

                                // Texto
                                previousText='Anterior'
                                nextText='Próximo'
                                loadingText='Carregando...'
                                pageText='Páginas'
                                ofText='de'
                                rowsText='Linhas'
                            >
                            </ReactTable>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
