import React, { Component } from 'react';
import 'materialize-css'; // It installs the JS asset only
import 'materialize-css/dist/css/materialize.min.css';
import { logout } from "../auth";

export default class Topo extends Component {
    funcaoSair()
    {
        logout() //funcao do arquivo auth.js
    }
        
    render() {
        return (
            <header>
            <div className ="navbar-fixed">
                <nav>
                    <div className ="nav-wrapper blue">
                        <a href="/#/home" className ="brand-logo">Verba de Sucumbência</a>
                    </div>
                </nav>
            </div>
            </header>
        );
    }
}