import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';

import { isAuthenticated } from "./auth";
import CadastrarProcesso from './components/CadastrarProcesso';
import home from './components/Home';
import Login from './components/Login';
import Pje from './components/Pje';
import Processos from './components/Processos';
import ProcessosDoSigad from './components/ProcessosDoSigad';
import Relatorio from './components/Relatorio';
import Rodape from './components/Rodape';
import Sigad from './components/Sigad';
import './components/css/rodape.css';
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <>
            <Route path="/" exact component={Login} />
            <PrivateRoute path="/cadastrar-processo" component={CadastrarProcesso} />
            <PrivateRoute path="/home" component={home} />
            <PrivateRoute path="/relatorio" component={Relatorio} />
            <PrivateRoute path="/editar-processo/:numeroProcesso" component={CadastrarProcesso} />
            <PrivateRoute path="/editar-processo-solar/:numeroProcesso" component={Sigad} />
            <PrivateRoute path="/processos-solar" component={ProcessosDoSigad} />
            <PrivateRoute path="/processos" component={Processos} />
            <PrivateRoute path="/pje/:numeroProcesso" component={Pje} />

            {/**CRIAR ROTAS COM PRIVATEROUTE */}
            <Rodape />
          </>
        </Switch>
      </HashRouter>
    );
  }
}

export default App;
