import axios from 'axios';
import moment from 'moment'; //TODO: npm uninstall? ver onde se algum outro arquivo usa
import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import Button from 'react-bootstrap/Button';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import ReactIntlCurrencyInput from "react-intl-currency-input";
import Spinner from 'react-loader-spinner';
import NumericInput from 'react-numeric-input';
import Modal from 'react-responsive-modal';
import { Redirect } from 'react-router-dom';
import Select from 'react-select';
import Switch from "react-switch";
import swal from 'sweetalert';
import { getToken } from "../auth";
import api from '../services/api';
import AvisosTable from './AvisosTable';
import NestedTable from './NestedTable';
import Topo_Autenticado from './Topo_autenticado';
import "./css/CadastrarProcesso.css";

/*
 * Valor Estimado virou: "valor estimado com base na petição inicial"
 * Valor Efetivo virou: "receita efetivamente recebida pela instituição"
 * Data de ingresso do valor... virou: "Data de ingresso da receita"
 * comentar o codigo abaixo impacta em algo?
 * adicionar .env.default
 * incrementar readme
 */

export default class CadastrarProcesso extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numeracao_unica: "",
            digitos_numeracao_unica: -1, //Ao clicar na caixa de "Núm. do Pro." esse state já recebe +1, por isso começamos com -1
            prioritario: 0,
            unidades_judiciarias: [],
            unidade_judiciaria_id: 0,
            idProcesso: 0,
            myAtosTablesLidos: [],
            myAtosTablesNaoLidos: [],
            notificacao: false,
            notificacaoAvisos: false,
            comarcas: [],
            comarca_id: 0,
            prorrogacoes: [],
            motivos: [], //situação: 'cobrança dispensada'
            motivos_pendentes: [], //situação: 'Pendente'
            value: "", //aqui ficará salva a string a ser utilizada para busca no autoComplete de buscar partes
            sugestoes_partes: [],
            observacoes: [{ observacao: "" }],
            flag_botao_add_parte: true,

            avisosPendentesLidos: [],
            avisosPendentesNaoLidos: [],
            avisosPendentes: [],

            isOpen: false, //dados do modal de cadastrar/editar parte
            isOpen2: false, //dados do modal de cadastrar/editar parte
            isOpenModelAviso: false,
            //id_parte: -1, //usado no editar parte, para não ter que iterar no array de partes abaixo
            nome: "",
            sw_cpf: true,
            cpf: null,
            digitos_cpf: -1,
            sw_rg: false,
            rg: null,
            digitos_rg: -1,
            sw_cnpj: false,
            cnpj: null,
            sw_sem_documento: false,
            flag_sem_documento: false,
            digitos_cnpj: -1,
            estados: [],
            estado_id: 0,
            municipios: [],
            municipio_id: 0,
            endereco: "",
            telefones: [{ telefone: "" }],
            descricao: "",
            spinnerReset: false, // TODO: renomear

            files: [],
            situacoes: [],

            partes: [
                /*{//toda parte tem:
                    id: -1, //id da parte no banco
                    nome: "DANIEL SANTOS",
                    display_cpf_cnpj: "CPF: 123.456.789-22", //CPF ou CNPJ exibido em baixo do nome da parte
                    sw_parte_principal: true,
                    telefones: [{telefone: ""}], //todas as partes vão ter esses dados (até aqui) em comum

                    situacao_id:0,
                    data_execucao: "",
                    data_ingresso_conta: "",
                    data_transito_julgado: "",
                    data_cobranca: "",
                    data_agendado_retorno: "",
                    campos_extras: '',
                    valor_executado: 0,
                    valor_executado_tratado: 0, //sem o prefixo "R$ ", utilizado para o cálculo da parcela
                    valor_estimado: 0,
                    valor_efetivo: 0,
                    receita_recebida: 0,
                    prorrogacao_id: '',
                    motivo_id: '',

                    qtd_parcelas: 1,
                    cb_correios: false,
                    cb_email: false,
                    cb_telefone: false,
                    cb_outros: false,
                    outro_meio_comunicacao: '',
                }*/
            ],


            //codigo: '',
            flag_editar_processo: false,
            flag_editar_parte: false,
            flag_redirecionar: false
        };

        this.addObservacao = this.addObservacao.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
        this.handleUnidadeJudiciaria = this.handleUnidadeJudiciaria.bind(this)
        this.handleSituacao = this.handleSituacao.bind(this)
        this.handleMotivo = this.handleMotivo.bind(this)
        this.handleMoeda = this.handleMoeda.bind(this)
        this.handlerCheckPrioritario = this.handlerCheckPrioritario.bind(this)
        this.handleDataExecucao = this.handleDataExecucao.bind(this)
        this.handleValorEstimado = this.handleValorEstimado.bind(this)
        this.handleValorExecutado = this.handleValorExecutado.bind(this)
        this.handleValorEfetivo = this.handleValorEfetivo.bind(this)
        this.handleParcelas = this.handleParcelas.bind(this)
        this.handleDeletarObs = this.handleDeletarObs.bind(this)
        this.handleChangeNome = this.handleChangeNome.bind(this);
        this.handleChangeCPF = this.handleChangeCPF.bind(this);
        this.handleChangeRG = this.handleChangeRG.bind(this);
        this.handleChangeCNPJ = this.handleChangeCNPJ.bind(this);
        this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
        this.handleEstado = this.handleEstado.bind(this);
        this.handleChangeEndereco = this.handleChangeEndereco.bind(this);
        this.handleChangeTelefone = this.handleChangeTelefone.bind(this);
        this.handleCadastrarParte = this.handleCadastrarParte.bind(this);
        this.handleChangeDescricao = this.handleChangeDescricao.bind(this);
        this.addCadastrarParte = this.addCadastrarParte.bind(this);
        this.handleDesvincularParte = this.handleDesvincularParte.bind(this);
        this.handleChangeSwitchPartePrincipal = this.handleChangeSwitchPartePrincipal.bind(this);
        this.handleDataIngressoConta = this.handleDataIngressoConta.bind(this);
        this.handleDataTransitoJulgado = this.handleDataTransitoJulgado.bind(this);
        this.handleDataCobranca = this.handleDataCobranca.bind(this);
        this.handleDataAgendadoRetorno = this.handleDataAgendadoRetorno.bind(this);
        this.handleCheckCBCorreios = this.handleCheckCBCorreios.bind(this);
        this.handleCheckCBEmail = this.handleCheckCBEmail.bind(this);
        this.handleCheckCBTelefone = this.handleCheckCBTelefone.bind(this);
        this.handleCheckCBOutro = this.handleCheckCBOutro.bind(this);
        this.handleChangeOutroMeioComunicacao = this.handleChangeOutroMeioComunicacao.bind(this);
        this.getSuggestionValue = this.getSuggestionValue.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.onChangeSuggest = this.onChangeSuggest.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.getMunicipios = this.getMunicipios.bind(this);
        this.handleMunicipio = this.handleMunicipio.bind(this);
        this.inserirParte = this.inserirParte.bind(this);
        this.handleNumeracaoUnica = this.handleNumeracaoUnica.bind(this);
        this.handleChangeObs = this.handleChangeObs.bind(this);
        this.handleChangeSwitchDocumento = this.handleChangeSwitchDocumento.bind(this);
        this.atualizarParte = this.atualizarParte.bind(this);
    }


    //Adiciona observações na tela
    addObservacao = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            observacoes: [...prevState.observacoes, { observacao: "" }]
        })
        );

    }

    //Adiciona telefones na tela
    addTel = (e) => {
        e.preventDefault();
        this.setState((prevState) => ({
            telefones: [...prevState.telefones, { telefone: "" }]
        }));
    }

    handleChangeObs(valor, id) //id -> posição da observação a ser alterada no array
    {
        let observacoes = [...this.state.observacoes]
        observacoes[id]["observacao"] = valor.target.value;
        this.setState({ observacoes })
    }

    handleButtonClick = (item) => {
        // Execute your function or logic using the item data

        this.lerAtos(item)
    };

    handleButtonClick2 = (item) => {
        // Execute your function or logic using the item data

        this.deslerAtos(item)
    };

    //Método que executa o submit
    handleSubmit(e) {
        e.preventDefault();
        let flag_invalido = false
        let arr = this.state

        /*
        delete arr['digitos_numeracao_unica']
        delete arr['unidades_judiciarias']
        delete arr['comarcas']
        delete arr['prorrogacoes']
        delete arr['motivos']
        delete arr['value'] //texto a ser pesquisado no autoComplete
        delete arr['sugestoes_partes']
        delete arr['flag_botao_add_parte']
        delete arr['isOpen']
        delete arr['id'] //o id da parte no back-end, já existe dentro de cada obj 'parte' no array de partes, existe fora do array...
                         //...apenas para não ter que iterar no array procurando a parte a ser realizada a operação
        delete arr['nome']//dados dos modais daqui
        delete arr['sw_cpf']
        delete arr['cpf']
        delete arr['digitos_cpf']
        delete arr['sw_cnpj']
        delete arr['cnpj']
        delete arr['digitos_cnpj']
        delete arr['estados']
        delete arr['estado_id']
        delete arr['municipios']
        delete arr['municipio_id']
        delete arr['endereco']
        delete arr['telefones']
        delete arr['descricao']
        delete arr['spinnerReset']//até aqui

        delete arr['situacoes']
        //delete arr['campos_extras']

        delete arr['files']
        delete arr['isLoaded']
        delete arr['error']
        */

        for (let i = 0; i < arr['partes'].length; i++) //trata as máscaras em cada 'parte'/obj do array
        {
            delete arr['partes'][i]['valor_executado_tratado']

            let valor_executado = String(arr['partes'][i]['valor_executado']);
            if (valor_executado.includes(",")) //testa se existe ',' no número
            {
                let valorExe = arr['partes'][i]['valor_executado'];
                valorExe = valorExe.replace(/\./g, "") //remove os '.' do valor | R$ 9.876.543,21 -> R$ 9876543,21
                valorExe = valorExe.replace(/\,/g, ".") //substitui a ',' dos centavos por '.' p/ enviar pro back-end | R$ 9876543,21 -> R$ 9876543.21
                if (valor_executado.includes("R")) //se existir 'R' na string (R$ 12345.99), remove o R$
                    valorExe = valorExe.substr(3); //remove o "R$ " do começo da string | R$ 9876543.21 -> 9876543.21
                arr['partes'][i]['valor_executado'] = valorExe;
            }

            let valor_estimado = String(arr['partes'][i]['valor_estimado']);
            if (valor_estimado.includes(",")) {
                let valorEst = arr['partes'][i]['valor_estimado'];
                valorEst = valorEst.replace(/\./g, "")
                valorEst = valorEst.replace(/\,/g, ".")
                if (valor_estimado.includes("R"))
                    valorEst = valorEst.substr(3);
                arr['partes'][i]['valor_estimado'] = valorEst;
            }

            let valor_efetivo = String(arr['partes'][i]['valor_efetivo']);
            if (valor_efetivo.includes(",")) {
                let valorEfe = arr['partes'][i]['valor_efetivo'];
                valorEfe = valorEfe.replace(/\./g, "")
                valorEfe = valorEfe.replace(/\,/g, ".")
                if (valor_efetivo.includes("R"))
                    valorEfe = valorEfe.substr(3);
                arr['partes'][i]['valor_efetivo'] = valorEfe;
            }
        }

        this.state.partes.forEach(parte => {
            if (parte.situacao_id === undefined)
                flag_invalido = true
        });

        if (flag_invalido) {
            swal({
                title: "Erro",
                text: "Existe uma parte sem situação definida",
                icon: "error",
                button: "Fechar"
            }).then((e) => { })
        }
        else {
            if (this.state.flag_editar_processo) // true -> editar processo
            {
                let dados = {
                    numeracao_unica: this.state.numeracao_unica,
                    prioritario: this.state.prioritario,
                    unidade_judiciaria_id: this.state.unidade_judiciaria_id,
                    comarca_id: this.state.comarca_id,
                    observacoes: this.state.observacoes,
                    partes: this.state.partes
                }


                //axios.post("http://10.53.23.189:8000" + `/cadastrar-processo/update/` + this.state.numeracao_unica, dados
                axios.post(process.env.REACT_APP_DOMAIN + `/cadastrar-processo/update/` + this.state.numeracao_unica, dados
                    , {
                        headers:
                        {
                            'authorization': getToken()
                        },
                    })
                    .then(res => {
                        if (res.status === 200) {
                            swal({
                                title: "Sucesso!",
                                text: "O processo foi atualizado",
                                icon: "success",
                                timer: 3000,
                                button: false
                            }).then(() =>
                                this.setState({ flag_redirecionar: true }) //redireciona para /home
                            )
                        }
                    }).catch(
                        function (e) {
                            if (e.response.status === 500) {
                                swal({
                                    title: "Erro",
                                    text: "Não foi possível atualizar o processo, favor tentar novamente",
                                    icon: "error",
                                    button: "Fechar"
                                })
                            }
                        }
                    );
            }
            else // cadastrar processo
            {
                let dados = {
                    numeracao_unica: this.state.numeracao_unica,
                    prioritario: this.state.prioritario,
                    unidade_judiciaria_id: this.state.unidade_judiciaria_id,
                    comarca_id: this.state.comarca_id,
                    observacoes: this.state.observacoes,
                    partes: this.state.partes
                }
                //axios.post("http://10.53.23.189:8000" + `/cadastrar-processo/store`, qs.stringify(arr)
                axios.post(process.env.REACT_APP_DOMAIN + `/cadastrar-processo/store`, dados
                    , {
                        headers:
                        {
                            //'content-type': 'application/x-www-form-urlencoded',
                            'authorization': getToken()
                        },
                    })
                    .then(res => {
                        if (res.status === 200) {
                            swal({
                                title: "Sucesso!",
                                text: "O processo foi cadastrado",
                                icon: "success",
                                timer: 3000,
                                button: false
                            }).then(() =>
                                this.setState({ flag_redirecionar: true }) //redireciona para /home
                            )
                        }
                    }).catch(
                        function (e) {
                            if (e.response.status === 500) {
                                swal({
                                    title: "Erro",
                                    text: "Este número de processo já foi cadastrado",
                                    icon: "error",
                                    button: "Fechar"
                                })
                            }
                        }
                    );
            }
        }
    }
    formataNumeroProcesso(valor) {
        if (valor.length != 20)
            return valor;
        else
            var formatado = valor.replace(/^(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4}).*/, '$1-$2.$3.$4.$5.$6');
        return formatado;
    }
    handlerCheckPrioritario = (e) => {
        this.setState({ prioritario: e.target.checked })
    }

    handleNumeracaoUnica(valor) {
        this.setState({ numeracao_unica: valor.target.value })
    }

    //Atualiza o objeto moeda com o valor correto
    handleMoeda = (e, value, maskedValue) => {
        let change = {}
        change[e.target.name] = value
        this.setState(change)
    }

    //seta valor do campo para o objeto de situações e exibe outros campos dependendo da situação
    //atribui o 'id' da opção selecionada em 'situacao_id' no objeto correto dentro do array 'partes' no state
    handleSituacao(situacao, id) {
        /** como a var 'situacao' é:
            situacao = {
                "value": 3,
                "label": "EXECUTADO"
            }
        */
        //console.log(situacao)
        //console.log("id ==" + id);

        let partes = [...this.state.partes];
        partes[id].situacao_id = situacao.value;
        this.setState({ partes })
    }

    //seta valor do campo para o objeto de comarcas
    handleComarca(e) {
        let change = {}
        change['comarca_id'] = e.value
        this.setState(change)
        this.getUJ(e.value);
    }
    //seta valor do campo para o objeto de unidade judiciária
    handleUnidadeJudiciaria(e) {
        let change = {}
        change['unidade_judiciaria_id'] = e.value
        this.setState(change)
    }

    //seta valor do campo para o objeto de motivo
    //atribui o 'id' da opção selecionada em 'motivo_id' no objeto correto dentro do array 'partes' no state
    handleMotivo(motivo, id) {
        //console.log(motivo)
        //console.log("id ==" + id);

        let partes = [...this.state.partes];
        partes[id].motivo_id = motivo.value;
        this.setState({ partes })
    }

    handleMotivoPendencia(motivo, id) {
        let partes = [...this.state.partes];
        partes[id].motivo_pendente_id = motivo.value;
        this.setState({ partes })
    }

    //seta valor do campo para o objeto de prorrogação
    //atribui o 'id' da opção selecionada em 'prorrogacao_id' no objeto correto dentro do array 'partes' no state
    handleProrrogacao(prorrogacao, id) {
        //console.log("prorrogacao == " + prorrogacao.value)
        let partes = [...this.state.partes];
        partes[id].prorrogacao_id = prorrogacao.value;
        this.setState({ partes })
    }

    //atualiza o objeto referente aos campos observação, priotitário, numeração única e campos de data
    handleChange = (e) => {
        if (["materialize-textarea"].includes(e.target.className)) {
            let observacoes = [...this.state.observacoes]
            observacoes[e.target.dataset.id]["observacao"] = e.target.value
            this.setState({ observacoes }, () => console.log(this.state.observacoes))
        }
        else if (e.target.name === "outro_meio_comunicacao") {
            this.setState({ [e.target.name]: e.target.value })
        }
        else if (e.target.name === "qtd_parcelas") {
            this.setState({ [e.target.name]: e.target.value })
        }
        else if (e.target.name === "prioritario" || e.target.name.startsWith("cb")) { //as outras checkboxes
            this.setState({ [e.target.name]: e.target.checked })
        } else if (e.target.name === "numeracao_unica") {
            this.setState({ [e.target.name]: e.target.value })
        } else if (e.target.type === 'date') {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    handleValorEstimado(valor, id) {
        //console.log("valor estimado!")
        //console.log("valor == " + valor);
        let partes = [...this.state.partes];
        partes[id].valor_estimado = valor;
        this.setState({ partes })
    }

    handleValorExecutado(valor, id) {
        let partes = [...this.state.partes];

        partes[id].valor_executado = valor;
        //abaixo vai tratar o valor para utilizar no cálculo da parcela
        let valorExeTra = valor.replace(/\./g, "")
        valorExeTra = valorExeTra.replace(/\,/g, ".")
        valorExeTra = valorExeTra.substr(2);
        partes[id].valor_executado_tratado = valorExeTra;

        this.setState({ partes })
    }

    handleValorEfetivo(valor, id) {
        //console.log("valor efetivo!")
        //console.log("valor == " + valor);
        //console.log("id == " + id);
        let partes = [...this.state.partes];
        partes[id].valor_efetivo = valor;
        this.setState({ partes })
    }

    handleParcelas(valor, id) {
        //console.log("qtd_parcelas!")
        //console.log("qtd_parcelas == " + valor);
        let partes = [...this.state.partes];
        partes[id].qtd_parcelas = valor;
        this.setState({ partes })
    }

    handleDataExecucao(valor, id) {
        //console.log("data formatada == " + moment(valor).format().substr(0, 10));
        let partes = [...this.state.partes];
        partes[id].data_execucao = valor.target.value
        this.setState({ partes });
    }

    handleDataIngressoConta(valor, id) {
        //console.log("data == " + valor.target.value)
        //console.log("data formatada == " + moment(valor.target.value).format().substr(0, 10));
        let partes = [...this.state.partes];
        partes[id].data_ingresso_conta = valor.target.value;
        this.setState({ partes })
    }

    handleDataTransitoJulgado(valor, id) {
        let partes = [...this.state.partes];
        partes[id].data_transito_julgado = valor.target.value;
        this.setState({ partes })
    }

    handleDataCobranca(valor, id) {
        let partes = [...this.state.partes];
        partes[id].data_cobranca = valor.target.value;
        this.setState({ partes });
    }

    handleDataAgendadoRetorno(valor, id) {
        let partes = [...this.state.partes];
        partes[id].data_agendado_retorno = valor.target.value;
        this.setState({ partes });
    }

    handleCheckCBCorreios(valor, id) {
        let partes = [...this.state.partes];
        partes[id].cb_correios = !partes[id].cb_correios;
        this.setState({ partes });
    }

    handleCheckCBEmail(valor, id) {
        let partes = [...this.state.partes];
        partes[id].cb_email = !partes[id].cb_email;
        this.setState({ partes });
    }

    handleCheckCBTelefone(valor, id) {
        let partes = [...this.state.partes];
        partes[id].cb_telefone = !partes[id].cb_telefone;
        this.setState({ partes });
    }

    handleCheckCBOutro(valor, id) {
        let partes = [...this.state.partes];
        partes[id].cb_outros = !partes[id].cb_outros;
        this.setState({ partes });
    }

    handleChangeOutroMeioComunicacao(valor, id) {
        let partes = [...this.state.partes];
        partes[id].outro_meio_comunicacao = valor.target.value;
        this.setState({ partes })
    }

    //Deletar observação
    handleDeletarObs(id) //id -> posicao
    {
        let observacoes = [...this.state.observacoes];

        if (this.state.flag_editar_processo) //em editar processo excluir obs precisa avisar o back-end caso a obs exista no banco...
        {//...senão (criada no front mas não salva no back) so precisa apagar no front
            swal({
                title: "Você tem certeza?",
                text: "Esta ação não poderá ser desfeita!",
                icon: "warning",
                buttons: {
                    cancel: {
                        text: "NÃO",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    confirm: {
                        text: "SIM",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            })
                .then((result) => {
                    if (result) // se clicar em 'SIM'
                    {
                        let dados = //dados necessários para excluir uma observação em editar processo (onde já existe o vínculo no back-end)
                        {
                            observacao_id: observacoes[id].id,
                            observacao: observacoes[id].observacao,
                            numeracao_unica: this.state.numeracao_unica
                        }

                        //é necessário verificar se a obs existe só no front-end ou veio do back-end. Como isso é possível:
                        //se a propriedade 'id' no objeto da observação for nulo, a observação não existe no back-end
                        if (dados.observacao_id != null) //se existir 'id' na obs, precisa apagar no back-end
                        {
                            axios.post(process.env.REACT_APP_DOMAIN + `/observacao-processo/delete`, dados
                                , {
                                    headers:
                                    {
                                        'authorization': getToken()
                                    },
                                })
                                .then(res => {
                                    if (res.status === 200) {
                                        swal({
                                            title: "Sucesso!",
                                            text: "A observação foi excluída",
                                            icon: "success",
                                            timer: 3000,
                                            button: false
                                        });
                                        //com a confirmação do back-end, a observação pode ser removida do front-end

                                        observacoes.splice(id, 1);
                                        this.setState({ observacoes });

                                    }
                                }).catch(
                                    function (e) {
                                        if (e.response.status === 500) {
                                            swal({
                                                title: "Erro",
                                                text: "Esta observação não pôde ser excluída, favor tentar novamente mais tarde",
                                                icon: "error",
                                                button: "Fechar"
                                            })
                                        }
                                    }
                                );
                        }
                        else //se o 'id' for nulo, só precisa apagar no front-end
                        {
                            observacoes.splice(id, 1);
                            this.setState({ observacoes });
                        }
                    }
                    else //se clicar em cancelar, não faz nada
                    { }
                });
        }
        else //em cadastrar processo, ainda não existe vínculo da observação com o processo, então a operação só precisa ser realizada no front-end
        {
            //let observacoes = [...this.state.observacoes];
            observacoes.splice(id, 1);
            this.setState({ observacoes });
        }
    }

    //Deletar Telefone
    handleDeletarTel(id) {
        let telefones = [...this.state.telefones];
        telefones.splice(id, 1);
        this.setState({ telefones });
    }

    //carrega o combo de situações
    getSituacoes() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getSituacoes"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    //var arr = [];

                    let situacoes = result.map(situacao => ({
                        value: situacao.id,
                        label: situacao.nome,
                    }));
                    if (situacoes.findIndex(el => el.label == 'RETORNO'))
                        situacoes.splice((situacoes.findIndex(el => el.label == 'RETORNO')), 1)
                    this.setState({
                        situacoes: situacoes
                    });

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }
    //carrega o combo de unidade judiciária de acordo com a comarca selecionada
    getUJ(comarca_id, unidade_judiciaria_id = -1) { //? se nao enviar o 2º parâmetro, 'unidade_judiciaria_id' fica com o valor -1,
        //? assim da pra saber se é somente p/ carregar as UJ's ->OU<- carregá-las e já setar uma UJ

        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getUJ/" + comarca_id
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let unidades = result.map(unidade => ({
                        value: unidade.id,
                        label: unidade.nome,
                    }));
                    if (unidade_judiciaria_id === -1) //se for -1, o usuário vai escolher uma UJ
                    {
                        this.setState({
                            unidades_judiciarias: unidades
                        });
                    }
                    else //senao ja carrega o combo de acordo com a comarca e seta um valor
                    {
                        this.setState({
                            unidades_judiciarias: unidades,
                            unidade_judiciaria_id: unidade_judiciaria_id
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    //Faz a mágica
    componentDidMount() {

        this.getSituacoes();
        this.getComarcas();
        //this.getUJ(); //? essa função agora é chamada somente ao selecionar uma comarca ou em editar-processo
        this.getMotivo();
        this.getMotivosPendentes()
        this.getProrrogacoes();
        this.getEstados();


        const { match: { params } } = this.props;

        if (params.numeroProcesso) {
            //fetch("http://10.53.23.189:8000" + "/cadastrar-processo/edit/"+params.numeroProcesso
            fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/edit/" + params.numeroProcesso
                , {
                    headers:
                    {
                        //'content-type': 'application/x-www-form-urlencoded',
                        'authorization': getToken()
                    },
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            numeracao_unica: result.numeracao_unica,
                            prioritario: result.prioritario,
                            comarca_id: result.comarca_id,
                            unidade_judiciaria_id: result.unidade_judiciaria_id,
                            flag_editar_processo: true,
                            idProcesso: result.id,
                            notificacao: result.notificacao
                        });
                        this.getAvisosPendentes(result.numeracao_unica)
                        this.getUJ(result.comarca_id, result.unidade_judiciaria_id)

                        let partes = [...this.state.partes]; //copia do state atual, podia ser 'let partes = []'
                        if (result.processo_parte != null) {
                            result.processo_parte.forEach(parte => {
                                if (parte.id != null) {

                                    let aux_parte = //var auxiliar para inserir no array de partes do front-end
                                    {
                                        //dados referentes à parte:
                                        numeroProcesso: params.numeroProcesso, //usado em desvincular parte: handleDesvincularParte(id)
                                        id: parte.parte_infos.id, //id da parte no banco
                                        relacionamento_id: parte.relacionamento_id, //id da parte no banco
                                        nome: parte.parte_infos.nome,
                                        sw_parte_principal: parte.principal,
                                        telefones: parte.telefones,
                                        tipo_cpf: parte.parte_infos.tipo_cpf,
                                        cpf: parte.parte_infos.cpf,
                                        tipo_rg: parte.parte_infos.tipo_rg,
                                        rg: parte.parte_infos.rg,
                                        tipo_cnpj: parte.parte_infos.tipo_cnpj,
                                        cnpj: parte.parte_infos.cnpj,
                                        endereco: parte.parte_infos.endereco,
                                        descricao: parte.parte_infos.descricao,
                                        estado_id: parte.parte_infos.estado_id,
                                        cidade_id: parte.parte_infos.cidade_id,



                                        //dados referentes à situação:
                                        situacao_id: parte.situacao_id,
                                        data_execucao: parte.data_execucao,
                                        data_ingresso_conta: parte.data_ingresso_conta,
                                        data_transito_julgado: parte.data_transito_julgado,
                                        data_cobranca: parte.data_cobranca,
                                        data_agendado_retorno: parte.data_agendado_retorno,
                                        valor_executado: parte.valor_executado,
                                        valor_executado_tratado: parte.valor_executado,
                                        valor_estimado: parte.valor_estimado,
                                        valor_efetivo: parte.valor_efetivo,
                                        receita_recebida: parte.receita_recebida,
                                        prorrogacao_id: parte.prorrogacao_id,
                                        motivo_id: parte.motivo_id,
                                        motivo_pendente_id: parte.motivo_pendente_id,
                                        qtd_parcelas: parte.qtd_parcelas,
                                        cb_correios: parte.cb_correios,
                                        cb_email: parte.cb_email,
                                        cb_telefone: parte.cb_telefone,
                                        cb_outros: parte.cb_outros,
                                        outro_meio_comunicacao: parte.outro_meio_comunicacao,
                                    }

                                    if (aux_parte.tipo_cpf === true) //atribui cpf ou rg ou cnpj ao campo display_cpf_cnpj
                                    {
                                        aux_parte['display_cpf_cnpj'] = "CPF: " + aux_parte.cpf
                                    }
                                    else if (aux_parte.tipo_rg === true) //atribui cpf ou rg ou cnpj ao campo display_cpf_cnpj
                                    {
                                        aux_parte['display_cpf_cnpj'] = "RG: " + aux_parte.rg
                                    }
                                    else {
                                        aux_parte['display_cpf_cnpj'] = "CNPJ: " + aux_parte.cnpj
                                    }

                                    if (aux_parte.valor_estimado != null) {
                                        aux_parte.valor_estimado = aux_parte.valor_estimado.replace(/\./g, ",");//troca o '.' por ','
                                    }
                                    if (aux_parte.valor_executado != null) {
                                        aux_parte.valor_executado = aux_parte.valor_executado.replace(/\./g, ",");//troca o '.' por ','
                                    }
                                    if (aux_parte.valor_efetivo != null) {
                                        aux_parte.valor_efetivo = aux_parte.valor_efetivo.replace(/\./g, ",");//troca o '.' por ','
                                    }

                                    partes.push(aux_parte) //adiciona no fim
                                }
                            });
                            this.setState({ partes }) //salva
                        }

                        /* console.log("result.observacoes:")
                        console.log(result.observacoes)
                        console.log("hora:")
                        console.log(result.observacoes[0].created_at)
                        console.log(moment(result.observacoes[0].created_at).format("HH:mm")) */

                        if (result.observacoes != null) {
                            //carrega as observações:
                            let observacoes = [] //o array de observacoes contém N objetos observação {id, observacao}
                            result.observacoes.forEach(element => {
                                if (element.observacao != "") {
                                    let observacao = {
                                        id: element.id,
                                        observacao: element.observacao,
                                        data: moment(element.created_at).format("DD/MM/yyyy"),
                                        hora: moment(element.created_at).format("HH:mm")
                                    }
                                    observacoes.push(observacao)
                                }
                            })
                            this.setState({ observacoes: observacoes })
                        }
                        //console.log("estado:")
                        //console.log(this.state)

                    },
                    (error) => {

                    }
                );

        }
    }

    //carrega o combo de motivos da situação 'Cobrança Dispensada'
    getMotivo() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getMotivos"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let motivos = result.map(motivo => ({
                        value: motivo.id,
                        label: motivo.nome,
                    }));
                    this.setState({
                        motivos: motivos
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    //carrega o combo de motivos da situação 'Pendente'
    getMotivosPendentes() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getMotivosPendentes"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let motivos = result.map(motivo => ({
                        value: motivo.id,
                        label: motivo.nome,
                    }));
                    this.setState({
                        motivos_pendentes: motivos
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    //carrega a lista de comarcas
    getComarcas() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getComarcas"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken(),
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let comarcas = result.map(comarca => ({
                        value: comarca.id,
                        label: comarca.nome,
                    }));
                    this.setState({
                        comarcas: comarcas
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    //exibe o campo prorrogacao
    eProrrogacao() {
        const currencyConfig = {
            locale: "pt-BR",
            formats: {
                number: {
                    BRL: {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    },
                },
            },
        };

        return (
            <div>
                <div className="col s4">
                    <Select
                        onChange={this.handleProrrogacao.bind(this)}
                        options={this.state.prorrogacoes}
                        placeholder="Tempo de Prorrogação"
                        inputId="prorrogacao_id"
                        name="prorrogacao_id"
                        value={this.state.prorrogacao_id}
                    />
                    <br />
                    <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                    <ReactIntlCurrencyInput currency="BRL" config={currencyConfig}
                        id="valor_estimado"
                        name="valor_estimado"
                        value={this.state.valor_estimado}
                        onChange={this.handleMoeda.bind(this)}
                    />
                </div>
            </div>
        );
    }

    //carrega a lista de tempos de prorrogação
    getProrrogacoes() {
        fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/getProrrogacoes"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    let prorrogacoes = result.map(prorrogacao => ({
                        value: prorrogacao.id,
                        label: prorrogacao.qtd_dias,
                    }));
                    this.setState({
                        prorrogacoes: prorrogacoes
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen
        },
            () => {
                if (!this.state.isOpen) //se fechar o modal, limpa
                {
                    this.setState({  //volta o modal para o estado padrão
                        nome: "",
                        id: -1, //já que agora ele existe, precisa ser resetado também, diferente da limpeza em cadastrar parte abaixo
                        sw_cpf: true,
                        cpf: "",
                        sw_cnpj: false,
                        cnpj: "",
                        //estados: [], os estados não precisam ser apagados pq não precisam ser carregados novamente
                        estado_id: 0,
                        municipios: [],
                        municipio_id: 0,
                        endereco: "",
                        telefones: [{ telefone: "" }],
                        descricao: "",
                        flag_editar_parte: false,
                        sw_sem_documento: false,
                        flag_sem_documento: false,
                        isOpen: false
                    })
                }
            });
    }

    toggleModal2 = () => {
        this.getAtos()
        this.setState({
            isOpen2: !this.state.isOpen2
        },
            () => {
                if (!this.state.isOpen2) //se fechar o modal, limpa
                {
                    this.setState({  //volta o modal para o estado padrão
                        isOpen2: false
                    })
                }
            });
    }

    toggleModalAvisoPendente = () => {
        this.getAtos()
        this.setState({
            isOpenModelAviso: !this.state.isOpenModelAviso
        },
            () => {
                if (!this.state.isOpenModelAviso) //se fechar o modal, limpa
                {
                    this.setState({  //volta o modal para o estado padrão
                        isOpenModelAviso: false
                    })
                }
            });
    }





    toggleModalEditar = async (id) => //esse id é a posição da parte no array de partes
    {
        //prepara os dados p/ dps abrir o modal:

        const parte = this.state.partes[id] //copia os dados da parte a ser editada
        this.getEstados() //já carrega os estados

        if (parte.sem_documento) {
            await this.setState({ //esse comando tem 'await' pq é necessário aguardar sua execução p/ depois abrir o modal
                nome: parte.nome,
                id: parte.id, //id da parte no back-end, NÃO é declarado no começo do código pq só editar parte o usa (evitando iterar no array partes)

                sw_sem_documento: true,
                sw_cpf: true,
                cpf: null,
                sw_cnpj: false,
                cnpj: null,

                estado_id: parte.estado_id,
                municipio_id: parte.cidade_id,
                endereco: parte.endereco,
                descricao: parte.descricao,
                telefones: parte.telefones,
                flag_editar_parte: true
            })
        }
        else {
            if (parte.tipo_cpf) //se for PF, atribui 'true' ao switch de CPF
            {
                await this.setState({ //esse comando tem 'await' pq é necessário aguardar sua execução p/ depois abrir o modal
                    nome: parte.nome,
                    id: parte.id, //id da parte no back-end, NÃO é declarado no começo do código pq só editar parte o usa (evitando iterar no array partes)

                    sw_sem_documento: false,
                    sw_cpf: true,
                    cpf: parte.cpf,
                    sw_rg: false,
                    rg: null,
                    sw_cnpj: false,
                    cnpj: null,

                    estado_id: parte.estado_id,
                    municipio_id: parte.cidade_id,
                    endereco: parte.endereco,
                    descricao: parte.descricao,
                    telefones: parte.telefones,
                    flag_editar_parte: true
                })
            }
            else if (parte.tipo_rg) //se for RG, atribui 'true' ao switch de RG
            {
                await this.setState({ //esse comando tem 'await' pq é necessário aguardar sua execução p/ depois abrir o modal
                    nome: parte.nome,
                    id: parte.id, //id da parte no back-end, NÃO é declarado no começo do código pq só editar parte o usa (evitando iterar no array partes)

                    sw_sem_documento: false,
                    sw_cpf: false,
                    cpf: null,
                    sw_rg: true,
                    rg: parte.rg,
                    sw_cnpj: false,
                    cnpj: null,

                    estado_id: parte.estado_id,
                    municipio_id: parte.cidade_id,
                    endereco: parte.endereco,
                    descricao: parte.descricao,
                    telefones: parte.telefones,
                    flag_editar_parte: true
                })
            }
            else //se for PJ
            {
                await this.setState({ //carrega os dados no modal
                    nome: parte.nome,
                    id: parte.id, //usado no editar parte

                    sw_sem_documento: false,
                    sw_cpf: false,
                    cpf: null,
                    sw_rg: false,
                    rg: null,
                    sw_cnpj: true,
                    cnpj: parte.cnpj,

                    estado_id: parte.estado_id,
                    municipio_id: parte.cidade_id,
                    endereco: parte.endereco,
                    descricao: parte.descricao,
                    telefones: parte.telefones,
                    flag_editar_parte: true
                })
            }
        }

        this.getMunicipios(parte.estado_id) //carrega os municípios
        await this.setState({ municipio_id: parte.cidade_id })
        this.toggleModal()
    }

    handleChangeNome(e) {
        this.setState({ nome: e.target.value })
    }

    handleChangeCPF(e) {
        this.setState({ cpf: e.target.value });
        this.setState({ digitos_cpf: this.state.digitos_cpf + 1 })
    }

    handleChangeRG(e) {
        this.setState({ rg: e.target.value });
        this.setState({ digitos_rg: this.state.digitos_rg + 1 })
    }

    handleChangeCNPJ(e) {
        this.setState({ cnpj: e.target.value });
        this.setState({ digitos_cnpj: this.state.digitos_cnpj + 1 })
    }

    handleChangeSwitch(e) {
        if (e === "cpf") {
            this.setState({
                sw_cpf: true,
                sw_rg: false,
                sw_cnpj: false
            });
        }
        else if (e === "rg") {
            this.setState({
                sw_cpf: false,
                sw_rg: true,
                sw_cnpj: false
            });
        }
        else if (e === "cnpj") {
            this.setState({
                sw_cpf: false,
                sw_rg: false,
                sw_cnpj: true
            });
        }
    }

    handleChangeSwitchDocumento(e) {
        if (e === true) //desativar os documentos
        {
            swal({
                title: "Atenção",
                text: "Uma parte sem dados não contabilizará nos relatórios, deseja prosseguir?",
                icon: "warning",
                buttons: {
                    cancel: {
                        text: "NÃO",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    confirm: {
                        text: "SIM",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            })
                .then((result) => {
                    if (result) // se clicar em 'SIM'
                    {
                        this.setState({ sw_sem_documento: true, flag_sem_documento: true })
                    }
                    else { }
                });
        }
        else {
            this.setState({ sw_sem_documento: false })
        }
    }

    getEstados() {
        //fetch("http://10.53.23.189:8000/parte/getEstados"
        fetch(process.env.REACT_APP_DOMAIN + "/parte/getEstados"
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    const estados = result.map(estado => ({
                        value: estado.id,
                        label: estado.nome,
                    }));
                    this.setState({
                        estados: estados
                    });

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    lerAtos = async (item) => {
        /*
        let dados = {
            processo_id: this.state.idProcesso,
        }
        */

        try {
            let res = await api.post("/processo-sigad/lerAtos", item.ato_processo_id)

            if (res.data == 0) {
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: [],
                        to: 5
                    }
                }
            } else {

                this.getAtos()


            }
        } catch (error) {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }
    deslerAtos = async (item) => {
        /*
        let dados = {
            processo_id: this.state.idProcesso,
        }
        */
        const updatedItem = {
            ...item,
            processo_honorarios: this.state.idProcesso
        };

        try {
            let res = await api.post("/processo-sigad/deslerAtos", updatedItem)

            if (res.data == 0) {
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: [],
                        to: 5
                    }
                }
            } else {

                this.getAtos()


            }
        } catch (error) {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    lerAvisos = async (item) => {
        fetch(process.env.REACT_APP_DOMAIN + "/avisos-pendentes/read/" + item.avisos.id_aviso, {
            method: 'POST',
            headers: { 'authorization': getToken() },
        }).then(res => res.json())
            .then((result) => {
                this.getAvisosPendentes(item.numeracao_unica)
            }).catch((err) => {
            })
    }

    deslerAvisos = async (item) => {
        fetch(process.env.REACT_APP_DOMAIN + "/avisos-pendentes/unread/" + item.avisos.id_aviso, {
            method: 'POST',
            headers: { 'authorization': getToken() },
        }).then(res => res.json())
            .then((result) => {
                this.getAvisosPendentes(item.numeracao_unica)
            }).catch((err) => {
            })
    }

    getNotificacao = async () => {
        const { match: { params } } = this.props;
        if (params.numeroProcesso) {
            //fetch("http://10.53.23.189:8000" + "/cadastrar-processo/edit/"+params.numeroProcesso
            fetch(process.env.REACT_APP_DOMAIN + "/cadastrar-processo/edit/" + params.numeroProcesso
                , {
                    headers:
                    {
                        //'content-type': 'application/x-www-form-urlencoded',
                        'authorization': getToken()
                    },
                })
                .then(res => res.json())
                .then(
                    (result) => {

                        this.setState({
                            notificacao: result.notificacao
                        });

                    },
                    (error) => {

                    }
                );

        }
    }
    getAtos = async () => {

        let dados = {
            processo_id: this.state.idProcesso,
        }

        try {
            let res = await api.post("/processo-sigad/getAtos", dados)

            if (res.data == 0) {
                return {
                    data: {
                        page: 0,
                        pages: -1,
                        processos: [],
                        to: 5
                    }
                }
            } else {

                const myAtosTablesLidos1 = res.data.filter(item => item.lido === "1");
                const myAtosTablesNaoLidos1 = res.data.filter(item => item.lido === "0");


                const myAtosTablesLidos = myAtosTablesLidos1.map(linha => ({
                    ato_processo_id: linha.id,
                    numero_processo: linha.numeracao_unica,
                    ato_praticado: linha.ato_praticado,
                    observacao: linha.observacao,
                    data_ato: linha.data_ato,
                    processo_id: linha.processo_id
                }));
                const myAtosTablesNaoLidos = myAtosTablesNaoLidos1.map(linha => ({
                    ato_processo_id: linha.id,
                    numero_processo: linha.numeracao_unica,
                    ato_praticado: linha.ato_praticado,
                    observacao: linha.observacao,
                    data_ato: linha.data_ato,
                    processo_id: linha.processo_id
                }));

                this.setState({
                    myAtosTablesLidos: myAtosTablesLidos,
                    myAtosTablesNaoLidos: myAtosTablesNaoLidos
                });

                this.getNotificacao()

            }
        } catch (error) {
            return {
                data: {
                    page: 0,
                    pages: -1,
                    processos: []
                }
            }
        }
    }

    getAvisosPendentes = (numeroProcesso) => {
        fetch(process.env.REACT_APP_DOMAIN + "/avisos-pendentes/" + numeroProcesso
            , {
                headers:
                {
                    //'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            }).then(res => res.json())
            .then((result) => {
                this.setState({ avisosPendentesLidos: result.filter(item => item.avisos.aviso_lido === 1), avisosPendentesNaoLidos: result.filter(item => item.avisos.aviso_lido === 0) })
                result.filter(item => {
                    if (item.avisos.aviso_lido === 0) {
                        this.setState({ notificacaoAvisos: true })
                    } else {
                        this.setState({ notificacaoAvisos: false })
                    }
                })
                //this.setState({avisosPendentes: result});
            }).catch((err) => {
            })
    }


    getAtos2() {
        //fetch("http://10.53.23.189:8000/parte/getEstados"
        fetch(process.env.REACT_APP_DOMAIN + "/importacao-sigad/getAtos/"
            , {
                method: 'POST',
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
                body: JSON.stringify({
                    processo_id: this.state.idProcesso,
                })
            })
            .then(res => res.json())
            .then(
                (result) => {
                    const estados = result.map(estado => ({
                        value: estado.id,
                        label: estado.nome,
                    }));
                    this.setState({
                        estados: estados
                    });

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    getMunicipios(id) {
        //console.log("id == " + id)
        //fetch("http://10.53.23.189:8000/parte/getCidades/" + id
        fetch(process.env.REACT_APP_DOMAIN + "/parte/getCidades/" + id
            , {
                headers:
                {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then(res => res.json())
            .then(
                (result) => {
                    const municipios = result.map(municipio => ({
                        value: municipio.id,
                        label: municipio.nome,
                    }));
                    this.setState({
                        municipios: municipios
                    });

                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    }

    handleEstado(e) {
        let change = {}
        change['estado_id'] = e.value
        this.setState(change)

        this.getMunicipios(e.value) //faz a requisição pros municípios do estado escolhido
    }

    handleMunicipio(e) {
        let change = {}
        change['municipio_id'] = e.value
        this.setState(change)
    }

    handleChangeEndereco(e) {
        this.setState({ endereco: e.target.value });
        //console.log("this.state.telefone == " + this.state.telefone);
    }

    handleChangeDescricao(e) {
        this.setState({ descricao: e.target.value });
        //console.log("this.state.telefone == " + this.state.telefone);
    }

    handleChangeTelefone(valor, idx) {
        //console.log("valor == " + valor);
        //console.log("idx == " + idx);
        let telefones = [...this.state.telefones];
        telefones[idx]["telefone"] = valor;
        this.setState({ telefones });
    }

    inserirParte(dados) //essa função insere a parte (que acabou de ser cadastrada no back-end) no array de partes do processo
    {
        let parte =
        {
            id: dados.id,
            nome: dados.nome,
            sw_parte_principal: false,
            estado_id: dados.estado_id,
            municipio_id: dados.cidade_id, //! propriedade mudando de nome
            endereco: dados.endereco,
            telefones: dados.telefones,
            valor_executado: 0,
            valor_estimado: 0,
            valor_efetivo: 0,
            data_execucao: null,
            data_ingresso_conta: null,
            data_transito_julgado: null,
            qtd_parcelas: 1,
            data_cobranca: null,
            descricao: dados.descricao
        }
        parte.nome = parte.nome.toUpperCase();

        if (dados.sem_documento) //se não tiver documento
        {
            parte['display_cpf_cnpj'] = "->Documento Indefinido"
            parte['sem_documento'] = true
            parte['tipo_cpf'] = true
            parte['cpf'] = null
            parte['tipo_rg'] = false
            parte['rg'] = null
            parte['tipo_cnpj'] = false
            parte['cnpj'] = null
        }
        else //se tiver documento
        {
            if (dados.tipo_cpf === true) //atribui cpf ou cnpj ao campo display_cpf_cnpj
            {
                parte['display_cpf_cnpj'] = "CPF: " + dados.cpf
                parte['tipo_cpf'] = true
                parte['cpf'] = dados.cpf
                parte['tipo_cnpj'] = false
                parte['cnpj'] = null
                parte['sem_documento'] = false
            }
            else if (dados.tipo_rg) {
                parte['display_cpf_cnpj'] = "RG: " + dados.rg
                parte['tipo_cpf'] = false
                parte['cpf'] = null
                parte['tipo_rg'] = true
                parte['rg'] = dados.rg
                parte['tipo_cnpj'] = false
                parte['cnpj'] = null
                parte['sem_documento'] = false
            }
            else {
                parte['display_cpf_cnpj'] = "CNPJ: " + dados.cnpj
                parte['tipo_cpf'] = false
                parte['cpf'] = null
                parte['tipo_cnpj'] = true
                parte['cnpj'] = dados.cnpj
                parte['sem_documento'] = false
            }
        }


        let partes = [...this.state.partes]; //copia do state atual

        if (partes.length === 0) //se não houver nenhuma parte no processo, a que acabou de ser selecionada é a principal
            parte['sw_parte_principal'] = true;

        partes.push(parte) //adiciona no fim

        this.setState({ partes }) //salva
        this.setState({ //resetando os states
            flag_botao_add_parte: true,
            value: "",
        })
    }

    atualizarParte(dados)// !essa função atualiza os dados de uma parte (no front-end) após ela ter sido editada
    {
        let parte = this.state.partes.find(obj => obj.id === dados.id) //? pesquisa no array de partes a parte e salva uma cópia da parte a ser atualizada
        dados.sw_parte_principal = parte.sw_parte_principal //a var 'dados' não tem o flag de parte_principal
        let indice = this.state.partes.findIndex(obj => obj.id === dados.id) //pega o índice dessa parte
        parte = dados; //* atualiza a cópia da parte com os dados novos
        //? monta o campo 'display_cpf_cnpj'
        if (dados.sem_documento) //se não tiver documento
            parte['display_cpf_cnpj'] = "->Documento Indefinido"
        else {
            if (dados.tipo_cpf === true) //atribui cpf ou cnpj ao campo display_cpf_cnpj
                parte['display_cpf_cnpj'] = "CPF: " + dados.cpf
            else if (dados.tipo_rg === true)
                parte['display_cpf_cnpj'] = "RG: " + dados.rg
            else
                parte['display_cpf_cnpj'] = "CNPJ: " + dados.cnpj
        }
        let partes = [...this.state.partes]
        partes[indice] = parte
        this.setState({ partes })
    }

    handleCadastrarParte(e) //? cadastrar ou editar uma parte
    {
        e.preventDefault();
        let dados =
        { //pega os dados do state e nao do array de partes pq a parte ainda não está no array de partes, só após a confirmação do back-end
            'nome': this.state.nome,
            'tipo_cpf': this.state.sw_cpf,
            'cpf': this.state.cpf,
            'tipo_rg': this.state.sw_rg,
            'rg': this.state.rg,
            'tipo_cnpj': this.state.sw_cnpj,
            'cnpj': this.state.cnpj,
            'sem_documento': this.state.sw_sem_documento,
            'estado_id': this.state.estado_id,
            'cidade_id': this.state.municipio_id,
            'endereco': this.state.endereco,
            'telefones': this.state.telefones,
            'descricao': this.state.descricao
        } //display_cpf_cnpj não é atribuído aqui, só na função inserirParte(dados), pq é um dado que só importa no front-end

        if (dados.sem_documento) //se não tiver documento
        {
            dados.tipo_cpf = false;
            dados.cpf = null;
            dados.tipo_rg = false;
            dados.rg = null;
            dados.tipo_cnpj = false;
            dados.cnpj = null;
        }
        //o if abaixo determina se é cadastrar parte ou editar
        if (this.state.flag_editar_parte) //! editar parte
        {
            dados['id'] = this.state.id //esse 'state.id'é criado em 'toggleModalEditar', não é declarado em state, e sim adicionado na função citada

            axios.post(process.env.REACT_APP_DOMAIN + "/parte/update/" + dados['id'], dados
                , {
                    headers:
                    {
                        'authorization': getToken()
                    }
                }).then(res => {
                    if (res.status === 200) {
                        swal({
                            title: "Sucesso!",
                            text: "A parte foi editada com sucesso",
                            icon: "success",
                            timer: 3000,
                            button: false
                        }).then(() => this.atualizarParte(dados)) //? agora que a parte foi atualizada no banco, refletir as alterações no front-end
                            .then(() =>
                                this.setState({  //! volta o modal para o estado padrão
                                    nome: "",
                                    id: -1, //já que agora ele existe, precisa ser resetado também, diferente da limpeza em cadastrar parte abaixo
                                    sw_cpf: true,
                                    cpf: "",
                                    sw_rg: false,
                                    rg: "",
                                    sw_cnpj: false,
                                    cnpj: "",
                                    estados: [],
                                    estado_id: 0,
                                    municipios: [],
                                    municipio_id: 0,
                                    endereco: "",
                                    telefones: [{ telefone: "" }],
                                    descricao: "",
                                    isOpen: false
                                })
                            )
                    }
                }).catch(
                    function (e) {
                        if (e.response.status === 500) {
                            swal({
                                title: "Erro",
                                text: "Não foi possível cadastrar a parte, favor tentar novamente",
                                icon: "error",
                                button: "Fechar"
                            })
                        }
                        else if (e.response.status === 420) {
                            swal({
                                title: "Erro",
                                text: "O CPF/RG/CNPJ não é válido, favor corrigir",
                                icon: "error",
                                button: "Fechar"
                            })
                        }
                    }
                );
        }
        else //!cadastrar parte
        {
            //axios.post("http://10.53.23.189:8000/parte/store", dados
            axios.post(process.env.REACT_APP_DOMAIN + "/parte/store", dados
                , {
                    headers:
                    {
                        'authorization': getToken()
                    }
                }).then(res => {
                    if (res.status === 200) {
                        swal({
                            title: "Sucesso!",
                            text: "A parte foi cadastrada com sucesso",
                            icon: "success",
                            timer: 3000,
                            button: false
                        }).then(() =>
                            dados['id'] = res.data, //pega o id que foi atribuído a parte no banco
                            this.setState({  //volta o modal para o estado padrão
                                nome: "",
                                sw_cpf: true,
                                cpf: "",
                                sw_rg: false,
                                rg: "",
                                sw_cnpj: false,
                                cnpj: "",
                                estados: [],
                                estado_id: 0,
                                municipios: [],
                                municipio_id: 0,
                                endereco: "",
                                telefones: [{ telefone: "" }],
                                descricao: "",
                                isOpen: false
                            })
                        ).then(() => //depois que a requisição de store der ok e retornar o id da parte no banco
                            this.inserirParte(dados) //adiciona a parte (que acabou de ser cadastrada) ao processo sendo cadastrado
                        )
                    }
                }).catch(
                    function (e) {
                        if (e.response.status === 500) {
                            swal({
                                title: "Erro",
                                text: "Não foi possível cadastrar a parte, favor tentar novamente",
                                icon: "error",
                                button: "Fechar"
                            })
                        }
                        else if (e.response.status === 420) {
                            swal({
                                title: "Erro",
                                text: "O CPF/CNPJ não é válido, favor corrigir",
                                icon: "error",
                                button: "Fechar"
                            })
                        }
                    }
                );
        }
    }

    addCadastrarParte(e) {
        e.preventDefault();
        this.setState({ flag_botao_add_parte: !this.state.flag_botao_add_parte });
    }

    handleDesvincularParte(id) //id -> posição da parte no array de partes
    {
        let flag_parte_principal = false;
        let partes = [...this.state.partes];

        if (partes[id].sw_parte_principal === true) //se a parte a ser desvinculada é a principal
            flag_parte_principal = true;


        if (this.state.flag_editar_processo) //em editar processo, desvincular precisa avisar o back-end
        {
            swal({
                title: "Você tem certeza?",
                text: "Esta ação não poderá ser desfeita!",
                icon: "warning",
                buttons: {
                    cancel: {
                        text: "NÃO",
                        value: null,
                        visible: true,
                        className: "",
                        closeModal: true,
                    },
                    confirm: {
                        text: "SIM",
                        value: true,
                        visible: true,
                        className: "",
                        closeModal: true
                    }
                },
                dangerMode: true,
            })
                .then((result) => {
                    if (result) // se clicar em 'SIM'
                    {
                        let dados = //dados necessários para desvincular um processo em editar processo (onde já existe o vínculo no back-end OU NÃO)
                        {
                            parte_id: partes[id].id,
                            numeracao_unica: this.state.numeracao_unica
                        }
                        /**
                         * Caso a parte tenha sido vinculada ao processo somente no front-end (o processo ainda não foi atualizado com a nova parte)
                         * Então ao 'desvincular' esse relacionamento que não existe no back-end, somente o front-end precisa ser modificado
                         * Se a requisição retornar '212' significa que tentou apagar um relacionamento que não existe (exatamente o caso descrito agora)
                         * E só precisa apagar do array de partes do front-end
                         */
                        //axios.post("http://10.53.23.189:8000" + '/parte/desvincular', dados
                        axios.post(process.env.REACT_APP_DOMAIN + '/parte/desvincular', dados
                            , {
                                headers:
                                {
                                    'authorization': getToken()
                                },
                            })
                            .then(res => {
                                if (res.status === 200 || res.status === 212) {
                                    swal({
                                        title: "Sucesso!",
                                        text: "A parte foi desvinculada",
                                        icon: "success",
                                        timer: 3000,
                                        button: false
                                    });
                                    //com a confirmação do back-end, a parte pode ser removida do front-end
                                    partes.splice(id, 1); //remove do array

                                    /*if (flag_parte_principal) //essa execução está depois da deleção pq se a parte desvinculada era a da posicao 0...
                                        if (partes.length > 0) //e se houver pelo menos 1 parte
                                            partes[0].sw_parte_principal = true; //... a nova parte na posição 0 vira a principal
        
                                    if (partes.length === 1) //se só houver uma parte no array de partes
                                        partes[0].sw_parte_principal = true; //ela é a principal
                                    */

                                    if (partes.length > 0) {
                                        if (flag_parte_principal) //se a parte que foi excluída era a marcada como principal
                                        {
                                            partes[0].sw_parte_principal = true; //a primeira parte do processo agora é a principal
                                        }
                                    }

                                    this.setState({ partes }); //salva o novo array de partes
                                }
                            }).catch(
                                function (e) {
                                    if (e.response.status === 500) {
                                        swal({
                                            title: "Erro",
                                            text: "Esta parte não pôde ser desvinculada, favor tentar novamente mais tarde",
                                            icon: "error",
                                            button: "Fechar"
                                        })
                                    }
                                }
                            );
                    }
                    else {
                    }
                });
        }
        else //em cadastrar processo, ainda não existe vínculo da parte com o processo, então a operação só precisa ser realizada no front-end
        {
            partes.splice(id, 1); //remove do array

            /*if (flag_parte_principal) //essa execução está depois da deleção pq se a parte desvinculada era a da posicao 0...
                if (partes.length > 0) //e se houver pelo menos 1 parte
                    partes[0].sw_parte_principal = true; //... a nova parte na posição 0 vira a principal

            if (partes.length === 1) //se só houver uma parte no array de partes
                partes[0].sw_parte_principal = true; //ela é a principal

            */
            if (partes.length > 0) {
                if (flag_parte_principal) //se a parte que foi excluída era a marcada como principal
                {
                    partes[0].sw_parte_principal = true; //a primeira parte do processo agora é a principal
                }
            }

            this.setState({ partes }); //salva o novo array de partes
        }
    }

    handleChangeSwitchPartePrincipal(valor, id) {//There must always be a lich k....  quer dizer, sempre deve haver uma parte principal! o código não pode ter todas as partes com o switch desligado
        let partes = [...this.state.partes];
        for (let i = 0; i < partes.length; i++) {
            const parte = partes[i];
            if (i === id)
                parte.sw_parte_principal = true;
            else
                parte.sw_parte_principal = false;
        }
        this.setState({ partes });
        //partes[id].sw_parte_principal =
    }

    onSuggestionSelected(event, { suggestion }) { //essa função adiciona a parte selecionada ao processo
        /**
         * exemplo de como vem os dados da parte que o usuario clicar (parte == sugestao)
         *
            cidade_id: 210​
            cnpj: null
            cpf: "000.123.789-94"
            descricao: "Empresa de Agua e Saneamento"
            documento: "CPF: 000.123.789-94"
            endereco: "Rua A, Bairro X - 451"
            estado_id: 5
            id: 10
            nome: "ADSON SANTOS"
            tipo_cnpj: false
            tipo_cpf: true

            // a propriedade 'documento' vem no formato 'documento_parte: numeros'
            // pq na seção da parte no processo ela so precisa ser exibida, não é necessário realizar operações nela
         */

        let parte = {}

        if (suggestion.tipo_cpf) //se tiver cpf
        {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: null,
                rg: null,
                cpf: suggestion.cpf,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_cnpj: false,
                tipo_rg: false,
                tipo_cpf: true
            }
        }
        else if (suggestion.tipo_rg) //se tiver rg
        {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: null,
                rg: suggestion.rg,
                cpf: null,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_cnpj: false,
                tipo_rg: true,
                tipo_cpf: false
            }
        }
        else //se tiver cnpj
        {
            parte =
            {
                id: suggestion.id,
                nome: suggestion.nome,
                display_cpf_cnpj: suggestion.documento, // essa propriedade muda de nome, cuidado!
                sw_parte_principal: false,
                sem_documento: suggestion.sem_documento,

                cidade_id: suggestion.cidade_id,
                cnpj: suggestion.cnpj,
                rg: null,
                cpf: null,
                descricao: suggestion.descricao,
                endereco: suggestion.endereco,
                estado_id: suggestion.estado_id,
                telefones: suggestion.telefones,
                tipo_rg: false,
                tipo_cnpj: true,
                tipo_cpf: false
            }
        }

        //? inicializando valores
        parte['qtd_parcelas'] = 1;
        parte['valor_executado'] = 0;
        parte['valor_estimado'] = 0;
        parte['valor_efetivo'] = 0;

        parte['data_execucao'] = null;
        parte['data_ingresso_conta'] = null;
        parte['data_transito_julgado'] = null;
        parte['data_cobranca'] = null;

        /**
           data_execucao: "",
           data_ingresso_conta: "",
           data_transito_julgado: "",
           data_cobranca: "",
           data_agendado_retorno: "",
           campos_extras: '',
           valor_executado: 0,
           valor_executado_tratado: 0, //sem o prefixo "R$ ", utilizado para o cálculo da parcela
           valor_estimado: 0,
           valor_efetivo: 0,
           receita_recebida: 0,
           prorrogacao_id: '',
           motivo_id: '',
         */

        let partes = [...this.state.partes]; //cópia do state atual

        if (partes.length === 0) //se não houver nenhuma parte no processo, a que acabou de ser selecionada é a principal
            parte['sw_parte_principal'] = true;

        partes.push(parte) //adiciona no fim

        this.setState({ partes }) //salva


        this.setState({ //resetando os states
            flag_botao_add_parte: true, //exibir novamente o botão de adicionar parte
            value: "", //zerar o valor da pesquisa de parte
        })
    }

    getSuggestionValue(suggestion) {
        return suggestion.nome;
    }

    renderSuggestion(suggestion) {
        return (
            <span>{suggestion.nome}<br />{suggestion.documento}</span>
        );
    }

    onChangeSuggest = (event, { newValue, method }) => {
        this.setState({
            value: newValue
        });
    };

    onSuggestionsFetchRequested = async ({ value }) => {//essa função faz a requisição p/ o back-end retornar os dados das partes de acordo com o que foi escrito
        let valor_pesquisar = { 'string': value } //prepara o obj com a string a ser pesquisada no back-end
        let sugestoes_partes = [] //var que vai ter todas as sugestões

        //await axios.post("http://10.53.23.189:8000/parte/autocomplete", valor_pesquisar
        await axios.post(process.env.REACT_APP_DOMAIN + "/parte/autocomplete", valor_pesquisar
            , { //await -> segura a execução do resto do código enquanto o comando não termina, necessita que a função seja assíncrona
                headers:
                {
                    //'content-type': 'application/x-www-form-urlencoded',
                    'authorization': getToken()
                },
            })
            .then((resposta) => {
                //prepara os objetos de cada parte com seus respectivos dados
                for (let i = 0; i < resposta['data'].length; i++) //TODO: trocar por map?
                {
                    sugestoes_partes.push({}) //insere um obj vazio no array, p/ poder acessar pelo índice mais abaixo

                    const element = resposta['data'][i]; //element é o valor da vez
                    //sem o .push({}) acima, [i] é inacessível
                    sugestoes_partes[i]['id'] = element.id; //o id da parte no back-end! Importante
                    sugestoes_partes[i]['nome'] = element.nome;
                    sugestoes_partes[i]['documento'] = element.documento;
                    sugestoes_partes[i]['sem_documento'] = element.sem_documento;

                    if (element.sem_documento) {
                        sugestoes_partes[i]['tipo_cpf'] = true;
                        sugestoes_partes[i]['tipo_rg'] = false;
                        sugestoes_partes[i]['tipo_cnpj'] = false;

                        sugestoes_partes[i]['cpf'] = null;
                        sugestoes_partes[i]['rg'] = null;
                        sugestoes_partes[i]['cnpj'] = null;
                    }
                    else {
                        if (element.tipo_cpf) //element.tipo_cpf === true
                        {
                            sugestoes_partes[i]['tipo_cpf'] = true;
                            sugestoes_partes[i]['tipo_rg'] = false;
                            sugestoes_partes[i]['tipo_cnpj'] = false;

                            sugestoes_partes[i]['cpf'] = element.cpf;
                            sugestoes_partes[i]['rg'] = null;
                            sugestoes_partes[i]['cnpj'] = null;
                        }
                        else if (element.tipo_rg) //element.tipo_rg === true
                        {
                            sugestoes_partes[i]['tipo_cpf'] = false;
                            sugestoes_partes[i]['tipo_rg'] = true;
                            sugestoes_partes[i]['tipo_cnpj'] = false;

                            sugestoes_partes[i]['cpf'] = null;
                            sugestoes_partes[i]['rg'] = element.rg;
                            sugestoes_partes[i]['cnpj'] = null;
                        }
                        else {
                            sugestoes_partes[i]['tipo_cpf'] = false;
                            sugestoes_partes[i]['tipo_rg'] = false;
                            sugestoes_partes[i]['tipo_cnpj'] = true;

                            sugestoes_partes[i]['cpf'] = null
                            sugestoes_partes[i]['rg'] = null;
                            sugestoes_partes[i]['cnpj'] = element.cnpj
                        }
                    }
                    sugestoes_partes[i]['descricao'] = element.descricao
                    sugestoes_partes[i]['endereco'] = element.endereco
                    sugestoes_partes[i]['estado_id'] = element.estado_id
                    sugestoes_partes[i]['cidade_id'] = element.cidade_id
                    sugestoes_partes[i]['telefones'] = element.telefones
                }
            }) //fim do axios e do 'await', o que vier em seguida só será executado depois que o comando com 'await' terminar
        this.setState({
            sugestoes_partes: sugestoes_partes
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            sugestoes_partes: []
        });
    };

    render() {
        let { observacoes } = this.state
        let { telefones } = this.state
        let { partes } = this.state
        let { notificacao } = this.state
        let { notificacaoAvisos } = this.state

        const imageSource = notificacao
            ? require("../images/atos_image5.png")
            : require("../images/atos_image2.png");

        const imageSourceAviso = notificacaoAvisos ? require("../images/aviso_image2.png") : require("../images/aviso_image.png");

        const { value } = this.state;
        const inputProps =
        {
            placeholder: "Pesquisar Parte",
            value,
            onChange: this.onChangeSuggest
        };

        if (this.state.flag_redirecionar) {
            return <Redirect to='/home' />
        }

        //renderiza a tela
        return (
            <>
                <Topo_Autenticado />
                <div>
                    <div className="container">
                        <div className="titulo-cadastrar-editar-processo">
                            <h5>
                                {this.state.flag_editar_processo ?
                                    "Editar Processo"
                                    :
                                    "Cadastrar Processo"
                                }
                            </h5>
                        </div>
                        <div>
                            <div>
                                <div className="dados-processo">
                                    <div className="numeracao-processo">
                                        <label htmlFor="numeracao_unica">Número do Processo</label>
                                        <InputMask
                                            placeholder="Número do Processo"
                                            id="numeracao_unica"
                                            name="numeracao_unica"
                                            mask="9999999-99.9999.9.99.9999"
                                            value={this.state.numeracao_unica}
                                            disabled={this.state.flag_editar_processo} //se for editar processo não pode alterar a numeração
                                            onChange={valor => this.handleNumeracaoUnica(valor)}
                                            required
                                        />
                                    </div>
                                    <div className="cb-prioritario">
                                        <p>
                                            <label>
                                                <input type="checkbox" id="prioritario" name="prioritario" refs="prioritario"
                                                    defaulchecked="false" checked={this.state.prioritario} onChange={this.handlerCheckPrioritario}
                                                />
                                                <span>Prioritário</span>
                                            </label>
                                        </p>
                                    </div>
                                </div>
                                <div className="selects-comarca-vara">
                                    <div className="select-comarca">
                                        <Select
                                            onChange={this.handleComarca.bind(this)}
                                            options={this.state.comarcas}
                                            placeholder="Selecionar Comarca"
                                            noOptionsMessage={() => 'Carregando...'}
                                            inputId="comarca_id"
                                            value={this.state.comarcas.filter(({ value }) => { return value === this.state.comarca_id })}
                                        />
                                    </div>
                                    <div className="select-vara">
                                        <Select
                                            onChange={this.handleUnidadeJudiciaria.bind(this)}
                                            options={this.state.unidades_judiciarias}
                                            noOptionsMessage={() => 'Selecione uma Comarca'}
                                            placeholder="Selecionar Unidade Judiciária"
                                            inputId="unidade_judiciaria_id"
                                            value={this.state.unidades_judiciarias.filter(({ value }) => { return value === this.state.unidade_judiciaria_id })}
                                        />
                                    </div>
                                </div>
                                <div className="titulo-cadastrar-editar-parte">
                                    <h5>
                                        {this.state.flag_editar_processo ?
                                            "Editar Parte(s)"
                                            :
                                            "Cadastrar Parte(s)"
                                        }
                                    </h5>
                                </div>


                                {/*------------------MODAL CADASTRAR/EDITAR PARTE------------------*/}
                                <Modal
                                    open={this.state.isOpen}
                                    onClose={this.toggleModal}
                                    center
                                    className="modal_cadastrar_parte"
                                >
                                    <h5>
                                        {this.state.flag_editar_parte ?
                                            "Editar Dados da Parte"
                                            :
                                            "Cadastrar Parte"
                                        }
                                    </h5>
                                    <br />
                                    <div className="modal_cadastrar_parte">
                                        <div className="col s3">
                                            <label>Nome Completo/Nome Fantasia</label>
                                            <input
                                                type='text'
                                                name='nome'
                                                className='form-control'
                                                placeholder='Nome Completo ou Nome Fantasia'
                                                value={this.state.nome}
                                                onChange={this.handleChangeNome.bind(this)}
                                                size="12"
                                                required
                                            />
                                        </div>
                                        <br />

                                        <div class="modal-row-documento">
                                            <div class="secao-cpf">
                                                <div class='float-left-child-sw-cpf'>
                                                    <br />
                                                    <Switch
                                                        id="sw_cpf"
                                                        onChange={(e) => this.handleChangeSwitch("cpf")}
                                                        checked={this.state.sw_cpf}
                                                        disabled={Boolean(this.state.sw_sem_documento)}
                                                    />
                                                    {" "}
                                                </div>
                                                <div class='child float-left-child-input-cpf'>
                                                    <label>CPF</label>
                                                    <InputMask
                                                        placeholder="CPF"
                                                        id="cpf"
                                                        name="cpf"
                                                        mask="999.999.999-99"
                                                        value={this.state.cpf}
                                                        onChange={this.handleChangeCPF.bind(this)}
                                                        disabled={Boolean(!this.state.sw_cpf) || Boolean(this.state.sw_sem_documento)}
                                                        required={this.state.sw_cpf}
                                                    />
                                                </div>
                                            </div>

                                            <div class="secao-rg">
                                                <div class='child float-left-child-sw-rg'>
                                                    <Switch
                                                        id="sw_rg"
                                                        onChange={(e) => this.handleChangeSwitch("rg")}
                                                        checked={this.state.sw_rg}
                                                        disabled={Boolean(this.state.sw_sem_documento)}
                                                    />
                                                </div>
                                                <div class='float-left-child-input-rg'>
                                                    <label>RG</label>
                                                    <InputMask
                                                        placeholder="RG"
                                                        id="rg"
                                                        name="rg"
                                                        //mask="99.999.999-99"
                                                        value={this.state.rg}
                                                        onChange={this.handleChangeRG.bind(this)}
                                                        disabled={Boolean(!this.state.sw_rg) || Boolean(this.state.sw_sem_documento)}
                                                        required={this.state.sw_cnpj}
                                                    />
                                                </div>
                                            </div>

                                            <div class="secao-cnpj">
                                                <div class='child float-left-child-sw-cnpj'>
                                                    <Switch
                                                        id="sw_cnpj"
                                                        onChange={(e) => this.handleChangeSwitch("cnpj")}
                                                        checked={this.state.sw_cnpj}
                                                        disabled={Boolean(this.state.sw_sem_documento)}
                                                    />
                                                </div>
                                                <div class='float-left-child-input-cnpj'>
                                                    <label>CNPJ</label>
                                                    <InputMask
                                                        placeholder="CNPJ"
                                                        id="cnpj"
                                                        name="cnpj"
                                                        mask="99.999.999/9999-99"
                                                        value={this.state.cnpj}
                                                        onChange={this.handleChangeCNPJ.bind(this)}
                                                        disabled={Boolean(!this.state.sw_cnpj) || Boolean(this.state.sw_sem_documento)}
                                                        required={this.state.sw_cnpj}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="div-sem-doc">
                                            <Switch
                                                id="sw_sem_documento"
                                                onChange={this.handleChangeSwitchDocumento}
                                                checked={this.state.sw_sem_documento}
                                            />
                                            <label className="div-sem-doc-label">
                                                Sem Documento
                                            </label>
                                        </div>
                                        <div class="modal-row-estado-municipio">
                                            <div className='child float-left-child-select-estado'>
                                                <br />
                                                <label>Estado</label>
                                                <Select
                                                    onChange={this.handleEstado.bind(this)}
                                                    options={this.state.estados}
                                                    placeholder="Selecionar Estado"
                                                    noOptionsMessage={() => 'Carregando...'}
                                                    inputId="estado_id"
                                                    inputName="estado_id"
                                                    value={this.state.estados.filter(({ value }) => { return value === this.state.estado_id })}
                                                />
                                            </div>
                                            <div className='child float-left-child-select-municipio'>
                                                <br />
                                                <label>Município</label>
                                                <Select
                                                    onChange={this.handleMunicipio.bind(this)}
                                                    options={this.state.municipios}
                                                    placeholder="Selecionar Município"
                                                    noOptionsMessage={() => 'Selecione um Estado'}
                                                    inputId="municipio_id"
                                                    inputName="municipio_id"
                                                    value={this.state.municipios.filter(({ value }) => { return value === this.state.municipio_id })}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div class="parent">
                                            <div class='child float-left-child-input-endereco'>
                                                <label>Endereço</label>
                                                <input
                                                    type='text'
                                                    name='endereco'
                                                    className='form-control'
                                                    placeholder='Endereço'
                                                    onChange={this.handleChangeEndereco.bind(this)}
                                                    value={this.state.endereco}
                                                    required
                                                />
                                            </div>
                                            <div>
                                                <label>Descrição</label>
                                                <input
                                                    type='text'
                                                    name='descricao'
                                                    className='form-control'
                                                    placeholder='Descrição'
                                                    onChange={this.handleChangeDescricao.bind(this)}
                                                    value={this.state.descricao}
                                                />
                                                <br />
                                            </div>
                                            <div>
                                                {
                                                    this.state.telefones.map((telefone, idx) => {
                                                        //var observacaoId = `observacao-${idx}`;
                                                        var tel_id = idx; //essa var é necessária pois no onClick abaixo a var 'idx' é null

                                                        return (
                                                            <div key={idx} className="row">
                                                                <div className={idx} id={idx}>
                                                                    <div className=" col s11" >
                                                                        <InputMask
                                                                            placeholder="Telefone"
                                                                            id="telefone"
                                                                            name="telefone"
                                                                            mask="(99) 999999999"
                                                                            value={telefone.telefone}
                                                                            onChange={value => this.handleChangeTelefone(value.target.value, idx)}
                                                                        />
                                                                        <label htmlFor={tel_id}>
                                                                            {`Telefone #${idx + 1}`}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="col s1">
                                                                    <a href="/#" onClick={
                                                                        (e) => {
                                                                            e.preventDefault();
                                                                            this.handleDeletarTel(tel_id);
                                                                        }
                                                                    }>
                                                                        <img alt="" className="" src={require("../images/icons8-cancelar-30.png")} />
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                                <div className="row">
                                                    <div className="col s6 offset-s11"><span className="flow-text">
                                                        <a href="/#" onClick={this.addTel}><img alt="" src={require("../images/icons8-adicionar.svg")} /></a>
                                                    </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div //acerta a posição do spinner //TODO:
                                            style={{
                                                width: "100%",
                                                height: "100",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Spinner //configs do spinner
                                                type="ThreeDots"
                                                color="#2BAD60"
                                                height="50"
                                                width="50"
                                                visible={this.state.spinnerReset} //FIXME: renomear state
                                            />
                                        </div>
                                        <br />
                                        <button
                                            className="btn"
                                            type="submit"
                                            onClick={this.handleCadastrarParte}
                                        >
                                            Enviar
                                        </button>
                                    </div>
                                </Modal>
                                {/*------------------MODAL CADASTRAR/EDITAR PARTE------------------*/}

                                <Modal
                                    open={this.state.isOpen2}
                                    onClose={this.toggleModal2}
                                    center
                                >
                                    <div className="modal_atos_aviso">

                                        <h5>
                                            Lista de Ato(s) Praticado(s)
                                        </h5>


                                        <br />
                                        <div className="modal_atos_aviso_header">
                                            <p><b>Atos praticados não visualizados </b></p>
                                        </div>
                                        <NestedTable data={this.state.myAtosTablesNaoLidos}
                                            onButtonClick={this.handleButtonClick}
                                            isLidoProp={false} />

                                        <p><b>Atos praticados visualizados</b></p>
                                        <NestedTable data={this.state.myAtosTablesLidos}
                                            onButtonClick={this.handleButtonClick2}
                                            isLidoProp={true} />

                                    </div>
                                </Modal>

                                {/*------------------MODAL AVISOS PENDENTES------------------*/}
                                <Modal
                                    open={this.state.isOpenModelAviso}
                                    onClose={this.toggleModalAvisoPendente}
                                    center
                                    className="modal_avisos_pendentes"
                                >
                                    <div >

                                        <h5>Lista de Avisos Pendentes</h5>


                                        <br />
                                        <div className="modal_atos_aviso_header">
                                            <p><b>Atos praticados não visualizados </b></p>
                                        </div>
                                        <AvisosTable data={this.state.avisosPendentesNaoLidos}
                                            onButtonClick={this.lerAvisos}
                                            isLidoProp={false} />

                                        <p><b>Atos praticados visualizados</b></p>
                                        <AvisosTable data={this.state.avisosPendentesLidos}
                                            onButtonClick={this.deslerAvisos}
                                            isLidoProp={true} />

                                    </div>
                                </Modal>

                                {/*------------------MODAL CADASTRAR/EDITAR PARTE------------------*/}

                                <div className="row">
                                    {
                                        //observacoes.map((observacao, idx) => { //
                                        this.state.partes.map((parte, idx) => {
                                            //var observacaoId = `observacao-${idx}`;
                                            //var parte_id = idx; //essa var é necessária pois nos onClick's abaixo a var 'idx' é null

                                            return (
                                                <div>
                                                    {parte.nome == "" ?
                                                        null
                                                        :
                                                        <div key={idx} className="row-dados-parte">
                                                            <div className="row-display-nome-doc">
                                                                <h6>
                                                                    {parte.nome}
                                                                    <br />
                                                                    {parte.display_cpf_cnpj}
                                                                </h6>
                                                            </div>

                                                            <div className="row-btn-editar-parte">
                                                                <button
                                                                    className='btn'
                                                                    onClick={() => this.toggleModalEditar(idx)}
                                                                //esse onClick usa callback/arrow function p/ evitar re-render do toggleModalEditar
                                                                //ou seja, evita loop
                                                                >
                                                                    EDITAR DADOS DA PARTE
                                                                </button>
                                                            </div>

                                                            <div className="div-btns-manipular-parte">
                                                                <div className="btn-desvincular"> {/* Botão de desvincular Parte */}
                                                                    <a href="/#" onClick={
                                                                        (e) => {
                                                                            e.preventDefault();
                                                                            this.handleDesvincularParte(idx);
                                                                        }
                                                                    }>
                                                                        <img alt="" className="" src={require("../images/icon_delete20.png")} />
                                                                        <label class="label-desvincular-parte">
                                                                            Desvincular Parte do Processo
                                                                        </label>
                                                                    </a>
                                                                </div>

                                                                <div className={idx} id={idx}> {/* Switch Parte Principal */}
                                                                    <Switch
                                                                        id={"sw_" + idx}
                                                                        onChange={valor => this.handleChangeSwitchPartePrincipal(valor, idx)}
                                                                        //checked={this.state.partes[idx].sw_parte_principal}
                                                                        checked={parte.sw_parte_principal}
                                                                    />
                                                                    <label class="label-parte-principal">
                                                                        Parte Principal do Processo
                                                                    </label>
                                                                </div>
                                                            </div>


                                                            <div>
                                                                {/*<div className="input-field col s4">*/}
                                                                <div className="div-select-situacao">
                                                                    <Select
                                                                        onChange={value => this.handleSituacao(value, idx)}
                                                                        options={this.state.situacoes}
                                                                        placeholder="Selecionar Situação"
                                                                        inputId="situacao_id"
                                                                        inputName="situacao_id"
                                                                        value={this.state.situacoes.filter(({ value }) => { return value === parte.situacao_id })}
                                                                    />
                                                                </div>
                                                                {/*<div className="input-field col s6">*/}
                                                                <div className="div-campos-situacao">
                                                                    {/* parte.campos_extras TODO: remover essa linha? */}
                                                                    {/**########################## 01 NOVO ##########################**/}
                                                                    {parte.situacao_id === 1 ?
                                                                        <div className="div-sit-novo">
                                                                            <label htmlFor="valor_estimado">
                                                                                Valor estimado com base na petição inicial
                                                                            </label>
                                                                            <CurrencyInput
                                                                                id="valor_estimado"
                                                                                value={parte.valor_estimado}
                                                                                prefix="R$ "
                                                                                decimalSeparator=","
                                                                                thousandSeparator="."
                                                                                onChange={value => this.handleValorEstimado(value, idx)}
                                                                                selectAllOnFocus={true}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 02 COBRANÇA DISPENSADA ##########################**/}
                                                                    {parte.situacao_id === 2 ?
                                                                        <div className="div-sit-cob-dis">
                                                                            <div className="sit-cob-dis-select-motivo">
                                                                                <Select
                                                                                    //onChange={this.handleMotivo.bind(this)}
                                                                                    onChange={value => this.handleMotivo(value, idx)}
                                                                                    options={this.state.motivos}
                                                                                    placeholder="Selecionar Motivo"
                                                                                    inputId="motivo_id"
                                                                                    name="motivo_id"
                                                                                    tooltip="Motivo"
                                                                                    value={this.state.motivos.filter(({ value }) => {
                                                                                        return value === parte.motivo_id
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                            <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                            <CurrencyInput
                                                                                id="valor_estimado"
                                                                                value={parte.valor_estimado}
                                                                                prefix="R$ "
                                                                                decimalSeparator=","
                                                                                thousandSeparator="."
                                                                                onChange={value => this.handleValorEstimado(value, idx)}
                                                                                selectAllOnFocus={true}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 03 EXECUTADO ##########################**/}
                                                                    {parte.situacao_id === 3 ?
                                                                        <div className="div-sit-exe">
                                                                            <div className="div-sit-exe-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="div-sit-exe-valor-executado">
                                                                                <label htmlFor="valor_executado">Valor Executado</label>
                                                                                <CurrencyInput
                                                                                    id="valor_executado"
                                                                                    value={parte.valor_executado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorExecutado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="div-sit-exe-btn-calc">
                                                                                <Button
                                                                                    variant="primary"
                                                                                    href="https://www.tjrs.jus.br/site/servicos/ferramenta_de_calculo/index.html"
                                                                                    target="_blank"
                                                                                >
                                                                                    Calcular Honorário
                                                                                </Button>
                                                                            </div>
                                                                            <div className="div-sit-exe-data-exe">
                                                                                <label htmlFor="data_transito_julgado">Data de Execução</label>
                                                                                <input
                                                                                    type="date"
                                                                                    name="data_execucao"
                                                                                    id="data_execucao"
                                                                                    value={parte.data_execucao}
                                                                                    onChange={value => this.handleDataExecucao(value, idx)}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 04 EXECUÇÃO CONCLUÍDA ##########################**/}
                                                                    {parte.situacao_id === 4 ?
                                                                        <div className="div-sit-exe-con">
                                                                            <div className="div-sit-exe-con-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="div-sit-exe-con-valor-executado">
                                                                                <label htmlFor="valor_executado">Valor Executado</label>
                                                                                <CurrencyInput
                                                                                    id="valor_executado"
                                                                                    value={parte.valor_executado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorExecutado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="div-sit-exe-con-receita-recebida">
                                                                                <label htmlFor="valor_efetivo">Receita efetivamente recebida pela instituição</label>
                                                                                <CurrencyInput
                                                                                    id="valor_efetivo"
                                                                                    value={parte.valor_efetivo}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={valor => this.handleValorEfetivo(valor, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="div-sit-exe-con-data-ingresso">
                                                                                <label htmlFor="data_ingresso_conta">Data de ingresso da receita</label>
                                                                                <input
                                                                                    type="date"
                                                                                    onChange={valor => this.handleDataIngressoConta(valor, idx)}
                                                                                    name="data_ingresso_conta"
                                                                                    id="data_ingresso_conta"
                                                                                    value={parte.data_ingresso_conta}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 05 HONORÁRIO NÃO TRANSITADO##########################**/}
                                                                    {parte.situacao_id === 5 ?
                                                                        <div className="div-sit-hon-n-tra">
                                                                            <div className="sit-hon-n-tra-select-tempo">
                                                                                <Select
                                                                                    onChange={value => this.handleProrrogacao(value, idx)}
                                                                                    options={this.state.prorrogacoes}
                                                                                    placeholder="Tempo de Prorrogação"
                                                                                    inputId="prorrogacao_id"
                                                                                    name="prorrogacao_id"
                                                                                    value={this.state.prorrogacoes.filter(({ value }) => { return value === parte.prorrogacao_id })}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-hon-n-tra-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 06 HONORÁRIO TRANSITADO ##########################**/}
                                                                    {parte.situacao_id === 6 ?
                                                                        <div className="div-sit-hon-tra">
                                                                            <div className="sit-hon-tra-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 07 IMPROCEDENTE ##########################**/}
                                                                    {parte.situacao_id === 7 ?
                                                                        <div className="div-sit-impro">
                                                                            <div className="sit-impro-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 08 RETORNO ##########################**/}
                                                                    {/** A DEFINIR */}
                                                                    {/**########################## 09 REQUER EMBARGOS DE DECLARAÇÃO ##########################**/}
                                                                    {parte.situacao_id === 9 ?
                                                                        <div className="div-sit-req-emb">
                                                                            <div className="sit-req-emb-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 10 RISCO DE PRESCRIÇÃO ##########################**/}
                                                                    {parte.situacao_id === 10 ?
                                                                        <div className="div-sit-ris-pre">
                                                                            <div className="sit-ris-pre-valor-estimado">
                                                                                <label htmlFor="valor_estimado">Valor estimado com base na petição inicial</label>
                                                                                <CurrencyInput
                                                                                    id="valor_estimado"
                                                                                    value={parte.valor_estimado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorEstimado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-ris-pre-data-transito">
                                                                                <label htmlFor="data_transito_julgado">Data do Trânsito em julgado</label>
                                                                                <input
                                                                                    onChange={valor => this.handleDataTransitoJulgado(valor, idx)}
                                                                                    type="date"
                                                                                    name="data_transito_julgado"
                                                                                    id="data_transito_julgado"
                                                                                    value={parte.data_transito_julgado}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 11 COBRANÇA ADMINISTRATIVA ##########################**/}
                                                                    {parte.situacao_id === 11 ? //carregar campos específicos da situação "Cobrança Administrativa"
                                                                        <div className="div-sit-cob-adm">
                                                                            <div className="sit-cob-adm-datas-cobranca-ingresso">
                                                                                <label htmlFor="data_cobranca">Data da Cobrança</label>
                                                                                <input
                                                                                    type="date"
                                                                                    onChange={valor => this.handleDataCobranca(valor, idx)}
                                                                                    name="data_cobranca"
                                                                                    id="data_cobranca"
                                                                                    value={parte.data_cobranca}
                                                                                    required
                                                                                />
                                                                                <label htmlFor="data_ingresso_conta">Data de Ingresso da Receita</label>
                                                                                <input
                                                                                    type="date"
                                                                                    onChange={valor => this.handleDataIngressoConta(valor, idx)}
                                                                                    name="data_ingresso_conta"
                                                                                    id="data_ingresso_conta"
                                                                                    value={parte.data_ingresso_conta}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                            <div className="sit-cob-adm-valor-exe">
                                                                                <label htmlFor="valor_executado">Valor Executado</label>
                                                                                <CurrencyInput
                                                                                    id="valor_executado"
                                                                                    value={parte.valor_executado}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    onChange={value => this.handleValorExecutado(value, idx)}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-cob-adm-data-retorno">
                                                                                <label htmlFor="data_agendado_retorno">Agendado Retorno</label>
                                                                                <input
                                                                                    type="date"
                                                                                    onChange={valor => this.handleDataAgendadoRetorno(valor, idx)}
                                                                                    name="data_agendado_retorno"
                                                                                    id="data_agendado_retorno"
                                                                                    value={parte.data_agendado_retorno}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-cob-adm-btn-calc">
                                                                                <Button variant="primary" href="https://www.tjrs.jus.br/site/servicos/ferramenta_de_calculo/index.html" target="_blank">Calcular Honorário</Button>
                                                                            </div>
                                                                            <div className="sit-cob-adm-parcela">
                                                                                <label>Parcelas</label>
                                                                                <NumericInput
                                                                                    min={1}
                                                                                    max={100}
                                                                                    value={parte.qtd_parcelas}
                                                                                    onChange={valor => this.handleParcelas(valor, idx)}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-cob-adm-valor-parcela">
                                                                                <label>Valor de Cada Parcela</label>
                                                                                <CurrencyInput
                                                                                    readOnly
                                                                                    value={(parte.valor_executado_tratado) / (parte.qtd_parcelas)}
                                                                                    //value = {(this.state.valor_executado)/(this.state.qtd_parcelas)}
                                                                                    prefix="R$ "
                                                                                    decimalSeparator=","
                                                                                    thousandSeparator="."
                                                                                    disabled={true}
                                                                                    selectAllOnFocus={true}
                                                                                />
                                                                            </div>
                                                                            <div className="sit-cob-adm-meio-comu">
                                                                                <label>Meio de Envio da Comunicação</label>
                                                                                <br />
                                                                                <label>
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="cb_correios"
                                                                                            name="cb_correios"
                                                                                            refs="cb_correios"
                                                                                            onChange={valor => this.handleCheckCBCorreios(valor, idx)}
                                                                                            checked={parte.cb_correios}
                                                                                        />
                                                                                        <span>Correios</span>
                                                                                    </label>
                                                                                    <br />
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="cb_email"
                                                                                            onChange={valor => this.handleCheckCBEmail(valor, idx)}
                                                                                            name="cb_email"
                                                                                            refs="cb_email"
                                                                                            checked={parte.cb_email}
                                                                                        />
                                                                                        <span>Email</span>
                                                                                    </label>
                                                                                    <br />
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="cb_telefone"
                                                                                            onChange={valor => this.handleCheckCBTelefone(valor, idx)}
                                                                                            name="cb_telefone"
                                                                                            refs="cb_telefone"
                                                                                            checked={parte.cb_telefone}
                                                                                        />
                                                                                        <span>Telefone</span>
                                                                                    </label>
                                                                                    <br />
                                                                                    <label>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="cb_outros"
                                                                                            onChange={valor => this.handleCheckCBOutro(valor, idx)}
                                                                                            name="cb_outros"
                                                                                            refs="cb_outros"
                                                                                            checked={parte.cb_outros}
                                                                                        />
                                                                                        <span>Outro Meio</span>
                                                                                    </label>
                                                                                    <input
                                                                                        placeholder={"Outro meio"}
                                                                                        type="text"
                                                                                        disabled={!parte.cb_outros}
                                                                                        onChange={valor => this.handleChangeOutroMeioComunicacao(valor, idx)}
                                                                                        id="outro_meio_comunicacao"
                                                                                        name="outro_meio_comunicacao"
                                                                                        value={parte.outro_meio_comunicacao}
                                                                                    />
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {/**########################## 12 PENDENTE ##########################**/}
                                                                    {parte.situacao_id === 12 ? //carregar campos específicos da situação "Pendente"
                                                                        <div className="div-sit-pend">
                                                                            <div className="sit-pend-select-motivo">
                                                                                <Select
                                                                                    onChange={value => this.handleMotivoPendencia(value, idx)}
                                                                                    options={this.state.motivos_pendentes}
                                                                                    placeholder="Selecionar Motivo"
                                                                                    inputId="motivo_pendente_id"
                                                                                    name="motivo_pendente_id"
                                                                                    tooltip="Motivo"
                                                                                    value={this.state.motivos_pendentes.filter(({ value }) => {
                                                                                        return value === parte.motivo_pendente_id
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                {this.state.flag_botao_add_parte ? //botão "Adicionar Parte"
                                    <div className="row">
                                        <div className="col s6">
                                            <a href="/#" onClick={(e) => this.addCadastrarParte(e)}>
                                                <img alt="" src={require("../images/icons8-adicionar.svg")} />
                                            </a>
                                            <label>Adicionar Parte</label>
                                        </div>
                                    </div>
                                    :
                                    <div className="div-pesquisar-add-parte">
                                        <div className="input-pesquisar-parte">
                                            <label>
                                                Pesquisar Parte
                                            </label>
                                            <Autosuggest
                                                suggestions={this.state.sugestoes_partes}
                                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                                getSuggestionValue={this.getSuggestionValue}
                                                renderSuggestion={this.renderSuggestion}
                                                inputProps={inputProps}
                                                onSuggestionSelected={this.onSuggestionSelected}
                                            />
                                        </div>
                                        <div className="btn-cadastrar-parte">
                                            <button className="btn" onClick={this.toggleModal} >CADASTRAR PARTE</button>
                                        </div>
                                    </div>
                                }





                                <div >
                                    <div className="btn-voltar">
                                        <a href="/home" className="btn">Voltar</a>
                                    </div>
                                    <div className="btn-inserir">
                                        <button
                                            className="btn"
                                            onClick={this.handleSubmit}
                                        /*disabled =
                                        { // ----------lógica do botão 'INSERIR'----------
                                        //o botão tem que ter {disabled = true} enquanto os campos estiverem vazios E a numeração do processo for menor que 20 dígitos
                                        //consequentemente, ele só será ativado para o usuário clicar {disabled = false} quando:
                                        //situação, comarca e UJ forem selecionados
                                        //E state.digitos_numeracao_unica >= 20
                                        //ou seja !(false && false && false)
                                            !(
                                                Boolean(this.state.situacao_id) &&
                                                Boolean(this.state.comarca_id) &&
                                                Boolean(this.state.unidade_judiciaria_id) &&
                                                Boolean(this.state.digitos_numeracao_unica >= 20)
                                                //|| true
                                            )
                                        }*/
                                        >Inserir</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container_obs">
                        <span className="flow-text">

                            <a href="/#" onClick={this.addObservacao}><img alt="" src={require("../images/icons8-adicionar.svg")} />
                                <label>Adicionar Observação</label>
                            </a>
                            {this.state.flag_editar_processo && (
                                <>
                                    <button className='btn-lor' onClick={this.toggleModal2} > <img alt="" src={imageSource} />
                                        <label >Visualizar Atos Praticados</label>
                                    </button>


                                    <button className='btn-lor' onClick={this.toggleModalAvisoPendente} > <img alt="" src={notificacaoAvisos ? require("../images/aviso_image2.png") : require("../images/aviso_image.png")} />
                                        <label>Avisos Pendentes</label>
                                    </button>
                                </>
                            )}



                        </span>
                        {
                            //slice() garante q estamos realizando as operações (no caso abaixo de inversão (reverse()) e exibição (map()))
                            //numa cópia do state, já os handleChange operam no state normalmente
                            this.state.observacoes.slice().reverse().map((observacao, idx) => {
                                var obs_id = idx; //essa var é necessária pois no onClick abaixo a var 'idx' é null

                                return (
                                    <div key={idx} className="pilha-obs">
                                        <div className="txt-obs">
                                            <textarea
                                                className="materialize-textarea"
                                                data-id={idx}
                                                name={observacoes.observacao}
                                                id={obs_id} value={observacao.observacao}
                                                onChange={valor => this.handleChangeObs(valor, this.state.observacoes.length - idx - 1)}
                                            ></textarea>
                                            <label htmlFor={obs_id}>
                                                {observacao.data ?
                                                    `Observação #${this.state.observacoes.length - idx} - criada em ${observacao.data} às ${observacao.hora}`
                                                    :
                                                    `Observação #${this.state.observacoes.length - idx}`
                                                }
                                            </label>
                                        </div>
                                        <div className="btn-apagar-obs">
                                            <a href="/#" onClick={
                                                (e) => {
                                                    e.preventDefault();
                                                    this.handleDeletarObs(this.state.observacoes.length - obs_id - 1)
                                                    //num array de 5 posicoes, ao invertê-lo, deletar a 4a observação (pos 3)
                                                    //tem que 'converter' p/ apagar na posição relativa 'desinvertida'
                                                    //formula: posicao_a_deletar = comprimento - posicao_no_array_invertido - 1
                                                    //ou seja, clicando na 4a observacao (posicao 3 no array invertido):
                                                    //deletar = 5 - 3 - 1 ------> deletar = 1
                                                    //ir no array original (nao invertido) e deletar no array na posição 1
                                                }
                                            }>
                                                <img alt="" className="" src={require("../images/icons8-cancelar-30.png")} />
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }
}
